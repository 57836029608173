/**
 * Open API ListenAPI spec
 * Open API Spec of Listen API
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


import * as url from "url";
import globalAxios, { AxiosInstance, AxiosPromise } from 'axios';
import { Configuration } from "./configuration";

const BASE_PATH = "https://listenapi.planetradio.co.uk/api9.2/".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface AxiosAPI
 */
export interface AxiosAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface AxiosArgs
 */
export interface AxiosArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration!: Configuration;
    protected basePath!: string;
    protected axios!: AxiosInstance;
    constructor(configuration?: Configuration, _basePath: string = BASE_PATH, _axios: AxiosInstance = globalAxios) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 *
 * @export
 * @interface AggregatorBrand
 */
export interface AggregatorBrand {
    /**
     *
     * @type {number}
     * @memberof AggregatorBrand
     */
    AggregatorBrandId: number;
    /**
     *
     * @type {string}
     * @memberof AggregatorBrand
     */
    AggregatorBrandCode: string;
    /**
     *
     * @type {string}
     * @memberof AggregatorBrand
     */
    AggregatorBrandName: string;
    /**
     *
     * @type {string}
     * @memberof AggregatorBrand
     */
    AggregatorBrandStrapline: string;
    /**
     *
     * @type {string}
     * @memberof AggregatorBrand
     */
    AggregatorBrandColor: string;
    /**
     *
     * @type {string}
     * @memberof AggregatorBrand
     */
    AggregatorBrandModuleContentPriority: string;
    /**
     *
     * @type {string}
     * @memberof AggregatorBrand
     */
    AggregatorBrandPodcastContentPriority: string;
    /**
     *
     * @type {boolean}
     * @memberof AggregatorBrand
     */
    AggregatorBrandDisplayAggregatorMenu: boolean;
    /**
     *
     * @type {boolean}
     * @memberof AggregatorBrand
     */
    AggregatorBrandInPagePlayerEnabled: boolean;
    /**
     *
     * @type {string}
     * @memberof AggregatorBrand
     */
    AggregatorBrandWebLogoImageUrl: string;
    /**
     *
     * @type {string}
     * @memberof AggregatorBrand
     */
    AggregatorBrandWebLogoHeaderImageUrl: string;
    /**
     *
     * @type {string}
     * @memberof AggregatorBrand
     */
    AggregatorBrandSocialSharingImageUrl: string;
    /**
     *
     * @type {string}
     * @memberof AggregatorBrand
     */
    AggregatorBrandMobileHeroImageUrl: string;
    /**
     *
     * @type {string}
     * @memberof AggregatorBrand
     */
    AggregatorBrandDesktopHeroImageUrl: string;
    /**
     *
     * @type {string}
     * @memberof AggregatorBrand
     */
    AggregatorBrandWebsiteUrl: string;
    /**
     *
     * @type {string}
     * @memberof AggregatorBrand
     */
    AggregatorBrandPrivacyUrl: string;
    /**
     *
     * @type {string}
     * @memberof AggregatorBrand
     */
    AggregatorBrandTermsUrl: string;
    /**
     *
     * @type {string}
     * @memberof AggregatorBrand
     */
    AggregatorBrandCompetitionTermsUrl: string;
    /**
     *
     * @type {string}
     * @memberof AggregatorBrand
     */
    AggregatorBrandCookiesUrl: string;
    /**
     *
     * @type {string}
     * @memberof AggregatorBrand
     */
    AggregatorBrandFacebookUrl: string;
    /**
     *
     * @type {string}
     * @memberof AggregatorBrand
     */
    AggregatorBrandTwitterUrl: string;
    /**
     *
     * @type {string}
     * @memberof AggregatorBrand
     */
    AggregatorBrandBannerDesktopImageUrl: string;
    /**
     *
     * @type {string}
     * @memberof AggregatorBrand
     */
    AggregatorBrandBannerMobileImageUrl: string;
    /**
     *
     * @type {string}
     * @memberof AggregatorBrand
     */
    AggregatorBrandBannerTargetUrl: string;
    /**
     *
     * @type {string}
     * @memberof AggregatorBrand
     */
    AggregatorBrandBannerClickAction: AggregatorBrandBannerClickActionEnum;
    /**
     *
     * @type {string}
     * @memberof AggregatorBrand
     */
    AggregatorBrandLogoImageUrl: string;
    /**
     *
     * @type {string}
     * @memberof AggregatorBrand
     */
    AggregatorBrandWhiteLogoImageUrl: string;
    /**
     *
     * @type {string}
     * @memberof AggregatorBrand
     */
    AggregatorBrandSplashLogoImageUrl: string;
    /**
     *
     * @type {string}
     * @memberof AggregatorBrand
     */
    RegionCode: string;
    /**
     *
     * @type {Array<number>}
     * @memberof AggregatorBrand
     */
    StationIds: Array<number>;
    /**
     *
     * @type {string}
     * @memberof AggregatorBrand
     */
    AggregatorBrandAdvertiseUrl: string;
    /**
     *
     * @type {boolean}
     * @memberof AggregatorBrand
     */
    AggregatorBrandIsPremiumEnabled: boolean;
    /**
     *
     * @type {string}
     * @memberof AggregatorBrand
     */
    AggregatorBrandPremiumSocialShareImageUrl: string;
}

/**
    * @export
    * @enum {string}
    */
export enum AggregatorBrandBannerClickActionEnum {
    Self = 'self',
    Blank = 'blank'
}

/**
 *
 * @export
 * @interface App
 */
export interface App {
    /**
     *
     * @type {number}
     * @memberof App
     */
    AppId?: number;
    /**
     *
     * @type {string}
     * @memberof App
     */
    AppCode?: string;
    /**
     *
     * @type {string}
     * @memberof App
     */
    AppName?: string;
    /**
     *
     * @type {string}
     * @memberof App
     */
    AppUpdated?: string;
    /**
     * Returns deleted date as string.
     * @type {string}
     * @memberof App
     */
    AppDeleted?: string;
    /**
     *
     * @type {string}
     * @memberof App
     */
    AppITunesStoreId?: string;
    /**
     *
     * @type {string}
     * @memberof App
     */
    AppGoogleBundleId?: string;
    /**
     *
     * @type {string}
     * @memberof App
     */
    AppITunesBundleId?: string;
    /**
     *
     * @type {string}
     * @memberof App
     */
    AppITunesTestApp?: string;
    /**
     *
     * @type {string}
     * @memberof App
     */
    AppITunesTestAppVersion?: string;
    /**
     *
     * @type {string}
     * @memberof App
     */
    AppGoogleTestApp?: string;
    /**
     *
     * @type {string}
     * @memberof App
     */
    AppGoogleTestAppVersion?: string;
    /**
     *
     * @type {string}
     * @memberof App
     */
    AppRateMeTitle?: string;
    /**
     *
     * @type {string}
     * @memberof App
     */
    AppRateMeMessage?: string;
    /**
     *
     * @type {string}
     * @memberof App
     */
    AppRateMeYes?: string;
    /**
     *
     * @type {string}
     * @memberof App
     */
    AppRateMeNo?: string;
    /**
     *
     * @type {string}
     * @memberof App
     */
    AppRateMeLater?: string;
    /**
     *
     * @type {string}
     * @memberof App
     */
    AppTrialExpiredMessage?: string;
    /**
     *
     * @type {number}
     * @memberof App
     */
    AppNative?: number;
    /**
     *
     * @type {string}
     * @memberof App
     */
    AppUniversalLinkDomain?: string;
    /**
     *
     * @type {string}
     * @memberof App
     */
    AppITunesPrefix?: string;
    /**
     *
     * @type {string}
     * @memberof App
     */
    AppPlayerId?: string;
    /**
     *
     * @type {string}
     * @memberof App
     */
    AppGoogleSigningSHA?: string;
    /**
     *
     * @type {number}
     * @memberof App
     */
    AppUniversalDomainKey?: number;
    /**
     *
     * @type {number}
     * @memberof App
     */
    AppUniversalDomainCert?: number;
    /**
     *
     * @type {number}
     * @memberof App
     */
    AppUniversalDomainChain?: number;
    /**
     *
     * @type {number}
     * @memberof App
     */
    AppPushEnabled?: number;
    /**
     *
     * @type {number}
     * @memberof App
     */
    AppLoginType?: number;
    /**
     *
     * @type {string}
     * @memberof App
     */
    AppDFPIOS?: string;
    /**
     *
     * @type {string}
     * @memberof App
     */
    AppDFPAndroid?: string;
    /**
     *
     * @type {number}
     * @memberof App
     */
    AppRegionId?: number;
    /**
     *
     * @type {string}
     * @memberof App
     */
    AppITunesPrefixEnt?: string;
    /**
     *
     * @type {string}
     * @memberof App
     */
    AppITunesBundleIdEnt?: string;
    /**
     *
     * @type {string}
     * @memberof App
     */
    AppIconImageUrl?: string;
    /**
     *
     * @type {string}
     * @memberof App
     */
    AppIconSmallImageUrl?: string;
    /**
     *
     * @type {string}
     * @memberof App
     */
    AppShowPriorities?: string;
    /**
     *
     * @type {string}
     * @memberof App
     */
    AppIOSAdswizzInstallationId?: string;
    /**
     *
     * @type {string}
     * @memberof App
     */
    AppAndroidAdswizzInstallationId?: string;
    /**
     *
     * @type {string}
     * @memberof App
     */
    AppFirebaseProjectId?: string;
}
/**
 *
 * @export
 * @interface Artist
 */
export interface Artist {
    /**
     *
     * @type {number}
     * @memberof Artist
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof Artist
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof Artist
     */
    image: string;
    /**
     *
     * @type {string}
     * @memberof Artist
     */
    imageSmall: string;
}
/**
 *
 * @export
 * @interface Audible
 */
export interface Audible {
    /**
     *
     * @type {string}
     * @memberof Audible
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof Audible
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof Audible
     */
    title?: string;
    /**
     *
     * @type {string}
     * @memberof Audible
     */
    published_at?: string;
    /**
     *
     * @type {number}
     * @memberof Audible
     */
    duration?: number;
    /**
     *
     * @type {string}
     * @memberof Audible
     */
    url?: string;
    /**
     *
     * @type {string}
     * @memberof Audible
     */
    app_url?: string;
    /**
     *
     * @type {string}
     * @memberof Audible
     */
    shuttle_url?: string;
    /**
     *
     * @type {boolean}
     * @memberof Audible
     */
    is_downloadable?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof Audible
     */
    is_premium_only?: boolean;
    /**
     *
     * @type {PodcastEpisode}
     * @memberof Audible
     */
    podcast_episode?: PodcastEpisode;
    /**
     *
     * @type {ListenAgain}
     * @memberof Audible
     */
    listenagain?: ListenAgain;
    /**
     *
     * @type {BoxSetEpisode}
     * @memberof Audible
     */
    boxset_episode?: BoxSetEpisode;
}
/**
 *
 * @export
 * @interface BoxSet
 */
export interface BoxSet {
    /**
     *
     * @type {number}
     * @memberof BoxSet
     */
    BoxSetId?: number;
    /**
     *
     * @type {number}
     * @memberof BoxSet
     */
    StationId?: number;
    /**
     *
     * @type {string}
     * @memberof BoxSet
     */
    StationCode?: string;
    /**
     *
     * @type {string}
     * @memberof BoxSet
     */
    BoxSetTitle?: string;
    /**
     *
     * @type {string}
     * @memberof BoxSet
     */
    BoxSetUrl?: string;
    /**
     *
     * @type {string}
     * @memberof BoxSet
     */
    BoxSetDescription?: string;
    /**
     *
     * @type {string}
     * @memberof BoxSet
     */
    BoxSetImageUrl?: string;
    /**
     *
     * @type {string}
     * @memberof BoxSet
     */
    BoxSetImageThumbnailUrl?: string;
    /**
     *
     * @type {string}
     * @memberof BoxSet
     */
    BoxSetSmartLink?: string;
    /**
     *
     * @type {string}
     * @memberof BoxSet
     */
    BoxSetPublishDate?: string;
    /**
     *
     * @type {string}
     * @memberof BoxSet
     */
    BoxSetRssBuildDate?: string;
    /**
     *
     * @type {string}
     * @memberof BoxSet
     */
    BoxSetWideImageUrl?: string;
    /**
     *
     * @type {boolean}
     * @memberof BoxSet
     */
    BoxSetAdultsOnly?: boolean;
    /**
     *
     * @type {string}
     * @memberof BoxSet
     */
    BoxSetSubtitle?: string;
    /**
     *
     * @type {boolean}
     * @memberof BoxSet
     */
    StationTest?: boolean;
    /**
     *
     * @type {string}
     * @memberof BoxSet
     */
    UpdatedAt?: string;
    /**
     *
     * @type {Array<number>}
     * @memberof BoxSet
     */
    StationIds?: Array<number>;
    /**
     *
     * @type {Array<string>}
     * @memberof BoxSet
     */
    StationCodes?: Array<string>;
}
/**
 *
 * @export
 * @interface BoxSetEpisode
 */
export interface BoxSetEpisode {
    /**
     *
     * @type {number}
     * @memberof BoxSetEpisode
     */
    BoxSetEpisodeId?: number;
    /**
     *
     * @type {number}
     * @memberof BoxSetEpisode
     */
    BoxSetId?: number;
    /**
     *
     * @type {string}
     * @memberof BoxSetEpisode
     */
    BoxSetEpisodeTitle?: string;
    /**
     *
     * @type {string}
     * @memberof BoxSetEpisode
     */
    BoxSetEpisodeDescription?: string;
    /**
     *
     * @type {string}
     * @memberof BoxSetEpisode
     */
    BoxSetEpisodeImageUrl?: string;
    /**
     *
     * @type {string}
     * @memberof BoxSetEpisode
     */
    BoxSetEpisodeImageThumbnailUrl?: string;
    /**
     *
     * @type {string}
     * @memberof BoxSetEpisode
     */
    BoxSetEpisodeMediaUrl?: string;
    /**
     *
     * @type {string}
     * @memberof BoxSetEpisode
     */
    BoxSetEpisodeMediaType?: string;
    /**
     *
     * @type {number}
     * @memberof BoxSetEpisode
     */
    BoxSetEpisodeDuration?: number;
    /**
     *
     * @type {string}
     * @memberof BoxSetEpisode
     */
    BoxSetEpisodePublishDate?: string;
    /**
     *
     * @type {number}
     * @memberof BoxSetEpisode
     */
    BoxSetEpisodeOrder?: number;
    /**
     *
     * @type {string}
     * @memberof BoxSetEpisode
     */
    BoxSetEpisodeExpiryDate?: string;
    /**
     *
     * @type {string}
     * @memberof BoxSetEpisode
     */
    BoxSetEpisodeSmartLink?: string;
    /**
     *
     * @type {string}
     * @memberof BoxSetEpisode
     */
    BoxSetEpisodeWideImageUrl?: string;
    /**
     *
     * @type {string}
     * @memberof BoxSetEpisode
     */
    BoxSetEpisodeStationCode?: string;
    /**
     *
     * @type {boolean}
     * @memberof BoxSetEpisode
     */
    StationTest?: boolean;
    /**
     *
     * @type {string}
     * @memberof BoxSetEpisode
     */
    BoxSetSlug?: string;
    /**
     *
     * @type {boolean}
     * @memberof BoxSetEpisode
     */
    BoxSetEpisodeIsPremiumOnly?: boolean;
    /**
     *
     * @type {string}
     * @memberof BoxSetEpisode
     */
    BoxSetEpisodeType?: BoxSetEpisodeTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum BoxSetEpisodeTypeEnum {
    Full = 'full',
    Trailer = 'trailer',
    Bonus = 'bonus'
}

/**
 *
 * @export
 * @interface Brand
 */
export interface Brand {
    /**
     *
     * @type {number}
     * @memberof Brand
     */
    BrandId: number;
    /**
     *
     * @type {string}
     * @memberof Brand
     */
    BrandCode: string;
    /**
     *
     * @type {string}
     * @memberof Brand
     */
    BrandName: string;
    /**
     *
     * @type {number}
     * @memberof Brand
     */
    RegionId: number;
    /**
     *
     * @type {string}
     * @memberof Brand
     */
    BrandWebsiteUrl: string;
    /**
     *
     * @type {string}
     * @memberof Brand
     */
    BrandLogoImageUrl: string;
    /**
     *
     * @type {string}
     * @memberof Brand
     */
    BrandDomain: string;
    /**
     *
     * @type {string}
     * @memberof Brand
     */
    BrandIncentiveText: string;
    /**
     *
     * @type {string}
     * @memberof Brand
     */
    BrandPrivacyUrl: string;
    /**
     *
     * @type {string}
     * @memberof Brand
     */
    BrandTermsUrl: string;
    /**
     *
     * @type {string}
     * @memberof Brand
     */
    BrandWhiteLogoImageUrl: string;
    /**
     *
     * @type {string}
     * @memberof Brand
     */
    BrandSplashLogoImageUrl: string;
    /**
     *
     * @type {string}
     * @memberof Brand
     */
    BrandColor: string;
}
/**
 * Article, sourced from \"Publish API\"
 * @export
 * @interface ContentArticle
 */
export interface ContentArticle {
    /**
     *
     * @type {string}
     * @memberof ContentArticle
     */
    date?: string;
    /**
     *
     * @type {string}
     * @memberof ContentArticle
     */
    station_code?: string;
    /**
     *
     * @type {string}
     * @memberof ContentArticle
     */
    category?: string;
    /**
     *
     * @type {string}
     * @memberof ContentArticle
     */
    title?: string;
    /**
     *
     * @type {string}
     * @memberof ContentArticle
     */
    image?: string;
    /**
     *
     * @type {string}
     * @memberof ContentArticle
     */
    url?: string;
}
/**
 *
 * @export
 * @interface Device
 */
export interface Device {
}
/**
 *
 * @export
 * @interface DeviceToken
 */
export interface DeviceToken {
    /**
     *
     * @type {number}
     * @memberof DeviceToken
     */
    success?: number;
}
/**
 *
 * @export
 * @interface Event
 */
export interface Event {
    /**
     *
     * @type {number}
     * @memberof Event
     */
    eventId: number;
    /**
     *
     * @type {string}
     * @memberof Event
     */
    eventStart: string;
    /**
     *
     * @type {string}
     * @memberof Event
     */
    eventFinish: string;
    /**
     *
     * @type {number}
     * @memberof Event
     */
    eventDuration: number;
    /**
     *
     * @type {string}
     * @memberof Event
     */
    eventType: string;
    /**
     *
     * @type {string}
     * @memberof Event
     */
    eventSongTitle: string;
    /**
     *
     * @type {string}
     * @memberof Event
     */
    eventSongArtist: string;
    /**
     *
     * @type {string}
     * @memberof Event
     */
    eventImageUrl: string;
    /**
     *
     * @type {string}
     * @memberof Event
     */
    eventImageUrlSmall: string;
    /**
     *
     * @type {string}
     * @memberof Event
     */
    eventAppleMusicUrl: string;
}
/**
 *
 * @export
 * @interface EventsXml
 */
export interface EventsXml {
    /**
     *
     * @type {EventsXmlEvents}
     * @memberof EventsXml
     */
    events?: EventsXmlEvents;
}
/**
 *
 * @export
 * @interface EventsXmlEvents
 */
export interface EventsXmlEvents {
    /**
     *
     * @type {EventsXmlEventsCurrent}
     * @memberof EventsXmlEvents
     */
    current?: EventsXmlEventsCurrent;
    /**
     *
     * @type {EventsXmlEventsCurrent}
     * @memberof EventsXmlEvents
     */
    future?: EventsXmlEventsCurrent;
}
/**
 *
 * @export
 * @interface EventsXmlEventsCurrent
 */
export interface EventsXmlEventsCurrent {
    /**
     *
     * @type {string}
     * @memberof EventsXmlEventsCurrent
     */
    html?: string;
    /**
     *
     * @type {string}
     * @memberof EventsXmlEventsCurrent
     */
    fullscreen?: string;
}
/**
 *
 * @export
 * @interface Init
 */
export interface Init {
}
/**
 *
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     *
     * @type {string}
     * @memberof InlineResponse200
     */
    stationCode?: string;
    /**
     *
     * @type {NowPlayingSimple}
     * @memberof InlineResponse200
     */
    stationNowPlaying?: NowPlayingSimple;
    /**
     *
     * @type {StationOnAir}
     * @memberof InlineResponse200
     */
    stationOnAir?: StationOnAir;
}
/**
 *
 * @export
 * @interface Instream
 */
export interface Instream {
    /**
     *
     * @type {string}
     * @memberof Instream
     */
    fullscreen?: string;
    /**
     *
     * @type {string}
     * @memberof Instream
     */
    fullscreenhtml?: string;
}
/**
 *
 * @export
 * @interface ListenAgain
 */
export interface ListenAgain {
    /**
     *
     * @type {number}
     * @memberof ListenAgain
     */
    ScheduleId?: number;
    /**
     *
     * @type {number}
     * @memberof ListenAgain
     */
    ShowId?: number;
    /**
     *
     * @type {string}
     * @memberof ListenAgain
     */
    ScheduleStart?: string;
    /**
     *
     * @type {string}
     * @memberof ListenAgain
     */
    ScheduleDescription?: string;
    /**
     *
     * @type {string}
     * @memberof ListenAgain
     */
    ScheduleTitle?: string;
    /**
     *
     * @type {string}
     * @memberof ListenAgain
     */
    ScheduleWebsite?: string;
    /**
     *
     * @type {number}
     * @memberof ListenAgain
     */
    ScheduleListenAgainROMP?: number;
    /**
     *
     * @type {string}
     * @memberof ListenAgain
     */
    ScheduleSmartLink?: string;
    /**
     *
     * @type {number}
     * @memberof ListenAgain
     */
    ScheduleDuration?: number;
    /**
     *
     * @type {string}
     * @memberof ListenAgain
     */
    SchedulePlaylist?: string;
    /**
     *
     * @type {string}
     * @memberof ListenAgain
     */
    ScheduleImageUrl?: string;
    /**
     *
     * @type {string}
     * @memberof ListenAgain
     */
    ScheduleWideImageUrl?: string;
    /**
     *
     * @type {string}
     * @memberof ListenAgain
     */
    ScheduleLargeImageUrl?: string;
    /**
     *
     * @type {string}
     * @memberof ListenAgain
     */
    ConfigListenAgainUrl?: string;
    /**
     *
     * @type {string}
     * @memberof ListenAgain
     */
    ConfigRadioplayerUrl?: string;
    /**
     *
     * @type {string}
     * @memberof ListenAgain
     */
    mediaUrl?: string;
    /**
     *
     * @type {string}
     * @memberof ListenAgain
     */
    ScheduleStationCode?: string;
    /**
     *
     * @type {string}
     * @memberof ListenAgain
     */
    ScheduleExpiresAt?: string;
}
/**
 *
 * @export
 * @interface ListenAgainEpisode
 */
export interface ListenAgainEpisode {
    /**
     *
     * @type {number}
     * @memberof ListenAgainEpisode
     */
    episodeid?: number;
    /**
     *
     * @type {number}
     * @memberof ListenAgainEpisode
     */
    showid?: number;
    /**
     *
     * @type {string}
     * @memberof ListenAgainEpisode
     */
    starttime?: string;
    /**
     *
     * @type {string}
     * @memberof ListenAgainEpisode
     */
    title?: string;
    /**
     *
     * @type {string}
     * @memberof ListenAgainEpisode
     */
    shortdesc?: string;
    /**
     *
     * @type {string}
     * @memberof ListenAgainEpisode
     */
    smartlink?: string;
    /**
     *
     * @type {number}
     * @memberof ListenAgainEpisode
     */
    duration?: number;
    /**
     *
     * @type {string}
     * @memberof ListenAgainEpisode
     */
    mediaurl?: string;
    /**
     *
     * @type {string}
     * @memberof ListenAgainEpisode
     */
    mediaurl_mp3?: string;
    /**
     *
     * @type {string}
     * @memberof ListenAgainEpisode
     */
    playerurl?: string;
    /**
     *
     * @type {string}
     * @memberof ListenAgainEpisode
     */
    imageurl_square?: string;
    /**
     *
     * @type {string}
     * @memberof ListenAgainEpisode
     */
    imageurl?: string;
    /**
     *
     * @type {string}
     * @memberof ListenAgainEpisode
     */
    playlist?: string;
}
/**
 *
 * @export
 * @interface ListenAgainShow
 */
export interface ListenAgainShow {
    /**
     *
     * @type {number}
     * @memberof ListenAgainShow
     */
    ShowId?: number;
    /**
     *
     * @type {string}
     * @memberof ListenAgainShow
     */
    ShowSlug?: string;
    /**
     *
     * @type {number}
     * @memberof ListenAgainShow
     */
    ShowActualId?: number;
    /**
     *
     * @type {string}
     * @memberof ListenAgainShow
     */
    ShowTitle?: string;
    /**
     *
     * @type {string}
     * @memberof ListenAgainShow
     */
    ShowDescription?: string;
    /**
     *
     * @type {string}
     * @memberof ListenAgainShow
     */
    ShowImageUrl?: string;
    /**
     *
     * @type {string}
     * @memberof ListenAgainShow
     */
    ShowWideImageUrl?: string;
    /**
     *
     * @type {string}
     * @memberof ListenAgainShow
     */
    ShowMediumImageUrl?: string;
    /**
     *
     * @type {string}
     * @memberof ListenAgainShow
     */
    ShowLargeImageUrl?: string;
    /**
     *
     * @type {string}
     * @memberof ListenAgainShow
     */
    ShowEmail?: string;
    /**
     *
     * @type {string}
     * @memberof ListenAgainShow
     */
    ShowUrl?: string;
    /**
     *
     * @type {string}
     * @memberof ListenAgainShow
     */
    ShowPodcastChannel?: string;
    /**
     *
     * @type {string}
     * @memberof ListenAgainShow
     */
    ShowPodcastChannelUrl?: string;
    /**
     *
     * @type {string}
     * @memberof ListenAgainShow
     */
    ShowPodcastChannelImage?: string;
    /**
     *
     * @type {ListenAgainShowPresenter}
     * @memberof ListenAgainShow
     */
    ShowPresenters?: ListenAgainShowPresenter;
    /**
     *
     * @type {string}
     * @memberof ListenAgainShow
     */
    ShowExpiresAt?: string;
    /**
     *
     * @type {string}
     * @memberof ListenAgainShow
     */
    StationCode?: string;
}
/**
 *
 * @export
 * @interface ListenAgainShowPresenter
 */
export interface ListenAgainShowPresenter {
    /**
     *
     * @type {number}
     * @memberof ListenAgainShowPresenter
     */
    ShowPresenterId?: number;
    /**
     *
     * @type {string}
     * @memberof ListenAgainShowPresenter
     */
    ShowPresenterSlug?: string;
    /**
     *
     * @type {string}
     * @memberof ListenAgainShowPresenter
     */
    ShowPresenterImageUrl?: string;
    /**
     *
     * @type {string}
     * @memberof ListenAgainShowPresenter
     */
    ShowPresenterForename?: string;
    /**
     *
     * @type {string}
     * @memberof ListenAgainShowPresenter
     */
    ShowPresenterSurname?: string;
}
/**
 *
 * @export
 * @interface ListenXml
 */
export interface ListenXml {
    /**
     *
     * @type {number}
     * @memberof ListenXml
     */
    success?: number;
}
/**
 *
 * @export
 * @interface Network
 */
export interface Network {
    /**
     *
     * @type {number}
     * @memberof Network
     */
    StationNetworkId?: number;
    /**
     *
     * @type {string}
     * @memberof Network
     */
    StationNetworkName?: string;
}
/**
 * Mutated event, refers only to actively playing content. Raw in-comparison to \"NowPlayingSimplified\"
 * @export
 * @interface NowPlaying
 */
export interface NowPlaying {
    /**
     *
     * @type {number}
     * @memberof NowPlaying
     */
    EventId?: number;
    /**
     *
     * @type {string}
     * @memberof NowPlaying
     */
    EventStart?: string;
    /**
     *
     * @type {number}
     * @memberof NowPlaying
     */
    EventService?: number;
    /**
     *
     * @type {string}
     * @memberof NowPlaying
     */
    EventFinish?: string;
    /**
     *
     * @type {string}
     * @memberof NowPlaying
     */
    EventType?: string;
    /**
     *
     * @type {string}
     * @memberof NowPlaying
     */
    EventMaestroContent?: string;
    /**
     *
     * @type {number}
     * @memberof NowPlaying
     */
    EventTrackId?: number;
    /**
     *
     * @type {number}
     * @memberof NowPlaying
     */
    EventNewTrackId?: number;
    /**
     *
     * @type {number}
     * @memberof NowPlaying
     */
    TrackId?: number;
    /**
     *
     * @type {string}
     * @memberof NowPlaying
     */
    TrackTitle?: string;
    /**
     *
     * @type {number}
     * @memberof NowPlaying
     */
    TrackDuration?: number;
    /**
     *
     * @type {number}
     * @memberof NowPlaying
     */
    TrackImageAvailable?: number;
    /**
     *
     * @type {string}
     * @memberof NowPlaying
     */
    TrackImageUpdatedDate?: string;
    /**
     *
     * @type {number}
     * @memberof NowPlaying
     */
    TrackAppleMusicCollectionId?: number;
    /**
     *
     * @type {number}
     * @memberof NowPlaying
     */
    TrackAppleMusicTrackId?: number;
    /**
     *
     * @type {string}
     * @memberof NowPlaying
     */
    ArtistName?: string;
    /**
     *
     * @type {number}
     * @memberof NowPlaying
     */
    ArtistId?: number;
    /**
     *
     * @type {number}
     * @memberof NowPlaying
     */
    ArtistImageAvailable?: number;
    /**
     *
     * @type {string}
     * @memberof NowPlaying
     */
    ArtistImageUpdatedDate?: string;
    /**
     *
     * @type {string}
     * @memberof NowPlaying
     */
    TrackAppleMusicUrl?: string;
    /**
     *
     * @type {string}
     * @memberof NowPlaying
     */
    ImageUrl?: string;
    /**
     *
     * @type {string}
     * @memberof NowPlaying
     */
    ImageUrlSmall?: string;
    /**
     *
     * @type {string}
     * @memberof NowPlaying
     */
    ArtistImageUrl?: string;
}
/**
 *
 * @export
 * @interface NowPlayingReduced
 */
export interface NowPlayingReduced {
    /**
     *
     * @type {string}
     * @memberof NowPlayingReduced
     */
    track?: string;
    /**
     *
     * @type {string}
     * @memberof NowPlayingReduced
     */
    artist?: string;
    /**
     * time in milliseconds since the epoch
     * @type {string}
     * @memberof NowPlayingReduced
     */
    time?: string;
}
/**
 *
 * @export
 * @interface NowPlayingSimple
 */
export interface NowPlayingSimple {
    /**
     *
     * @type {number}
     * @memberof NowPlayingSimple
     */
    nowPlayingTrackId: number;
    /**
     *
     * @type {string}
     * @memberof NowPlayingSimple
     */
    nowPlayingTrack: string;
    /**
     *
     * @type {string}
     * @memberof NowPlayingSimple
     */
    nowPlayingArtist: string;
    /**
     *
     * @type {string}
     * @memberof NowPlayingSimple
     */
    nowPlayingImage: string;
    /**
     *
     * @type {string}
     * @memberof NowPlayingSimple
     */
    nowPlayingSmallImage: string;
    /**
     *
     * @type {string}
     * @memberof NowPlayingSimple
     */
    nowPlayingTime: string;
    /**
     *
     * @type {number}
     * @memberof NowPlayingSimple
     */
    nowPlayingDuration: number;
    /**
     *
     * @type {string}
     * @memberof NowPlayingSimple
     */
    nowPlayingAppleMusicUrl: string;
}
/**
 *
 * @export
 * @interface PodcastChannel
 */
export interface PodcastChannel {
    /**
     *
     * @type {string}
     * @memberof PodcastChannel
     */
    PodcastChannelStitcherUrl?: string;
    /**
     *
     * @type {number}
     * @memberof PodcastChannel
     */
    PodcastChannelFeatured?: number;
    /**
     *
     * @type {string}
     * @memberof PodcastChannel
     */
    PodcastChannelKeywords?: string;
    /**
     *
     * @type {string}
     * @memberof PodcastChannel
     */
    PodcastChannelEpisodeType?: string;
    /**
     *
     * @type {number}
     * @memberof PodcastChannel
     */
    PodcastChannelGenre?: number;
    /**
     *
     * @type {number}
     * @memberof PodcastChannel
     */
    PodcastChannelRadioplayId?: number;
    /**
     *
     * @type {string}
     * @memberof PodcastChannel
     */
    PodcastChannelItunesUrl?: string;
    /**
     *
     * @type {string}
     * @memberof PodcastChannel
     */
    PodcastChannelHeroImageUrl?: string;
    /**
     *
     * @type {string}
     * @memberof PodcastChannel
     */
    PodcastChannelTags?: string;
    /**
     *
     * @type {string}
     * @memberof PodcastChannel
     */
    PodcastChannelImageUrl?: string;
    /**
     *
     * @type {string}
     * @memberof PodcastChannel
     */
    PodcastChannelWebAdZoneId?: string;
    /**
     *
     * @type {number}
     * @memberof PodcastChannel
     */
    PodcastChannelId?: number;
    /**
     *
     * @type {string}
     * @memberof PodcastChannel
     */
    PodcastChannelDeleted?: string;
    /**
     *
     * @type {string}
     * @memberof PodcastChannel
     */
    PodcastChannelApplePodcastUrl?: string;
    /**
     *
     * @type {string}
     * @memberof PodcastChannel
     */
    PodcastChannelAdswizzAdServerUrl?: string;
    /**
     *
     * @type {string}
     * @memberof PodcastChannel
     */
    PodcastChannelTitle?: string;
    /**
     *
     * @type {string}
     * @memberof PodcastChannel
     */
    PodcastChannelGooglePodcastUrl?: string;
    /**
     *
     * @type {number}
     * @memberof PodcastChannel
     */
    StationId?: number;
    /**
     *
     * @type {string}
     * @memberof PodcastChannel
     */
    StationCode?: string;
    /**
     *
     * @type {string}
     * @memberof PodcastChannel
     */
    PodcastChannelWideImageUrl?: string;
    /**
     *
     * @type {string}
     * @memberof PodcastChannel
     */
    PodcastChannelOvercastUrl?: string;
    /**
     *
     * @type {number}
     * @memberof PodcastChannel
     */
    PodcastChannelExplicit?: number;
    /**
     *
     * @type {string}
     * @memberof PodcastChannel
     */
    StationNielsenVcid?: string;
    /**
     *
     * @type {string}
     * @memberof PodcastChannel
     */
    PodcastChannelExtRSSUrl?: string;
    /**
     *
     * @type {number}
     * @memberof PodcastChannel
     */
    PodcastChannelSubgenre?: number;
    /**
     *
     * @type {string}
     * @memberof PodcastChannel
     */
    PodcastChannelDescription?: string;
    /**
     *
     * @type {string}
     * @memberof PodcastChannel
     */
    PodcastChannelSubtitle?: string;
    /**
     *
     * @type {string}
     * @memberof PodcastChannel
     */
    PodcastChannelHeroWideImageUrl?: string;
    /**
     *
     * @type {string}
     * @memberof PodcastChannel
     */
    PodcastChannelRegion?: string;
    /**
     *
     * @type {string}
     * @memberof PodcastChannel
     */
    PodcastChannelSlug?: string;
    /**
     *
     * @type {Array<number>}
     * @memberof PodcastChannel
     */
    StationIds?: Array<number>;
    /**
     *
     * @type {Array<string>}
     * @memberof PodcastChannel
     */
    StationCodes?: Array<string>;
}
/**
 *
 * @export
 * @interface PodcastChannelRegion
 */
export interface PodcastChannelRegion {
    /**
     *
     * @type {number}
     * @memberof PodcastChannelRegion
     */
    PodcastChannelId: number;
    /**
     *
     * @type {number}
     * @memberof PodcastChannelRegion
     */
    StationId: number;
    /**
     *
     * @type {string}
     * @memberof PodcastChannelRegion
     */
    PodcastChannelRegion: string;
    /**
     *
     * @type {string}
     * @memberof PodcastChannelRegion
     */
    PodcastChannelTitle: string;
    /**
     *
     * @type {string}
     * @memberof PodcastChannelRegion
     */
    PodcastChannelImageUrl: string;
    /**
     *
     * @type {string}
     * @memberof PodcastChannelRegion
     */
    PodcastChannelWideImageUrl: string;
    /**
     *
     * @type {string}
     * @memberof PodcastChannelRegion
     */
    PodcastChannelDescription: string;
    /**
     *
     * @type {number}
     * @memberof PodcastChannelRegion
     */
    PodcastChannelGenre: number;
    /**
     *
     * @type {number}
     * @memberof PodcastChannelRegion
     */
    PodcastChannelSubgenre: number;
    /**
     *
     * @type {number}
     * @memberof PodcastChannelRegion
     */
    PodcastChannelExplicit: number;
    /**
     *
     * @type {string}
     * @memberof PodcastChannelRegion
     */
    PodcastChannelSubtitle: string;
    /**
     *
     * @type {string}
     * @memberof PodcastChannelRegion
     */
    PodcastChannelKeywords: string;
    /**
     *
     * @type {string}
     * @memberof PodcastChannelRegion
     */
    PodcastChannelDeleted: string;
    /**
     *
     * @type {string}
     * @memberof PodcastChannelRegion
     */
    PodcastChannelHeroImageUrl: string;
    /**
     *
     * @type {number}
     * @memberof PodcastChannelRegion
     */
    PodcastChannelRadioplayId: number;
    /**
     *
     * @type {string}
     * @memberof PodcastChannelRegion
     */
    PodcastChannelExtRSSUrl: string;
    /**
     *
     * @type {string}
     * @memberof PodcastChannelRegion
     */
    PodcastChannelSlug: string;
    /**
     *
     * @type {string}
     * @memberof PodcastChannelRegion
     */
    PodcastChannelHeroWideImageUrl: string;
    /**
     *
     * @type {string}
     * @memberof PodcastChannelRegion
     */
    PodcastChannelItunesUrl: string;
    /**
     *
     * @type {number}
     * @memberof PodcastChannelRegion
     */
    PodcastChannelFeatured: number;
    /**
     *
     * @type {string}
     * @memberof PodcastChannelRegion
     */
    PodcastChannelEpisodeType: string;
    /**
     *
     * @type {string}
     * @memberof PodcastChannelRegion
     */
    PodcastChannelAdswizzAdServerUrl: string;
    /**
     *
     * @type {string}
     * @memberof PodcastChannelRegion
     */
    PodcastChannelWebAdZoneId: string;
    /**
     *
     * @type {string}
     * @memberof PodcastChannelRegion
     */
    StationNielsenVcid: string;
    /**
     *
     * @type {string}
     * @memberof PodcastChannelRegion
     */
    PodcastChannelTags: string;
    /**
     *
     * @type {string}
     * @memberof PodcastChannelRegion
     */
    PodcastChannelStitcherUrl: string;
    /**
     *
     * @type {string}
     * @memberof PodcastChannelRegion
     */
    PodcastChannelOvercastUrl: string;
    /**
     *
     * @type {string}
     * @memberof PodcastChannelRegion
     */
    PodcastChannelApplePodcastUrl: string;
    /**
     *
     * @type {string}
     * @memberof PodcastChannelRegion
     */
    PodcastChannelGooglePodcastUrl: string;
    /**
     *
     * @type {PodcastEpisode}
     * @memberof PodcastChannelRegion
     */
    PodcastChannelLatestEpisode: PodcastEpisode;
    /**
     *
     * @type {Array<number>}
     * @memberof PodcastChannelRegion
     */
    StationIds: Array<number>;
}
/**
 *
 * @export
 * @interface PodcastEpisode
 */
export interface PodcastEpisode {
    /**
     *
     * @type {number}
     * @memberof PodcastEpisode
     */
    PodcastId: number;
    /**
     *
     * @type {number}
     * @memberof PodcastEpisode
     */
    PodcastChannelId: number;
    /**
     *
     * @type {string}
     * @memberof PodcastEpisode
     */
    PodcastTitle: string;
    /**
     *
     * @type {string}
     * @memberof PodcastEpisode
     */
    PodcastDescription: string;
    /**
     *
     * @type {string}
     * @memberof PodcastEpisode
     */
    PodcastKeywords: string;
    /**
     *
     * @type {string}
     * @memberof PodcastEpisode
     */
    PodcastCreationDate: string;
    /**
     *
     * @type {string}
     * @memberof PodcastEpisode
     */
    PodcastUpdatedAt?: string;
    /**
     *
     * @type {string}
     * @memberof PodcastEpisode
     */
    PodcastDeletedAt?: string;
    /**
     *
     * @type {string}
     * @memberof PodcastEpisode
     */
    PodcastSubtitle: string;
    /**
     *
     * @type {string}
     * @memberof PodcastEpisode
     */
    PodcastImageUrl: string;
    /**
     *
     * @type {string}
     * @memberof PodcastEpisode
     */
    PodcastWideImageUrl: string;
    /**
     *
     * @type {string}
     * @memberof PodcastEpisode
     */
    PodcastPublishDate: string;
    /**
     *
     * @type {number}
     * @memberof PodcastEpisode
     */
    PodcastExplicit: number;
    /**
     *
     * @type {number}
     * @memberof PodcastEpisode
     */
    PodcastDuration: number;
    /**
     *
     * @type {string}
     * @memberof PodcastEpisode
     */
    PodcastExtMediaUrl: string;
    /**
     *
     * @type {string}
     * @memberof PodcastEpisode
     */
    PodcastFacebookOgImageUrl: string;
    /**
     *
     * @type {string}
     * @memberof PodcastEpisode
     */
    PodcastExpiry: string;
    /**
     *
     * @type {string}
     * @memberof PodcastEpisode
     */
    PodcastMimeType: string;
    /**
     *
     * @type {number}
     * @memberof PodcastEpisode
     */
    PodcastRadioplayId: number;
    /**
     *
     * @type {string}
     * @memberof PodcastEpisode
     */
    PodcastSmartLink: string;
    /**
     *
     * @type {number}
     * @memberof PodcastEpisode
     */
    PodcastEpisodeNumber: number;
    /**
     *
     * @type {number}
     * @memberof PodcastEpisode
     */
    PodcastSeasonNumber: number;
    /**
     *
     * @type {string}
     * @memberof PodcastEpisode
     */
    StationNielsenVcid: string;
    /**
     *
     * @type {string}
     * @memberof PodcastEpisode
     */
    PodcastStationCode: string;
}
/**
 *
 * @export
 * @interface Premium
 */
export interface Premium {
    /**
     * Unique identifier for Premium
     * @type {number}
     * @memberof Premium
     */
    PremiumId?: number;
    /**
     * Header text for premium section
     * @type {string}
     * @memberof Premium
     */
    PremiumHeader?: string;
    /**
     * Generic header text
     * @type {string}
     * @memberof Premium
     */
    PremiumHeaderGeneric?: string;
    /**
     * Stations' header text
     * @type {string}
     * @memberof Premium
     */
    PremiumHeaderStations?: string;
    /**
     * Shows' header text
     * @type {string}
     * @memberof Premium
     */
    PremiumHeaderShows?: string;
    /**
     * Skips' header text
     * @type {string}
     * @memberof Premium
     */
    PremiumHeaderSkip?: string;
    /**
     * Download header text
     * @type {string}
     * @memberof Premium
     */
    PremiumHeaderDownload?: string;
    /**
     *
     * @type {string}
     * @memberof Premium
     */
    PremiumBenefit1?: string;
    /**
     *
     * @type {string}
     * @memberof Premium
     */
    PremiumBenefit2?: string;
    /**
     *
     * @type {string}
     * @memberof Premium
     */
    PremiumBenefit3?: string;
    /**
     *
     * @type {string}
     * @memberof Premium
     */
    PremiumBenefit4?: string;
    /**
     *
     * @type {string}
     * @memberof Premium
     */
    PremiumBenefit5?: string;
    /**
     * Trial button text
     * @type {string}
     * @memberof Premium
     */
    PremiumButtonTrial?: string;
    /**
     * Activation button text
     * @type {string}
     * @memberof Premium
     */
    PremiumButtonActivate?: string;
    /**
     * Dismiss button text
     * @type {string}
     * @memberof Premium
     */
    PremiumButtonDismiss?: string;
    /**
     * Banner section button text
     * @type {string}
     * @memberof Premium
     */
    PremiumBannerButton?: string;
    /**
     * Banner section description
     * @type {string}
     * @memberof Premium
     */
    PremiumBannerDescription?: string;
    /**
     * Button support text
     * @type {string}
     * @memberof Premium
     */
    PremiumFooter?: string;
    /**
     * Premiums price, formatted
     * @type {string}
     * @memberof Premium
     */
    PremiumPrice?: string;
}
/**
 *
 * @export
 * @interface Presenter
 */
export interface Presenter {
    /**
     *
     * @type {number}
     * @memberof Presenter
     */
    id?: number;
    /**
     *
     * @type {number}
     * @memberof Presenter
     */
    presenterId?: number;
    /**
     *
     * @type {string}
     * @memberof Presenter
     */
    effective?: string;
    /**
     *
     * @type {string}
     * @memberof Presenter
     */
    forename?: string;
    /**
     *
     * @type {string}
     * @memberof Presenter
     */
    surname?: string;
    /**
     *
     * @type {string}
     * @memberof Presenter
     */
    tagline?: string;
    /**
     *
     * @type {string}
     * @memberof Presenter
     */
    summary?: string;
    /**
     *
     * @type {string}
     * @memberof Presenter
     */
    biography?: string;
    /**
     *
     * @type {string}
     * @memberof Presenter
     */
    username?: string;
    /**
     *
     * @type {string}
     * @memberof Presenter
     */
    record?: string;
    /**
     *
     * @type {string}
     * @memberof Presenter
     */
    reverted?: string;
    /**
     *
     * @type {string}
     * @memberof Presenter
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof Presenter
     */
    twitter?: string;
    /**
     *
     * @type {string}
     * @memberof Presenter
     */
    slug?: string;
    /**
     *
     * @type {string}
     * @memberof Presenter
     */
    metaTitle?: string;
    /**
     *
     * @type {string}
     * @memberof Presenter
     */
    metaDescription?: string;
    /**
     *
     * @type {string}
     * @memberof Presenter
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof Presenter
     */
    region?: string;
    /**
     *
     * @type {string}
     * @memberof Presenter
     */
    imageSquare?: string;
    /**
     *
     * @type {string}
     * @memberof Presenter
     */
    imagePortrait?: string;
    /**
     *
     * @type {string}
     * @memberof Presenter
     */
    imageWide?: string;
    /**
     *
     * @type {string}
     * @memberof Presenter
     */
    imageAlt?: string;
    /**
     *
     * @type {string}
     * @memberof Presenter
     */
    imageCaption?: string;
    /**
     *
     * @type {string}
     * @memberof Presenter
     */
    imageCredit?: string;
    /**
     *
     * @type {number}
     * @memberof Presenter
     */
    latestId?: number;
    /**
     *
     * @type {string}
     * @memberof Presenter
     */
    firstEffective?: string;
    /**
     *
     * @type {string}
     * @memberof Presenter
     */
    deleted?: string;
    /**
     *
     * @type {string}
     * @memberof Presenter
     */
    presenterStationId?: string;
    /**
     *
     * @type {number}
     * @memberof Presenter
     */
    starred?: number;
    /**
     *
     * @type {Array<string>}
     * @memberof Presenter
     */
    stationId?: Array<string>;
    /**
     *
     * @type {Array<PresenterShows>}
     * @memberof Presenter
     */
    shows?: Array<PresenterShows>;
    /**
     * Just exposed for search requests
     * @type {Array<string>}
     * @memberof Presenter
     */
    stationCodes?: Array<string>;
    /**
     * Just exposed for search requests
     * @type {Array<string>}
     * @memberof Presenter
     */
    brandCodes?: Array<string>;
    /**
     * Just exposed for search requests
     * @type {Array<string>}
     * @memberof Presenter
     */
    appCodes?: Array<string>;
}
/**
 *
 * @export
 * @interface PresenterShows
 */
export interface PresenterShows {
    /**
     *
     * @type {number}
     * @memberof PresenterShows
     */
    showId: number;
    /**
     *
     * @type {string}
     * @memberof PresenterShows
     */
    title: string;
    /**
     *
     * @type {string}
     * @memberof PresenterShows
     */
    imageSquare: string;
    /**
     *
     * @type {string}
     * @memberof PresenterShows
     */
    slug: string;
}
/**
 *
 * @export
 * @interface Region
 */
export interface Region {
    /**
     *
     * @type {number}
     * @memberof Region
     */
    StationRegionId?: number;
    /**
     *
     * @type {string}
     * @memberof Region
     */
    StationRegionCode?: StationRegionCodeEnum;
    /**
     *
     * @type {string}
     * @memberof Region
     */
    StationRegionName?: string;
    /**
     *
     * @type {string}
     * @memberof Region
     */
    StationRegionTimezone?: string;
    /**
     *
     * @type {string}
     * @memberof Region
     */
    StationRegionLanguage?: string;
    /**
     *
     * @type {number}
     * @memberof Region
     */
    StationRegionDefaultAppId?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum StationRegionCodeEnum {
    GB = 'GB',
    FI = 'FI',
    DK = 'DK',
    SE = 'SE',
    DE = 'DE',
    GR = 'GR',
    CY = 'CY',
    MD = 'MD'
}

/**
 *
 * @export
 * @interface RegionFeed
 */
export interface RegionFeed {
    /**
     *
     * @type {string}
     * @memberof RegionFeed
     */
    content?: string;
    /**
     *
     * @type {string}
     * @memberof RegionFeed
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof RegionFeed
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof RegionFeed
     */
    url?: string;
    /**
     *
     * @type {string}
     * @memberof RegionFeed
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof RegionFeed
     */
    broadcastDisplayName?: string;
    /**
     *
     * @type {string}
     * @memberof RegionFeed
     */
    callSign?: string;
    /**
     *
     * @type {string}
     * @memberof RegionFeed
     */
    description?: string;
    /**
     *
     * @type {RegionFeedAreaServed}
     * @memberof RegionFeed
     */
    areaServed?: RegionFeedAreaServed;
    /**
     *
     * @type {RegionFeedPotentialAction}
     * @memberof RegionFeed
     */
    potentialAction?: RegionFeedPotentialAction;
    /**
     *
     * @type {string}
     * @memberof RegionFeed
     */
    slogan?: string;
    /**
     *
     * @type {Array<RegionFeedBroadcaster>}
     * @memberof RegionFeed
     */
    broadcaster?: Array<RegionFeedBroadcaster>;
    /**
     *
     * @type {RegionFeedLogo}
     * @memberof RegionFeed
     */
    logo?: RegionFeedLogo;
    /**
     *
     * @type {string}
     * @memberof RegionFeed
     */
    sameAs?: string;
    /**
     *
     * @type {Array<RegionFeedBroadcastFrequency>}
     * @memberof RegionFeed
     */
    broadcastFrequency?: Array<RegionFeedBroadcastFrequency>;
}
/**
 *
 * @export
 * @interface RegionFeedAreaServed
 */
export interface RegionFeedAreaServed {
    /**
     *
     * @type {string}
     * @memberof RegionFeedAreaServed
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof RegionFeedAreaServed
     */
    name?: string;
}
/**
 *
 * @export
 * @interface RegionFeedBroadcastFrequency
 */
export interface RegionFeedBroadcastFrequency {
    /**
     *
     * @type {string}
     * @memberof RegionFeedBroadcastFrequency
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof RegionFeedBroadcastFrequency
     */
    broadcastFrequencyValue?: string;
    /**
     *
     * @type {string}
     * @memberof RegionFeedBroadcastFrequency
     */
    broadcastSignalModulation?: string;
}
/**
 *
 * @export
 * @interface RegionFeedBroadcaster
 */
export interface RegionFeedBroadcaster {
    /**
     *
     * @type {string}
     * @memberof RegionFeedBroadcaster
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof RegionFeedBroadcaster
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof RegionFeedBroadcaster
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof RegionFeedBroadcaster
     */
    sameAs?: string;
}
/**
 *
 * @export
 * @interface RegionFeedLogo
 */
export interface RegionFeedLogo {
    /**
     *
     * @type {string}
     * @memberof RegionFeedLogo
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof RegionFeedLogo
     */
    height?: string;
    /**
     *
     * @type {string}
     * @memberof RegionFeedLogo
     */
    width?: string;
    /**
     *
     * @type {string}
     * @memberof RegionFeedLogo
     */
    contentUrl?: string;
}
/**
 *
 * @export
 * @interface RegionFeedPotentialAction
 */
export interface RegionFeedPotentialAction {
    /**
     *
     * @type {string}
     * @memberof RegionFeedPotentialAction
     */
    type?: string;
    /**
     *
     * @type {Array<RegionFeedPotentialActionTarget>}
     * @memberof RegionFeedPotentialAction
     */
    target?: Array<RegionFeedPotentialActionTarget>;
    /**
     *
     * @type {RegionFeedPotentialActionActionAccessibilityRequirement}
     * @memberof RegionFeedPotentialAction
     */
    actionAccessibilityRequirement?: RegionFeedPotentialActionActionAccessibilityRequirement;
}
/**
 *
 * @export
 * @interface RegionFeedPotentialActionActionAccessibilityRequirement
 */
export interface RegionFeedPotentialActionActionAccessibilityRequirement {
    /**
     *
     * @type {string}
     * @memberof RegionFeedPotentialActionActionAccessibilityRequirement
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof RegionFeedPotentialActionActionAccessibilityRequirement
     */
    category?: string;
    /**
     *
     * @type {Array<RegionFeedAreaServed>}
     * @memberof RegionFeedPotentialActionActionAccessibilityRequirement
     */
    eligibleRegion?: Array<RegionFeedAreaServed>;
}
/**
 *
 * @export
 * @interface RegionFeedPotentialActionTarget
 */
export interface RegionFeedPotentialActionTarget {
    /**
     *
     * @type {string}
     * @memberof RegionFeedPotentialActionTarget
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof RegionFeedPotentialActionTarget
     */
    urlTemplate?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof RegionFeedPotentialActionTarget
     */
    actionPlatform?: Array<string>;
}
/**
 *
 * @export
 * @interface RegionsMeta
 */
export interface RegionsMeta {
    /**
     *
     * @type {string}
     * @memberof RegionsMeta
     */
    context: string;
    /**
     *
     * @type {string}
     * @memberof RegionsMeta
     */
    type: string;
    /**
     *
     * @type {string}
     * @memberof RegionsMeta
     */
    dateModified: string;
    /**
     *
     * @type {Array<RegionFeed>}
     * @memberof RegionsMeta
     */
    dataFeedElement: Array<RegionFeed>;
}
/**
 *
 * @export
 * @interface Schedule
 */
export interface Schedule {
    /**
     *
     * @type {number}
     * @memberof Schedule
     */
    episodeid?: number;
    /**
     *
     * @type {number}
     * @memberof Schedule
     */
    showid?: number;
    /**
     *
     * @type {string}
     * @memberof Schedule
     */
    start?: string;
    /**
     *
     * @type {string}
     * @memberof Schedule
     */
    title?: string;
    /**
     *
     * @type {string}
     * @memberof Schedule
     */
    shortdesc?: string;
    /**
     *
     * @type {number}
     * @memberof Schedule
     */
    duration?: number;
    /**
     *
     * @type {string}
     * @memberof Schedule
     */
    image_url?: string;
    /**
     *
     * @type {string}
     * @memberof Schedule
     */
    url?: string;
    /**
     *
     * @type {number}
     * @memberof Schedule
     */
    record?: number;
    /**
     *
     * @type {boolean}
     * @memberof Schedule
     */
    is_skippable?: boolean;
}
/**
 *
 * @export
 * @interface SearchAppBrandStationCodes
 */
export interface SearchAppBrandStationCodes {
    /**
     *
     * @type {Array<string>}
     * @memberof SearchAppBrandStationCodes
     */
    AppCodes?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof SearchAppBrandStationCodes
     */
    StationCodes?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof SearchAppBrandStationCodes
     */
    BrandCodes?: Array<string>;
}
/**
 *
 * @export
 * @interface SearchBoxSet
 */
export interface SearchBoxSet extends BoxSet {
    /**
     *
     * @type {string}
     * @memberof SearchBoxSet
     */
    BoxSetDeleted?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof SearchBoxSet
     */
    AppCodes?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof SearchBoxSet
     */
    StationCodes?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof SearchBoxSet
     */
    BrandCodes?: Array<string>;
}
/**
 *
 * @export
 * @interface SearchBoxSetEpisode
 */
export interface SearchBoxSetEpisode extends BoxSetEpisode {
    /**
     *
     * @type {string}
     * @memberof SearchBoxSetEpisode
     */
    UpdatedAt?: string;
    /**
     *
     * @type {string}
     * @memberof SearchBoxSetEpisode
     */
    BoxSetEpisodeDeletedAt?: string;
    /**
     *
     * @type {string}
     * @memberof SearchBoxSetEpisode
     */
    BoxSetTitle?: string;
    /**
     *
     * @type {string}
     * @memberof SearchBoxSetEpisode
     */
    BoxSetSubtitle?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof SearchBoxSetEpisode
     */
    AppCodes?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof SearchBoxSetEpisode
     */
    StationCodes?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof SearchBoxSetEpisode
     */
    BrandCodes?: Array<string>;
}


/**
 *
 * @export
 * @interface SearchBoxSetEpisodeExtra
 */
export interface SearchBoxSetEpisodeExtra {
    /**
     *
     * @type {string}
     * @memberof SearchBoxSetEpisodeExtra
     */
    UpdatedAt?: string;
    /**
     *
     * @type {string}
     * @memberof SearchBoxSetEpisodeExtra
     */
    BoxSetEpisodeDeletedAt?: string;
    /**
     *
     * @type {string}
     * @memberof SearchBoxSetEpisodeExtra
     */
    BoxSetTitle?: string;
    /**
     *
     * @type {string}
     * @memberof SearchBoxSetEpisodeExtra
     */
    BoxSetSubtitle?: string;
}
/**
 *
 * @export
 * @interface SearchBoxSetExtra
 */
export interface SearchBoxSetExtra {
    /**
     *
     * @type {string}
     * @memberof SearchBoxSetExtra
     */
    BoxSetDeleted?: string;
}
/**
 *
 * @export
 * @interface SearchListenAgain
 */
export interface SearchListenAgain extends ListenAgain {
    /**
     *
     * @type {string}
     * @memberof SearchListenAgain
     */
    ShowTitle?: string;
    /**
     *
     * @type {string}
     * @memberof SearchListenAgain
     */
    ShowDescription?: string;
    /**
     *
     * @type {string}
     * @memberof SearchListenAgain
     */
    ShowUpdatedAt?: string;
    /**
     *
     * @type {string}
     * @memberof SearchListenAgain
     */
    ShowDeletedAt?: string;
    /**
     *
     * @type {string}
     * @memberof SearchListenAgain
     */
    StationId?: string;
    /**
     *
     * @type {string}
     * @memberof SearchListenAgain
     */
    StationName?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof SearchListenAgain
     */
    presenters?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof SearchListenAgain
     */
    AppCodes?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof SearchListenAgain
     */
    StationCodes?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof SearchListenAgain
     */
    BrandCodes?: Array<string>;
}
/**
 *
 * @export
 * @interface SearchListenAgainEpisode
 */
export interface SearchListenAgainEpisode extends ListenAgainEpisode {
    /**
     *
     * @type {string}
     * @memberof SearchListenAgainEpisode
     */
    updatedAt?: string;
    /**
     *
     * @type {boolean}
     * @memberof SearchListenAgainEpisode
     */
    live?: boolean;
    /**
     *
     * @type {Date}
     * @memberof SearchListenAgainEpisode
     */
    expiresAt?: Date;
    /**
     *
     * @type {Array<string>}
     * @memberof SearchListenAgainEpisode
     */
    AppCodes?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof SearchListenAgainEpisode
     */
    StationCodes?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof SearchListenAgainEpisode
     */
    BrandCodes?: Array<string>;
}
/**
 *
 * @export
 * @interface SearchListenAgainEpisodeExtra
 */
export interface SearchListenAgainEpisodeExtra {
    /**
     *
     * @type {string}
     * @memberof SearchListenAgainEpisodeExtra
     */
    updatedAt?: string;
    /**
     *
     * @type {boolean}
     * @memberof SearchListenAgainEpisodeExtra
     */
    live?: boolean;
    /**
     *
     * @type {Date}
     * @memberof SearchListenAgainEpisodeExtra
     */
    expiresAt?: Date;
}
/**
 *
 * @export
 * @interface SearchListenAgainExtra
 */
export interface SearchListenAgainExtra {
    /**
     *
     * @type {string}
     * @memberof SearchListenAgainExtra
     */
    ShowTitle?: string;
    /**
     *
     * @type {string}
     * @memberof SearchListenAgainExtra
     */
    ShowDescription?: string;
    /**
     *
     * @type {string}
     * @memberof SearchListenAgainExtra
     */
    ShowUpdatedAt?: string;
    /**
     *
     * @type {string}
     * @memberof SearchListenAgainExtra
     */
    ShowDeletedAt?: string;
    /**
     *
     * @type {string}
     * @memberof SearchListenAgainExtra
     */
    StationId?: string;
    /**
     *
     * @type {string}
     * @memberof SearchListenAgainExtra
     */
    StationName?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof SearchListenAgainExtra
     */
    presenters?: Array<string>;
}
/**
 *
 * @export
 * @interface SearchPodcastEpisode
 */
export interface SearchPodcastEpisode extends PodcastEpisode {
    /**
     *
     * @type {string}
     * @memberof SearchPodcastEpisode
     */
    PodcastChannelTitle?: string;
    /**
     *
     * @type {string}
     * @memberof SearchPodcastEpisode
     */
    PodcastChannelKeywords?: string;
    /**
     *
     * @type {string}
     * @memberof SearchPodcastEpisode
     */
    PodcastChannelDescription?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof SearchPodcastEpisode
     */
    AppCodes?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof SearchPodcastEpisode
     */
    StationCodes?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof SearchPodcastEpisode
     */
    BrandCodes?: Array<string>;
}
/**
 *
 * @export
 * @interface SearchPodcastEpisodeExtra
 */
export interface SearchPodcastEpisodeExtra {
    /**
     *
     * @type {string}
     * @memberof SearchPodcastEpisodeExtra
     */
    PodcastChannelTitle?: string;
    /**
     *
     * @type {string}
     * @memberof SearchPodcastEpisodeExtra
     */
    PodcastChannelKeywords?: string;
    /**
     *
     * @type {string}
     * @memberof SearchPodcastEpisodeExtra
     */
    PodcastChannelDescription?: string;
}
/**
 *
 * @export
 * @interface SearchPodcastShow
 */
export interface SearchPodcastShow extends PodcastChannelRegion {
    /**
     *
     * @type {Array<string>}
     * @memberof SearchPodcastShow
     */
    AppCodes?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof SearchPodcastShow
     */
    StationCodes?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof SearchPodcastShow
     */
    BrandCodes?: Array<string>;
}
/**
 *
 * @export
 * @interface Show
 */
export interface Show {
    /**
     *
     * @type {string}
     * @memberof Show
     */
    id: string;
    /**
     *
     * @type {string}
     * @memberof Show
     */
    type: TypeEnum;
    /**
     *
     * @type {string}
     * @memberof Show
     */
    title: string;
    /**
     *
     * @type {string}
     * @memberof Show
     */
    url: string;
    /**
     *
     * @type {string}
     * @memberof Show
     */
    app_url: string;
    /**
     *
     * @type {string}
     * @memberof Show
     */
    shuttle_url: string;
    /**
     *
     * @type {boolean}
     * @memberof Show
     */
    is_featured: boolean;
    /**
     *
     * @type {boolean}
     * @memberof Show
     */
    is_downloadable: boolean;
    /**
     *
     * @type {boolean}
     * @memberof Show
     */
    is_premium_only: boolean;
    /**
     *
     * @type {string}
     * @memberof Show
     */
    last_episode_published_at: string;
    /**
     *
     * @type {number}
     * @memberof Show
     */
    num_audibles: number;
    /**
     *
     * @type {PodcastChannel}
     * @memberof Show
     */
    podcast?: PodcastChannel;
    /**
     *
     * @type {BoxSet}
     * @memberof Show
     */
    boxset?: BoxSet;
    /**
     *
     * @type {ListenAgainShow}
     * @memberof Show
     */
    listenagain_show?: ListenAgainShow;
}

/**
    * @export
    * @enum {string}
    */
export enum TypeEnum {
    Podcast = 'podcast',
    Boxset = 'boxset',
    ListenagainShow = 'listenagain_show'
}

/**
 *
 * @export
 * @interface ShuttleLink
 */
export interface ShuttleLink {
    /**
     *
     * @type {number}
     * @memberof ShuttleLink
     */
    id: number;
    /**
     *
     * @type {number}
     * @memberof ShuttleLink
     */
    application_id?: number;
    /**
     *
     * @type {string}
     * @memberof ShuttleLink
     */
    title: string;
    /**
     *
     * @type {string}
     * @memberof ShuttleLink
     */
    description: string;
    /**
     *
     * @type {string}
     * @memberof ShuttleLink
     */
    image_url: string;
    /**
     *
     * @type {string}
     * @memberof ShuttleLink
     */
    ios_url: string;
    /**
     *
     * @type {string}
     * @memberof ShuttleLink
     */
    android_url: string;
    /**
     *
     * @type {string}
     * @memberof ShuttleLink
     */
    desktop_url: string;
    /**
     *
     * @type {string}
     * @memberof ShuttleLink
     */
    slug: string;
    /**
     *
     * @type {string}
     * @memberof ShuttleLink
     */
    url: string;
}
/**
 *
 * @export
 * @interface SignInIncentive
 */
export interface SignInIncentive {
    /**
     * Unique identifier for sign in incentive
     * @type {number}
     * @memberof SignInIncentive
     */
    SignInIncentiveId?: number;
    /**
     * Default Header text
     * @type {string}
     * @memberof SignInIncentive
     */
    SignInIncentiveHeader?: string;
    /**
     * Default overview text
     * @type {string}
     * @memberof SignInIncentive
     */
    SignInIncentiveOverview?: string;
    /**
     * Default Image url
     * @type {string}
     * @memberof SignInIncentive
     */
    SignInIncentiveImage?: string;
    /**
     * Benefit Header 1 text
     * @type {string}
     * @memberof SignInIncentive
     */
    SignInIncentiveBenefitHeader1?: string;
    /**
     * Benefit Header 2 text
     * @type {string}
     * @memberof SignInIncentive
     */
    SignInIncentiveBenefitHeader2?: string;
    /**
     * Benefit Header 3 text
     * @type {string}
     * @memberof SignInIncentive
     */
    SignInIncentiveBenefitHeader3?: string;
    /**
     * Benefit Header 4 text
     * @type {string}
     * @memberof SignInIncentive
     */
    SignInIncentiveBenefitHeader4?: string;
    /**
     * Benefit Header 5 text
     * @type {string}
     * @memberof SignInIncentive
     */
    SignInIncentiveBenefitHeader5?: string;
    /**
     * Benefit Header 6 text
     * @type {string}
     * @memberof SignInIncentive
     */
    SignInIncentiveBenefitHeader6?: string;
    /**
     * Benefit 1 text
     * @type {string}
     * @memberof SignInIncentive
     */
    SignInIncentiveBenefit1?: string;
    /**
     * Benefit 2 text
     * @type {string}
     * @memberof SignInIncentive
     */
    SignInIncentiveBenefit2?: string;
    /**
     * Benefit 3 text
     * @type {string}
     * @memberof SignInIncentive
     */
    SignInIncentiveBenefit3?: string;
    /**
     * Benefit 4 text
     * @type {string}
     * @memberof SignInIncentive
     */
    SignInIncentiveBenefit4?: string;
    /**
     * Benefit 5 text
     * @type {string}
     * @memberof SignInIncentive
     */
    SignInIncentiveBenefit5?: string;
    /**
     * Benefit 6 text
     * @type {string}
     * @memberof SignInIncentive
     */
    SignInIncentiveBenefit6?: string;
}
/**
 *
 * @export
 * @interface Station
 */
export interface Station {
    /**
     *
     * @type {number}
     * @memberof Station
     */
    stationId: number;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    stationCode: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    stationName: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    stationType: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    stationRegion: string;
    /**
     *
     * @type {number}
     * @memberof Station
     */
    stationAppId?: number;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    stationAppCode?: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    stationBrandCode: string;
    /**
     *
     * @type {number}
     * @memberof Station
     */
    stationBrandId: number;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    stationNewsletterCode: string;
    /**
     *
     * @type {number}
     * @memberof Station
     */
    stationNetworkId: number;
    /**
     *
     * @type {number}
     * @memberof Station
     */
    stationOrderMode: number;
    /**
     *
     * @type {number}
     * @memberof Station
     */
    stationOrder: number;
    /**
     *
     * @type {number}
     * @memberof Station
     */
    brandOrder: number;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    stationRecommended: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    stationHeaderLogo: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    stationHeaderLogoSVG: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    stationListenBarLogo: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    stationHeroImage: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    stationMobileHeroImage: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    stationWhiteLogoImage: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    stationLockScreenImage: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    stationSmartLink: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    stationBrandColour: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    stationFacebook: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    facebookAppId?: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    stationTwitter: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    stationInstagram: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    stationYouTube: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    stationStrapline: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    configStraplineImageUrl: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    stationHomepage: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    stationDADIChannelId: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    stationDADIType: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    stationSplitGroup: string;
    /**
     *
     * @type {number}
     * @memberof Station
     */
    stationSplitGroupId: number;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    stationStudioEmail: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    stationPlaylistUrl: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    stationLoginMessage: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    stationPreviewAudioUrl: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    stationWelcomeAudioUrl: string;
    /**
     *
     * @type {NowPlayingSimple}
     * @memberof Station
     */
    stationNowPlaying: NowPlayingSimple;
    /**
     *
     * @type {Array<NowPlayingSimple>}
     * @memberof Station
     */
    stationPlayHistory: Array<NowPlayingSimple>;
    /**
     *
     * @type {StationOnAir}
     * @memberof Station
     */
    stationOnAir: StationOnAir;
    /**
     *
     * @type {Array<string>}
     * @memberof Station
     */
    stationBroadcastMedia: Array<string>;
    /**
     * Return Stations instances
     * @type {Array<any>}
     * @memberof Station
     */
    stationBrandRelated: Array<any>;
    /**
     * Return Stations instances
     * @type {Array<any>}
     * @memberof Station
     */
    stationRelated: Array<any>;
    /**
     * Return Stations instances
     * @type {Array<any>}
     * @memberof Station
     */
    stationSplits: Array<any>;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    stationAACStream: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    stationMP3Stream: string;
    /**
     *
     * @type {number}
     * @memberof Station
     */
    stationLat: number;
    /**
     *
     * @type {number}
     * @memberof Station
     */
    stationLon: number;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    stationPlayerUrl: string;
    /**
     *
     * @type {boolean}
     * @memberof Station
     */
    stationIsAdswizzEnabled: boolean;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    stationAdswizzZone: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    stationAdswizzUrl: string;
    /**
     *
     * @type {number}
     * @memberof Station
     */
    stationAppleMusicEnabled: number;
    /**
     *
     * @type {Array<string>}
     * @memberof Station
     */
    stationBearers: Array<string>;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    stationTermsUrl: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    stationPrivacyUrl: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    stationAdswizzPublisherId: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    stationAdswizzFillerZoneId: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    stationAdswizzFillerAdvertiserId: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    stationZettaAdsId: string;
    /**
     *
     * @type {Array<StationStationStreams>}
     * @memberof Station
     */
    stationStreams: Array<StationStationStreams>;
    /**
     * The stream delay for the station, in seconds
     * @type {number}
     * @memberof Station
     */
    stationStreamDelay?: number;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    stationFeedbackEmail: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    appGoogleBundleId: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    appITunesStoreId: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    stationNielsenVcid: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    stationContentPriority: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    stationServiceId: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    configBannerDesktopImageUrl: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    configBannerMobileImageUrl: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    configBannerTarget: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    configBannerAlt: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    configBannerUrl: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    heroImageMask: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    stationSquareLogo: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    stationLoginType: StationLoginTypeEnum;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    hasSchedule?: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    secondaryColourHex?: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    radioplayerUrl?: string;
    /**
     *
     * @type {number}
     * @memberof Station
     */
    webWaysToListenIsEnabled?: number;
    /**
     *
     * @type {number}
     * @memberof Station
     */
    radioWaysToListenIsEnabled?: number;
    /**
     *
     * @type {number}
     * @memberof Station
     */
    mobileWaysToListenIsEnabled?: number;
    /**
     *
     * @type {number}
     * @memberof Station
     */
    speakersWaysToListenIsEnabled?: number;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    amazonAlexaInvocation?: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    googleHomeInvocation?: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    applePodInvocation?: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    trafficTravelUrl?: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    responsysCode?: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    stationWikipediaUrl?: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    amazonAlexaSkillUrl?: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    applePodSkillUrl?: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    googleHomeSkillUrl?: string;
    /**
     *
     * @type {number}
     * @memberof Station
     */
    sonosIsEnabled?: number;
    /**
     *
     * @type {number}
     * @memberof Station
     */
    chromecastIsEnabled?: number;
    /**
     *
     * @type {number}
     * @memberof Station
     */
    tvGamingWaysToListenIsEnabled?: number;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    xboxAppStoreUrl?: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    psAppStoreUrl?: string;
    /**
     *
     * @type {number}
     * @memberof Station
     */
    appleTvEnabled?: number;
    /**
     *
     * @type {number}
     * @memberof Station
     */
    carWaysToListenIsEnabled?: number;
    /**
     *
     * @type {number}
     * @memberof Station
     */
    carPlayIsEnabled?: number;
    /**
     *
     * @type {number}
     * @memberof Station
     */
    androidAutoEnabled?: number;
    /**
     *
     * @type {number}
     * @memberof Station
     */
    carModeEnabled?: number;
    /**
     *
     * @type {number}
     * @memberof Station
     */
    wearablesWaysToListenIsEnabled?: number;
    /**
     *
     * @type {number}
     * @memberof Station
     */
    appleWatchEnabled?: number;
    /**
     *
     * @type {number}
     * @memberof Station
     */
    androidWatchEnabled?: number;
    /**
     *
     * @type {Array<string>}
     * @memberof Station
     */
    stationGenreTags?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    positioningStatementTitle?: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    positioningStatementDescription?: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    teslaNavItemFirstStationName?: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    teslaNavItemFirstStationUrl?: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    teslaNavItemSecondStationName?: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    teslaNavItemSecondStationUrl?: string;
    /**
     *
     * @type {number}
     * @memberof Station
     */
    isContactManagerEnabled?: number;
    /**
     *
     * @type {number}
     * @memberof Station
     */
    isCashRegisterEnabled?: number;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    cashRegisterHomepageHeroImageDesktop?: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    cashRegisterHomepageHeroImageMobile?: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    promotionalCashRegisterBannerDesktop?: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    promotionalCashRegisterBannerMobile?: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    vanityUrl?: string;
    /**
     *
     * @type {number}
     * @memberof Station
     */
    nielsenGroupId?: number;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    alternativeStationNames?: string;
    /**
     *
     * @type {number}
     * @memberof Station
     */
    showInAlexaFeed?: number;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    constituentCountry?: string;
    /**
     *
     * @type {number}
     * @memberof Station
     */
    hlsStreamEnabled?: number;
    /**
     *
     * @type {number}
     * @memberof Station
     */
    hlsInteractiveStream?: number;
    /**
     *
     * @type {number}
     * @memberof Station
     */
    hlsPlayFromShowStart?: number;
    /**
     *
     * @type {number}
     * @memberof Station
     */
    hlsPlayFromSongStart?: number;
    /**
     *
     * @type {number}
     * @memberof Station
     */
    hlsSuperBuffer?: number;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    copyrightTextOverride?: string;
    /**
     *
     * @type {number}
     * @memberof Station
     */
    enableTrackReactions?: number;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    formerStationName?: string;
    /**
     * Low quality HLS Stream URL
     * @type {string}
     * @memberof Station
     */
    stationLqHLSUrl?: string;
    /**
     * Low quality HLS Stream URL
     * @type {string}
     * @memberof Station
     */
    stationHqHLSUrl?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof Station
     */
    amazonAlexaGenres?: Array<string>;
    /**
     *
     * @type {number}
     * @memberof Station
     */
    skipTracksEnabled?: number;
    /**
     *
     * @type {number}
     * @memberof Station
     */
    premiumEnabled?: number;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    subRegionSlug: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    stationScheduleURL?: string;
    /**
     *
     * @type {number}
     * @memberof Station
     */
    premiumOnlyStation?: number;
    /**
     *
     * @type {number}
     * @memberof Station
     */
    rcsSkipEnabled?: number;
    /**
     *
     * @type {number}
     * @memberof Station
     */
    hlsDvrSkipEnabled?: number;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    welcomeMessageAudioUrl?: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    premiumOnlyAudioMessage?: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    stationAreaServed?: string;
    /**
     *
     * @type {string}
     * @memberof Station
     */
    stationAdvertiseUrl?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum StationLoginTypeEnum {
    Disabled = 'disabled',
    Enabled = 'enabled',
    Optional = 'optional'
}

/**
 *
 * @export
 * @interface StationOnAir
 */
export interface StationOnAir {
    /**
     *
     * @type {string}
     * @memberof StationOnAir
     */
    episodeTitle?: string;
    /**
     *
     * @type {string}
     * @memberof StationOnAir
     */
    episodeDescription?: string;
    /**
     *
     * @type {string}
     * @memberof StationOnAir
     */
    episodeStart?: string;
    /**
     *
     * @type {number}
     * @memberof StationOnAir
     */
    episodeDuration?: number;
    /**
     *
     * @type {string}
     * @memberof StationOnAir
     */
    episodeImageUrl?: string;
    /**
     *
     * @type {boolean}
     * @memberof StationOnAir
     */
    episodeIsSkippable?: boolean;
}
/**
 *
 * @export
 * @interface StationStationStreams
 */
export interface StationStationStreams {
    /**
     *
     * @type {string}
     * @memberof StationStationStreams
     */
    streamUrl?: string;
    /**
     *
     * @type {boolean}
     * @memberof StationStationStreams
     */
    streamUsersOnly?: boolean;
    /**
     *
     * @type {string}
     * @memberof StationStationStreams
     */
    streamType?: string;
    /**
     *
     * @type {number}
     * @memberof StationStationStreams
     */
    streamBitRate?: number;
    /**
     *
     * @type {string}
     * @memberof StationStationStreams
     */
    streamQuality?: string;
}
/**
 *
 * @export
 * @interface Ticket
 */
export interface Ticket {
    /**
     *
     * @type {string}
     * @memberof Ticket
     */
    title?: string;
    /**
     *
     * @type {string}
     * @memberof Ticket
     */
    shortdesc?: string;
    /**
     *
     * @type {string}
     * @memberof Ticket
     */
    image_url?: string;
    /**
     *
     * @type {string}
     * @memberof Ticket
     */
    image_square_url?: string;
    /**
     *
     * @type {string}
     * @memberof Ticket
     */
    image_alt?: string;
    /**
     *
     * @type {string}
     * @memberof Ticket
     */
    expiry?: string;
    /**
     *
     * @type {string}
     * @memberof Ticket
     */
    url?: string;
}
/**
 *
 * @export
 * @interface TokenStatus
 */
export interface TokenStatus {
    /**
     * Just return when the request is successful.
     * @type {boolean}
     * @memberof TokenStatus
     */
    success?: boolean;
    /**
     * Just return when the request fails.
     * @type {string}
     * @memberof TokenStatus
     */
    error?: string;
}
/**
 *
 * @export
 * @interface Travel
 */
export interface Travel {
    /**
     *
     * @type {number}
     * @memberof Travel
     */
    lat?: number;
    /**
     *
     * @type {number}
     * @memberof Travel
     */
    lon?: number;
    /**
     *
     * @type {number}
     * @memberof Travel
     */
    national?: number;
    /**
     *
     * @type {number}
     * @memberof Travel
     */
    priority?: number;
    /**
     *
     * @type {string}
     * @memberof Travel
     */
    locationName?: string;
    /**
     *
     * @type {string}
     * @memberof Travel
     */
    description?: string;
}
/**
 *
 * @export
 * @interface UserPremiumStatus
 */
export interface UserPremiumStatus {
    /**
     *
     * @type {string}
     * @memberof UserPremiumStatus
     */
    state: string;
    /**
     *
     * @type {string}
     * @memberof UserPremiumStatus
     */
    trial_used: string;
    /**
     *
     * @type {string}
     * @memberof UserPremiumStatus
     */
    payment_source: string;
    /**
     *
     * @type {string}
     * @memberof UserPremiumStatus
     */
    expired_at?: string;
}

export interface DeleteTokenRequest {
    registrationToken: string;
}

export interface ForgetTokensRequest {
    tokens?: Array<string>;
}

export interface GetAbsoluteArtistByIdRequest {
    absoluteArtistId: number;
}

export interface GetAggregatorBrandsRequest {
    _p?: number;
    _pp?: number;
    _o?: string;
    _filter?: Array<string>;
}

export interface GetAppsRequest {
    _p?: number;
    _pp?: number;
    _o?: string;
    _filter?: Array<string>;
}

export interface GetArticleByDadiIdRequest {
    dadiId: string;
}

export interface GetArticleByDadiIdAndFilterRequest {
    dadiId: string;
    filter: 'comps';
}

export interface GetArticleByStationCodeRequest {
    stationCode: string;
}

export interface GetArticleByStationCodeAndFilterRequest {
    stationCode: string;
    filter: 'comps';
}

export interface GetAudiblesRequest {
    AppCode?: string;
    RegionCode?: string;
    ForceAppCode?: string;
    BrandCode?: string;
    StationCode?: string;
    type?: Array<'podcast_episode' | 'boxset_episode' | 'listenagain'>;
    latest?: boolean;
    podcast_episode_PodcastChannelId?: Array<number>;
    boxset_episode_BoxSetId?: Array<number>;
    listenagain_ShowId?: Array<number>;
    _p?: number;
    _pp?: number;
    _o?: string;
    _filter?: Array<string>;
}

export interface GetBoxsetRequest {
    udid: string;
    applicationCode: string;
    platform: string;
    stationCode: string;
    streamQuality: 'hq' | 'sq' | 'wifi' | 'cellular';
    streamConnection: string;
    userId: number;
    show_items?: string;
}

export interface GetBoxsetEpisodesByStationCodeRequest {
    stationCode: string;
    _p?: number;
    _pp?: number;
    _o?: string;
    _filter?: Array<string>;
}

export interface GetBrandByBrandCodeRequest {
    brandCode: string;
}

export interface GetBrandsRequest {
    _p?: number;
    _pp?: number;
    _o?: string;
    _filter?: Array<string>;
}

export interface GetDeviceTokenRequest {
    udid: string;
    applicationCode: string;
    token: string;
}

export interface GetDevicesTokensRequest {
    applications: Array<string>;
    types?: Array<'ios' | 'and'>;
    _aggregate?: 'tokens' | 'data' | 'count';
    accounts?: Array<number>;
}

export interface GetEventRequest {
    udid: string;
    applicationCode: string;
    platform: string;
    stationCode: string;
    timeCode: string;
    userId: number;
}

export interface GetEventByIdRequest {
    eventId: number;
}

export interface GetEventsByDadiIdRequest {
    dadiCode: string;
    date: string;
    time: string;
    limit: number;
}

export interface GetEventsByStationCodeRequest {
    stationCode: string;
    time: string;
    limit: number;
}

export interface GetFeaturedPodcastsByRegionRequest {
    region: 'GB' | 'false' | 'DK' | 'FI' | 'SE' | 'DE' | 'GR' | 'CY' | 'MD';
    _p?: number;
    _pp?: number;
    _o?: string;
    _filter?: Array<string>;
}

export interface GetInstreamRequest {
    udid: string;
    applicationCode: string;
    platform: string;
    stationCode: string;
    userId: number;
    duration: string;
    adType: string;
    metaData: string;
    bandwidth: string;
}

export interface GetListenAgainByDadiIdRequest {
    dadiCode: string;
}

export interface GetListenAgainByStationCodeRequest {
    stationCode: string;
}

export interface GetListenLogsRequest {
    udid: string;
    applicationCode: string;
    platform: string;
    stationCode: string;
    streamQuality: 'hq' | 'sq' | 'wifi' | 'cellular';
    streamConnection: string;
    userId: number;
    advertiserId: number;
}

export interface GetMinifiedStationsRequest {
    regionCode: 'GB' | 'false' | 'DK' | 'FI' | 'SE' | 'DE' | 'GR' | 'CY' | 'MD';
    AppCode?: Array<string>;
    StationId?: Array<number>;
    StationCode?: Array<'radio' | 'box set' | 'television'>;
    StationDADIId?: Array<string>;
    StationType?: Array<'radio' | 'box set' | 'television'>;
    include?: Array<'stationBrandRelated' | 'stationRelated' | 'brand'>;
    test?: number;
    X_Forwarded_For?: string;
    _p?: number;
    _pp?: number;
    _o?: string;
    _filter?: Array<string>;
    premium?: number;
}

export interface GetNearbyStationsRequest {
    stationCode: string;
    userIp?: string;
    coordinates_lat?: string;
    coordinates_lon?: string;
}


export interface GetNowPlayingAndOnAirForStationsRequest {
    regionCode: 'GB' | 'false' | 'DK' | 'FI' | 'SE' | 'DE' | 'GR' | 'CY' | 'MD';
    StationCode?: Array<'radio' | 'box set' | 'television'>;
    StationDADIId?: Array<string>;
    StationType?: Array<'radio' | 'box set' | 'television'>;
    test?: number;
    premium?: number;
    X_Forwarded_For?: string;
    _p?: number;
    _pp?: number;
    _o?: string;
    _filter?: Array<string>;
}

export interface GetNowPlayingByStationCodeRequest {
    stationCode: string;
    responseType: 'json' | 'xml';
}

export interface GetOaNowPlayingByStationCodeRequest {
    stationCode: string;
    responseType: 'json' | 'xml';
}

export interface GetPlaylistRequest {
    ScheduleId?: number;
    StationCode?: string;
    from?: string;
    until?: string;
    _p?: number;
    _pp?: number;
    _o?: string;
    _filter?: Array<string>;
}

export interface GetPodcastByIdRequest {
    podcastId: number;
}

export interface GetPodcastChannelsDadiByDadiIdRequest {
    dadiCode: string;
    _p?: number;
    _pp?: number;
    _o?: string;
    _filter?: Array<string>;
}

export interface GetPodcastsByChannelRequest {
    podcastchannel: string;
    PodcastSeasonNumber?: Array<number>;
    PodcastRadioplayId?: Array<number>;
    _p?: number;
    _pp?: number;
    _o?: string;
    _filter?: Array<string>;
}

export interface GetPodcastsByChannelIdRequest {
    podcastChannelId: number;
    _p?: number;
    _pp?: number;
    _o?: string;
    _filter?: Array<string>;
}

export interface GetPodcastsChannelsByStationCodeRequest {
    stationCode: string;
}

export interface GetPremiumForAppRequest {
    appCode: string;
}

export interface GetPremiumForRegionRequest {
    regionCode: string;
}

export interface GetPremiumForStationRequest {
    stationCode: string;
}

export interface GetPresenterByIdRequest {
    presenterId: number;
}

export interface GetPresentersRequest {
    stationId?: number;
    stationCode?: string;
    brandCode?: string;
    regionCode?: string;
    since?: string;
    presenterId?: Array<number>;
}

export interface GetRegionsRequest {
    _p?: number;
    _pp?: number;
    _o?: string;
    _filter?: Array<string>;
}

export interface GetRegionsMetaByRegionCodeRequest {
    regionCode: 'GB' | 'false' | 'DK' | 'FI' | 'SE' | 'DE' | 'GR' | 'CY' | 'MD';
}

export interface GetScheduleByStationCodeRequest {
    stationCode: string;
    timestart: string;
    timeend: string;
}

export interface GetScheduleDadiByDadiIdRequest {
    dadiCode: string;
    timestart: string;
    timeend: string;
}

export interface GetShowsRequest {
    AppCode?: string;
    RegionCode?: string;
    ForceAppCode?: string;
    BrandCode?: string;
    StationCode?: string;
    type?: Array<'podcast' | 'boxset' | 'listenagain_show'>;
    latest?: boolean;
    is_active?: boolean;
    onlyWithAudibles?: boolean;
    _filter_is_featured?: number;
    _filter_is_premium_only?: number;
    _p?: number;
    _pp?: number;
    _o?: string;
    _filter?: Array<string>;
}

export interface GetShuttlelinksByIdRequest {
    shuttleLinkId: number;
}

export interface GetSignInIncentivesForAppRequest {
    appCode: string;
}

export interface GetSignInIncentivesForStationRequest {
    stationCode: string;
}

export interface GetStationsRequest {
    AppCode?: Array<string>;
    StationId?: Array<number>;
    StationCode?: Array<'radio' | 'box set' | 'television'>;
    StationDADIId?: Array<string>;
    StationType?: Array<'radio' | 'box set' | 'television'>;
    include?: Array<'stationBrandRelated' | 'stationRelated' | 'brand'>;
    test?: number;
    premium?: number;
    X_Forwarded_For?: string;
    _p?: number;
    _pp?: number;
    _o?: string;
    _filter?: Array<string>;
}


export interface GetStationsByRegionCodeRequest {
    regionCode: 'GB' | 'false' | 'DK' | 'FI' | 'SE' | 'DE' | 'GR' | 'CY' | 'MD';
    AppCode?: Array<string>;
    StationId?: Array<number>;
    StationCode?: Array<'radio' | 'box set' | 'television'>;
    StationDADIId?: Array<string>;
    StationType?: Array<'radio' | 'box set' | 'television'>;
    include?: Array<'stationLatestEpisode' | 'stationBrandRelated' | 'stationRelated'>;
    test?: string;
    X_Forwarded_For?: string;
    _p?: number;
    _pp?: number;
    _o?: string;
    _filter?: Array<string>;
    premium?: number;
}

export interface GetTicketsByStationCodeRequest {
    stationCode: string;
}

export interface GetTicketsDadiByDadiIdRequest {
    dadiCode: string;
}

export interface GetTrafficWarningsByDadiIdRequest {
    dadiCode: string;
}

export interface GetTrafficWarningsByStationCodeRequest {
    stationCode: string;
}

export interface GetUserSettingsRequest {
    saveMode: string;
    udid: string;
    applicationCode: string;
    platform: string;
    userId: number;
}

export interface InitAppRequest {
    udid: string;
    applicationCode: string;
    platform: string;
    location: string;
    userId: number;
    advertiserId: number;
    deeplink: string;
    test?: string;
    X_Forwarded_For?: string;
}

export interface InitWithDadiIdRequest {
    dadiCode: string;
    stationBrandRelated_StationType?: Array<'radio' | 'box set' | 'television'>;
    stationRelated_StationType?: Array<'radio' | 'box set' | 'television'>;
    test?: string;
    X_Forwarded_For?: string;
}

export interface InitWithStationCodeRequest {
    stationCode: string;
    stationBrandRelated_StationType?: Array<'radio' | 'box set' | 'television'>;
    stationRelated_StationType?: Array<'radio' | 'box set' | 'television'>;
    test?: string;
    X_Forwarded_For?: string;
}

export interface LatestEventsRequest {
    DadiCode?: string;
    StationCode?: string;
    StationId?: string;
    from?: string;
    _o?: 'asc' | 'desc';
}

export interface LogRequest {
    type: string;
    entity: string;
    message: string;
    USER?: number;
    also?: string;
}

export interface LoginRequest {
    udid: string;
    applicationCode: string;
    platform: string;
    location: string;
    email: string;
    password: string;
    device: string;
}

export interface LogoutRequest {
    udid: string;
    applicationCode: string;
    platform: string;
    userId: number;
}

export interface LuckydipRequest {
    region: string;
}

export interface MakeTokenRequest {
    userId: number;
    deviceId: number;
}

export interface PodcastchannelsstationRequest {
    stationCode: string;
}

export interface PodcastsByRegionCodeRequest {
    region: 'GB' | 'false' | 'DK' | 'FI' | 'SE' | 'DE' | 'GR' | 'CY' | 'MD';
    StationId?: Array<number>;
    AppId?: Array<number>;
    _p?: number;
    _pp?: number;
    _o?: string;
    _filter?: Array<string>;
}

export interface PostShuttlelinksRequest {
    title: number;
    application_id: number;
    description: string;
    image_url: string;
    ios_url: string;
    android_url: string;
    desktop_url: string;
    id?: number;
}


export interface RegisterUserRequest {
    udid: string;
    applicationCode: string;
    platform: string;
    fullName: string;
    email: string;
    gender: string;
    location: string;
    age: number;
    password: string;
}

export interface ResetUserPasswordRequest {
    udid: string;
    applicationCode: string;
    platform: string;
    emaill: string;
}

export interface SearchBoxSetEpisodeUpdatesRequest {
    region: 'GB' | 'false' | 'DK' | 'FI' | 'SE' | 'DE' | 'GR' | 'CY' | 'MD';
    since?: string;
    _pp?: number;
    include?: 'boxset';
}

export interface SearchBoxSetUpdatesRequest {
    region: 'GB' | 'false' | 'DK' | 'FI' | 'SE' | 'DE' | 'GR' | 'CY' | 'MD';
    since?: string;
    _pp?: number;
}

export interface SearchListenAgainEpisodeUpdatesRequest {
    region: 'GB' | 'false' | 'DK' | 'FI' | 'SE' | 'DE' | 'GR' | 'CY' | 'MD';
    since?: string;
    _pp?: number;
}

export interface SearchListenAgainShowUpdatesRequest {
    region: 'GB' | 'false' | 'DK' | 'FI' | 'SE' | 'DE' | 'GR' | 'CY' | 'MD';
    since?: string;
    _pp?: number;
    include?: Array<'station' | 'presenter'>;
}

export interface SearchPodcastEpisodeUpdatesRequest {
    region: 'GB' | 'false' | 'DK' | 'FI' | 'SE' | 'DE' | 'GR' | 'CY' | 'MD';
    since?: string;
    _pp?: number;
    include?: 'channel';
}

export interface SearchPodcastShowUpdatesRequest {
    region: 'GB' | 'false' | 'DK' | 'FI' | 'SE' | 'DE' | 'GR' | 'CY' | 'MD';
    since?: string;
    _pp?: number;
}

export interface SearchPresentersUpdatesRequest {
    region: 'GB' | 'false' | 'DK' | 'FI' | 'SE' | 'DE' | 'GR' | 'CY' | 'MD';
    since?: string;
    _pp?: number;
}

export interface SetSharpstreamRequest {
    i: string;
}

export interface SetUserReminderRequest {
    udid: string;
    applicationCode: string;
    platform: string;
    stationCode: string;
    timeCode: string;
    userId: number;
}

export interface StreamAuthLevelRequest {
    StreamUrl: string;
}

export interface UpdateTokenRequest {
    registrationToken: string;
}

export interface VerifyUserEmailRequest {
    udid: string;
    applicationCode: string;
    platform: string;
    email: string;
}


/**
 * DefaultApi - axios parameter creator
 * @export
 *
 * Note: configuration parameter prefixed with '_' until authMethods are required.
 */
export const DefaultApiAxiosParamCreator = function (_configuration?: Configuration) {
    return {
        /**
         * Remove a registration token.
         * @summary Remove a registration token.
         * @param {requestParameters} [DeleteTokenRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteToken(requestParams: DeleteTokenRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'registrationToken' is not null or undefined
            if (requestParams['registrationToken'] === null || requestParams['registrationToken'] === undefined) {
                throw new RequiredError('registrationToken','Required parameter registrationToken was null or undefined when calling deleteToken.');
            }
            const localVarPath = `/tokens/{registrationToken}`
                .replace(`{${"registrationToken"}}`, encodeURIComponent(String(requestParams['registrationToken'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);

            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary DEPRECATED. Delete multiple tokens
         * @param {requestParameters} [ForgetTokensRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgetTokens(requestParams: ForgetTokensRequest, options: any = {}): AxiosArgs {
            const localVarPath = `/forgetTokens`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (requestParams['tokens']) {
                localVarQueryParameter['tokens[]'] = requestParams['tokens'];
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // localVarUrlObj.search = null;
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve Artist Record
         * @summary Get artist object
         * @param {requestParameters} [GetAbsoluteArtistByIdRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAbsoluteArtistById(requestParams: GetAbsoluteArtistByIdRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'absoluteArtistId' is not null or undefined
            if (requestParams['absoluteArtistId'] === null || requestParams['absoluteArtistId'] === undefined) {
                throw new RequiredError('absoluteArtistId','Required parameter absoluteArtistId was null or undefined when calling getAbsoluteArtistById.');
            }
            const localVarPath = `/arartist/{absoluteArtistId}`
                .replace(`{${"absoluteArtistId"}}`, encodeURIComponent(String(requestParams['absoluteArtistId'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve list of aggregator brands
         * @summary Get a list of all active aggregator brands
         * @param {requestParameters} [GetAggregatorBrandsRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAggregatorBrands(requestParams: GetAggregatorBrandsRequest, options: any = {}): AxiosArgs {
            const localVarPath = `/aggregatorbrands`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (requestParams['_p'] !== undefined) {
                localVarQueryParameter['_p'] = requestParams['_p'];
            }
            if (requestParams['_pp'] !== undefined) {
                localVarQueryParameter['_pp'] = requestParams['_pp'];
            }
            if (requestParams['_o'] !== undefined) {
                localVarQueryParameter['_o'] = requestParams['_o'];
            }
            if (requestParams['_filter']) {
                localVarQueryParameter['_filter[]'] = requestParams['_filter'];
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return a list of all Applications
         * @summary Get applications object
         * @param {requestParameters} [GetAppsRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApps(requestParams: GetAppsRequest, options: any = {}): AxiosArgs {
            const localVarPath = `/applist`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (requestParams['_p'] !== undefined) {
                localVarQueryParameter['_p'] = requestParams['_p'];
            }
            if (requestParams['_pp'] !== undefined) {
                localVarQueryParameter['_pp'] = requestParams['_pp'];
            }
            if (requestParams['_o'] !== undefined) {
                localVarQueryParameter['_o'] = requestParams['_o'];
            }
            if (requestParams['_filter']) {
                localVarQueryParameter['_filter[]'] = requestParams['_filter'];
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get station details for Dadi ID
         * @param {requestParameters} [GetArticleByDadiIdRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArticleByDadiId(requestParams: GetArticleByDadiIdRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'dadiId' is not null or undefined
            if (requestParams['dadiId'] === null || requestParams['dadiId'] === undefined) {
                throw new RequiredError('dadiId','Required parameter dadiId was null or undefined when calling getArticleByDadiId.');
            }
            const localVarPath = `/contentdadi/{dadiId}`
                .replace(`{${"dadiId"}}`, encodeURIComponent(String(requestParams['dadiId'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get station details for Dadi ID
         * @param {requestParameters} [GetArticleByDadiIdAndFilterRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArticleByDadiIdAndFilter(requestParams: GetArticleByDadiIdAndFilterRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'dadiId' is not null or undefined
            if (requestParams['dadiId'] === null || requestParams['dadiId'] === undefined) {
                throw new RequiredError('dadiId','Required parameter dadiId was null or undefined when calling getArticleByDadiIdAndFilter.');
            }
            // verify required parameter 'filter' is not null or undefined
            if (requestParams['filter'] === null || requestParams['filter'] === undefined) {
                throw new RequiredError('filter','Required parameter filter was null or undefined when calling getArticleByDadiIdAndFilter.');
            }
            const localVarPath = `/contentdadi/{dadiId}/{filter}`
                .replace(`{${"dadiId"}}`, encodeURIComponent(String(requestParams['dadiId'])))
                .replace(`{${"filter"}}`, encodeURIComponent(String(requestParams['filter'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get station details
         * @param {requestParameters} [GetArticleByStationCodeRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArticleByStationCode(requestParams: GetArticleByStationCodeRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'stationCode' is not null or undefined
            if (requestParams['stationCode'] === null || requestParams['stationCode'] === undefined) {
                throw new RequiredError('stationCode','Required parameter stationCode was null or undefined when calling getArticleByStationCode.');
            }
            const localVarPath = `/content/{stationCode}`
                .replace(`{${"stationCode"}}`, encodeURIComponent(String(requestParams['stationCode'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get station details
         * @param {requestParameters} [GetArticleByStationCodeAndFilterRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArticleByStationCodeAndFilter(requestParams: GetArticleByStationCodeAndFilterRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'stationCode' is not null or undefined
            if (requestParams['stationCode'] === null || requestParams['stationCode'] === undefined) {
                throw new RequiredError('stationCode','Required parameter stationCode was null or undefined when calling getArticleByStationCodeAndFilter.');
            }
            // verify required parameter 'filter' is not null or undefined
            if (requestParams['filter'] === null || requestParams['filter'] === undefined) {
                throw new RequiredError('filter','Required parameter filter was null or undefined when calling getArticleByStationCodeAndFilter.');
            }
            const localVarPath = `/content/{stationCode}/{filter}`
                .replace(`{${"stationCode"}}`, encodeURIComponent(String(requestParams['stationCode'])))
                .replace(`{${"filter"}}`, encodeURIComponent(String(requestParams['filter'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve list of audible content (podcasts, box set episodes, listen again episodes)<hr><strong style=\"color:red\">*One of these parameters is required: `AppCode`, `RegionCode`, `StationCode`, `RegionCode`</strong>
         * @summary Get audibles object. (AppCode OR RegionCode OR StationCode OR RegionCode parameter is required)
         * @param {requestParameters} [GetAudiblesRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAudibles(requestParams: GetAudiblesRequest, options: any = {}): AxiosArgs {
            const localVarPath = `/audibles`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (requestParams['AppCode'] !== undefined) {
                localVarQueryParameter['AppCode'] = requestParams['AppCode'];
            }
            if (requestParams['RegionCode'] !== undefined) {
                localVarQueryParameter['RegionCode'] = requestParams['RegionCode'];
            }
            if (requestParams['ForceAppCode'] !== undefined) {
                localVarQueryParameter['ForceAppCode'] = requestParams['ForceAppCode'];
            }
            if (requestParams['BrandCode'] !== undefined) {
                localVarQueryParameter['BrandCode'] = requestParams['BrandCode'];
            }
            if (requestParams['StationCode'] !== undefined) {
                localVarQueryParameter['StationCode'] = requestParams['StationCode'];
            }
            if (requestParams['type']) {
                localVarQueryParameter['type[]'] = requestParams['type'];
            }
            if (requestParams['latest'] !== undefined) {
                localVarQueryParameter['latest'] = requestParams['latest'] ? '1' : '0';
            }
            if (requestParams['podcast_episode_PodcastChannelId']) {
                localVarQueryParameter['podcast_episode.PodcastChannelId[]'] = requestParams['podcast_episode_PodcastChannelId'];
            }
            if (requestParams['boxset_episode_BoxSetId']) {
                localVarQueryParameter['boxset_episode.BoxSetId[]'] = requestParams['boxset_episode_BoxSetId'];
            }
            if (requestParams['listenagain_ShowId']) {
                localVarQueryParameter['listenagain.ShowId[]'] = requestParams['listenagain_ShowId'];
            }
            if (requestParams['_p'] !== undefined) {
                localVarQueryParameter['_p'] = requestParams['_p'];
            }
            if (requestParams['_pp'] !== undefined) {
                localVarQueryParameter['_pp'] = requestParams['_pp'];
            }
            if (requestParams['_o'] !== undefined) {
                localVarQueryParameter['_o'] = requestParams['_o'];
            }
            if (requestParams['_filter']) {
                localVarQueryParameter['_filter[]'] = requestParams['_filter'];
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deprecated? Prefer getShows
         * @summary Get BoxSet and its episodes
         * @param {requestParameters} [GetBoxsetRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBoxset(requestParams: GetBoxsetRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'udid' is not null or undefined
            if (requestParams['udid'] === null || requestParams['udid'] === undefined) {
                throw new RequiredError('udid','Required parameter udid was null or undefined when calling getBoxset.');
            }
            // verify required parameter 'applicationCode' is not null or undefined
            if (requestParams['applicationCode'] === null || requestParams['applicationCode'] === undefined) {
                throw new RequiredError('applicationCode','Required parameter applicationCode was null or undefined when calling getBoxset.');
            }
            // verify required parameter 'platform' is not null or undefined
            if (requestParams['platform'] === null || requestParams['platform'] === undefined) {
                throw new RequiredError('platform','Required parameter platform was null or undefined when calling getBoxset.');
            }
            // verify required parameter 'stationCode' is not null or undefined
            if (requestParams['stationCode'] === null || requestParams['stationCode'] === undefined) {
                throw new RequiredError('stationCode','Required parameter stationCode was null or undefined when calling getBoxset.');
            }
            // verify required parameter 'streamQuality' is not null or undefined
            if (requestParams['streamQuality'] === null || requestParams['streamQuality'] === undefined) {
                throw new RequiredError('streamQuality','Required parameter streamQuality was null or undefined when calling getBoxset.');
            }
            // verify required parameter 'streamConnection' is not null or undefined
            if (requestParams['streamConnection'] === null || requestParams['streamConnection'] === undefined) {
                throw new RequiredError('streamConnection','Required parameter streamConnection was null or undefined when calling getBoxset.');
            }
            // verify required parameter 'userId' is not null or undefined
            if (requestParams['userId'] === null || requestParams['userId'] === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling getBoxset.');
            }
            const localVarPath = `/boxset/{udid}/{applicationCode}_{platform}/{stationCode}/{streamQuality}/{streamConnection}/{userId}`
                .replace(`{${"udid"}}`, encodeURIComponent(String(requestParams['udid'])))
                .replace(`{${"applicationCode"}}`, encodeURIComponent(String(requestParams['applicationCode'])))
                .replace(`{${"platform"}}`, encodeURIComponent(String(requestParams['platform'])))
                .replace(`{${"stationCode"}}`, encodeURIComponent(String(requestParams['stationCode'])))
                .replace(`{${"streamQuality"}}`, encodeURIComponent(String(requestParams['streamQuality'])))
                .replace(`{${"streamConnection"}}`, encodeURIComponent(String(requestParams['streamConnection'])))
                .replace(`{${"userId"}}`, encodeURIComponent(String(requestParams['userId'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (requestParams['show_items'] !== undefined) {
                localVarQueryParameter['show_items'] = requestParams['show_items'];
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve set episodes (JSON) -- Not on phones
         * @summary Get set episodes object
         * @param {requestParameters} [GetBoxsetEpisodesByStationCodeRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBoxsetEpisodesByStationCode(requestParams: GetBoxsetEpisodesByStationCodeRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'stationCode' is not null or undefined
            if (requestParams['stationCode'] === null || requestParams['stationCode'] === undefined) {
                throw new RequiredError('stationCode','Required parameter stationCode was null or undefined when calling getBoxsetEpisodesByStationCode.');
            }
            const localVarPath = `/boxsetepisodes/{stationCode}`
                .replace(`{${"stationCode"}}`, encodeURIComponent(String(requestParams['stationCode'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (requestParams['_p'] !== undefined) {
                localVarQueryParameter['_p'] = requestParams['_p'];
            }
            if (requestParams['_pp'] !== undefined) {
                localVarQueryParameter['_pp'] = requestParams['_pp'];
            }
            if (requestParams['_o'] !== undefined) {
                localVarQueryParameter['_o'] = requestParams['_o'];
            }
            if (requestParams['_filter']) {
                localVarQueryParameter['_filter[]'] = requestParams['_filter'];
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get brand object
         * @param {requestParameters} [GetBrandByBrandCodeRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrandByBrandCode(requestParams: GetBrandByBrandCodeRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'brandCode' is not null or undefined
            if (requestParams['brandCode'] === null || requestParams['brandCode'] === undefined) {
                throw new RequiredError('brandCode','Required parameter brandCode was null or undefined when calling getBrandByBrandCode.');
            }
            const localVarPath = `/brand/{brandCode}`
                .replace(`{${"brandCode"}}`, encodeURIComponent(String(requestParams['brandCode'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Index of brand entities
         * @param {requestParameters} [GetBrandsRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrands(requestParams: GetBrandsRequest, options: any = {}): AxiosArgs {
            const localVarPath = `/brands`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (requestParams['_p'] !== undefined) {
                localVarQueryParameter['_p'] = requestParams['_p'];
            }
            if (requestParams['_pp'] !== undefined) {
                localVarQueryParameter['_pp'] = requestParams['_pp'];
            }
            if (requestParams['_o'] !== undefined) {
                localVarQueryParameter['_o'] = requestParams['_o'];
            }
            if (requestParams['_filter']) {
                localVarQueryParameter['_filter[]'] = requestParams['_filter'];
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * DEPRECATED. Stores information about pushable device - {token} is unique identifier
         * @summary DEPRECATED. Get device token object
         * @param {requestParameters} [GetDeviceTokenRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceToken(requestParams: GetDeviceTokenRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'udid' is not null or undefined
            if (requestParams['udid'] === null || requestParams['udid'] === undefined) {
                throw new RequiredError('udid','Required parameter udid was null or undefined when calling getDeviceToken.');
            }
            // verify required parameter 'applicationCode' is not null or undefined
            if (requestParams['applicationCode'] === null || requestParams['applicationCode'] === undefined) {
                throw new RequiredError('applicationCode','Required parameter applicationCode was null or undefined when calling getDeviceToken.');
            }
            // verify required parameter 'token' is not null or undefined
            if (requestParams['token'] === null || requestParams['token'] === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling getDeviceToken.');
            }
            const localVarPath = `/device/{udid}/{applicationCode}/{token}`
                .replace(`{${"udid"}}`, encodeURIComponent(String(requestParams['udid'])))
                .replace(`{${"applicationCode"}}`, encodeURIComponent(String(requestParams['applicationCode'])))
                .replace(`{${"token"}}`, encodeURIComponent(String(requestParams['token'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * DEPRECATED. Index of device tokens
         * @summary DEPRECATED. Get device tokens object
         * @param {requestParameters} [GetDevicesTokensRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDevicesTokens(requestParams: GetDevicesTokensRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'applications' is not null or undefined
            if (requestParams['applications'] === null || requestParams['applications'] === undefined) {
                throw new RequiredError('applications','Required parameter applications was null or undefined when calling getDevicesTokens.');
            }
            const localVarPath = `/devices`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (requestParams['applications']) {
                localVarQueryParameter['applications[]'] = requestParams['applications'];
            }
            if (requestParams['types']) {
                localVarQueryParameter['types[]'] = requestParams['types'];
            }
            if (requestParams['_aggregate'] !== undefined) {
                localVarQueryParameter['_aggregate'] = requestParams['_aggregate'];
            }
            if (requestParams['accounts']) {
                localVarQueryParameter['accounts[]'] = requestParams['accounts'];
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve parsed event record (Generally more useful than eventdata)
         * @summary Get event object
         * @param {requestParameters} [GetEventRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvent(requestParams: GetEventRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'udid' is not null or undefined
            if (requestParams['udid'] === null || requestParams['udid'] === undefined) {
                throw new RequiredError('udid','Required parameter udid was null or undefined when calling getEvent.');
            }
            // verify required parameter 'applicationCode' is not null or undefined
            if (requestParams['applicationCode'] === null || requestParams['applicationCode'] === undefined) {
                throw new RequiredError('applicationCode','Required parameter applicationCode was null or undefined when calling getEvent.');
            }
            // verify required parameter 'platform' is not null or undefined
            if (requestParams['platform'] === null || requestParams['platform'] === undefined) {
                throw new RequiredError('platform','Required parameter platform was null or undefined when calling getEvent.');
            }
            // verify required parameter 'stationCode' is not null or undefined
            if (requestParams['stationCode'] === null || requestParams['stationCode'] === undefined) {
                throw new RequiredError('stationCode','Required parameter stationCode was null or undefined when calling getEvent.');
            }
            // verify required parameter 'timeCode' is not null or undefined
            if (requestParams['timeCode'] === null || requestParams['timeCode'] === undefined) {
                throw new RequiredError('timeCode','Required parameter timeCode was null or undefined when calling getEvent.');
            }
            // verify required parameter 'userId' is not null or undefined
            if (requestParams['userId'] === null || requestParams['userId'] === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling getEvent.');
            }
            const localVarPath = `/event/{udid}/{applicationCode}_{platform}/{stationCode}/{timeCode}/{userId}`
                .replace(`{${"udid"}}`, encodeURIComponent(String(requestParams['udid'])))
                .replace(`{${"applicationCode"}}`, encodeURIComponent(String(requestParams['applicationCode'])))
                .replace(`{${"platform"}}`, encodeURIComponent(String(requestParams['platform'])))
                .replace(`{${"stationCode"}}`, encodeURIComponent(String(requestParams['stationCode'])))
                .replace(`{${"timeCode"}}`, encodeURIComponent(String(requestParams['timeCode'])))
                .replace(`{${"userId"}}`, encodeURIComponent(String(requestParams['userId'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve raw-ish event record
         * @summary Retrieve raw-ish event record
         * @param {requestParameters} [GetEventByIdRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventById(requestParams: GetEventByIdRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'eventId' is not null or undefined
            if (requestParams['eventId'] === null || requestParams['eventId'] === undefined) {
                throw new RequiredError('eventId','Required parameter eventId was null or undefined when calling getEventById.');
            }
            const localVarPath = `/eventdata/{eventId}`
                .replace(`{${"eventId"}}`, encodeURIComponent(String(requestParams['eventId'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve \"events\" but with a Dadi ID
         * @summary Get playlist with a Dadi ID
         * @param {requestParameters} [GetEventsByDadiIdRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventsByDadiId(requestParams: GetEventsByDadiIdRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'dadiCode' is not null or undefined
            if (requestParams['dadiCode'] === null || requestParams['dadiCode'] === undefined) {
                throw new RequiredError('dadiCode','Required parameter dadiCode was null or undefined when calling getEventsByDadiId.');
            }
            // verify required parameter 'date' is not null or undefined
            if (requestParams['date'] === null || requestParams['date'] === undefined) {
                throw new RequiredError('date','Required parameter date was null or undefined when calling getEventsByDadiId.');
            }
            // verify required parameter 'time' is not null or undefined
            if (requestParams['time'] === null || requestParams['time'] === undefined) {
                throw new RequiredError('time','Required parameter time was null or undefined when calling getEventsByDadiId.');
            }
            // verify required parameter 'limit' is not null or undefined
            if (requestParams['limit'] === null || requestParams['limit'] === undefined) {
                throw new RequiredError('limit','Required parameter limit was null or undefined when calling getEventsByDadiId.');
            }
            const localVarPath = `/eventsdadi/{dadiCode}/{date}/{time}/{limit}`
                .replace(`{${"dadiCode"}}`, encodeURIComponent(String(requestParams['dadiCode'])))
                .replace(`{${"date"}}`, encodeURIComponent(String(requestParams['date'])))
                .replace(`{${"time"}}`, encodeURIComponent(String(requestParams['time'])))
                .replace(`{${"limit"}}`, encodeURIComponent(String(requestParams['limit'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve up to {limit} events up until {time}
         * @summary Get playlist
         * @param {requestParameters} [GetEventsByStationCodeRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventsByStationCode(requestParams: GetEventsByStationCodeRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'stationCode' is not null or undefined
            if (requestParams['stationCode'] === null || requestParams['stationCode'] === undefined) {
                throw new RequiredError('stationCode','Required parameter stationCode was null or undefined when calling getEventsByStationCode.');
            }
            // verify required parameter 'time' is not null or undefined
            if (requestParams['time'] === null || requestParams['time'] === undefined) {
                throw new RequiredError('time','Required parameter time was null or undefined when calling getEventsByStationCode.');
            }
            // verify required parameter 'limit' is not null or undefined
            if (requestParams['limit'] === null || requestParams['limit'] === undefined) {
                throw new RequiredError('limit','Required parameter limit was null or undefined when calling getEventsByStationCode.');
            }
            const localVarPath = `/events/{stationCode}/{time}/{limit}`
                .replace(`{${"stationCode"}}`, encodeURIComponent(String(requestParams['stationCode'])))
                .replace(`{${"time"}}`, encodeURIComponent(String(requestParams['time'])))
                .replace(`{${"limit"}}`, encodeURIComponent(String(requestParams['limit'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get list of all featured podcasts
         * @param {requestParameters} [GetFeaturedPodcastsByRegionRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturedPodcastsByRegion(requestParams: GetFeaturedPodcastsByRegionRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'region' is not null or undefined
            if (requestParams['region'] === null || requestParams['region'] === undefined) {
                throw new RequiredError('region','Required parameter region was null or undefined when calling getFeaturedPodcastsByRegion.');
            }
            const localVarPath = `/podcastchannelsregion/{region}/featured`
                .replace(`{${"region"}}`, encodeURIComponent(String(requestParams['region'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (requestParams['_p'] !== undefined) {
                localVarQueryParameter['_p'] = requestParams['_p'];
            }
            if (requestParams['_pp'] !== undefined) {
                localVarQueryParameter['_pp'] = requestParams['_pp'];
            }
            if (requestParams['_o'] !== undefined) {
                localVarQueryParameter['_o'] = requestParams['_o'];
            }
            if (requestParams['_filter']) {
                localVarQueryParameter['_filter[]'] = requestParams['_filter'];
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns xml document describing a full screen streaming ad
         * @summary Get full screen streaming ad object
         * @param {requestParameters} [GetInstreamRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstream(requestParams: GetInstreamRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'udid' is not null or undefined
            if (requestParams['udid'] === null || requestParams['udid'] === undefined) {
                throw new RequiredError('udid','Required parameter udid was null or undefined when calling getInstream.');
            }
            // verify required parameter 'applicationCode' is not null or undefined
            if (requestParams['applicationCode'] === null || requestParams['applicationCode'] === undefined) {
                throw new RequiredError('applicationCode','Required parameter applicationCode was null or undefined when calling getInstream.');
            }
            // verify required parameter 'platform' is not null or undefined
            if (requestParams['platform'] === null || requestParams['platform'] === undefined) {
                throw new RequiredError('platform','Required parameter platform was null or undefined when calling getInstream.');
            }
            // verify required parameter 'stationCode' is not null or undefined
            if (requestParams['stationCode'] === null || requestParams['stationCode'] === undefined) {
                throw new RequiredError('stationCode','Required parameter stationCode was null or undefined when calling getInstream.');
            }
            // verify required parameter 'userId' is not null or undefined
            if (requestParams['userId'] === null || requestParams['userId'] === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling getInstream.');
            }
            // verify required parameter 'duration' is not null or undefined
            if (requestParams['duration'] === null || requestParams['duration'] === undefined) {
                throw new RequiredError('duration','Required parameter duration was null or undefined when calling getInstream.');
            }
            // verify required parameter 'adType' is not null or undefined
            if (requestParams['adType'] === null || requestParams['adType'] === undefined) {
                throw new RequiredError('adType','Required parameter adType was null or undefined when calling getInstream.');
            }
            // verify required parameter 'metaData' is not null or undefined
            if (requestParams['metaData'] === null || requestParams['metaData'] === undefined) {
                throw new RequiredError('metaData','Required parameter metaData was null or undefined when calling getInstream.');
            }
            // verify required parameter 'bandwidth' is not null or undefined
            if (requestParams['bandwidth'] === null || requestParams['bandwidth'] === undefined) {
                throw new RequiredError('bandwidth','Required parameter bandwidth was null or undefined when calling getInstream.');
            }
            const localVarPath = `/instream/{udid}/{applicationCode}_{platform}/{stationCode}/{userId}/{duration}/{adType}/{metaData}/{bandwidth}`
                .replace(`{${"udid"}}`, encodeURIComponent(String(requestParams['udid'])))
                .replace(`{${"applicationCode"}}`, encodeURIComponent(String(requestParams['applicationCode'])))
                .replace(`{${"platform"}}`, encodeURIComponent(String(requestParams['platform'])))
                .replace(`{${"stationCode"}}`, encodeURIComponent(String(requestParams['stationCode'])))
                .replace(`{${"userId"}}`, encodeURIComponent(String(requestParams['userId'])))
                .replace(`{${"duration"}}`, encodeURIComponent(String(requestParams['duration'])))
                .replace(`{${"adType"}}`, encodeURIComponent(String(requestParams['adType'])))
                .replace(`{${"metaData"}}`, encodeURIComponent(String(requestParams['metaData'])))
                .replace(`{${"bandwidth"}}`, encodeURIComponent(String(requestParams['bandwidth'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get listen again episodes
         * @param {requestParameters} [GetListenAgainByDadiIdRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListenAgainByDadiId(requestParams: GetListenAgainByDadiIdRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'dadiCode' is not null or undefined
            if (requestParams['dadiCode'] === null || requestParams['dadiCode'] === undefined) {
                throw new RequiredError('dadiCode','Required parameter dadiCode was null or undefined when calling getListenAgainByDadiId.');
            }
            const localVarPath = `/listenagaindadi/{dadiCode}`
                .replace(`{${"dadiCode"}}`, encodeURIComponent(String(requestParams['dadiCode'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve historical play information for {station}
         * @summary Get listenagain object
         * @param {requestParameters} [GetListenAgainByStationCodeRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListenAgainByStationCode(requestParams: GetListenAgainByStationCodeRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'stationCode' is not null or undefined
            if (requestParams['stationCode'] === null || requestParams['stationCode'] === undefined) {
                throw new RequiredError('stationCode','Required parameter stationCode was null or undefined when calling getListenAgainByStationCode.');
            }
            const localVarPath = `/listenagain/{stationCode}`
                .replace(`{${"stationCode"}}`, encodeURIComponent(String(requestParams['stationCode'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Logs a \"listen\"
         * @param {requestParameters} [GetListenLogsRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListenLogs(requestParams: GetListenLogsRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'udid' is not null or undefined
            if (requestParams['udid'] === null || requestParams['udid'] === undefined) {
                throw new RequiredError('udid','Required parameter udid was null or undefined when calling getListenLogs.');
            }
            // verify required parameter 'applicationCode' is not null or undefined
            if (requestParams['applicationCode'] === null || requestParams['applicationCode'] === undefined) {
                throw new RequiredError('applicationCode','Required parameter applicationCode was null or undefined when calling getListenLogs.');
            }
            // verify required parameter 'platform' is not null or undefined
            if (requestParams['platform'] === null || requestParams['platform'] === undefined) {
                throw new RequiredError('platform','Required parameter platform was null or undefined when calling getListenLogs.');
            }
            // verify required parameter 'stationCode' is not null or undefined
            if (requestParams['stationCode'] === null || requestParams['stationCode'] === undefined) {
                throw new RequiredError('stationCode','Required parameter stationCode was null or undefined when calling getListenLogs.');
            }
            // verify required parameter 'streamQuality' is not null or undefined
            if (requestParams['streamQuality'] === null || requestParams['streamQuality'] === undefined) {
                throw new RequiredError('streamQuality','Required parameter streamQuality was null or undefined when calling getListenLogs.');
            }
            // verify required parameter 'streamConnection' is not null or undefined
            if (requestParams['streamConnection'] === null || requestParams['streamConnection'] === undefined) {
                throw new RequiredError('streamConnection','Required parameter streamConnection was null or undefined when calling getListenLogs.');
            }
            // verify required parameter 'userId' is not null or undefined
            if (requestParams['userId'] === null || requestParams['userId'] === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling getListenLogs.');
            }
            // verify required parameter 'advertiserId' is not null or undefined
            if (requestParams['advertiserId'] === null || requestParams['advertiserId'] === undefined) {
                throw new RequiredError('advertiserId','Required parameter advertiserId was null or undefined when calling getListenLogs.');
            }
            const localVarPath = `/listen/{udid}/{applicationCode}_{platform}/{stationCode}/{streamQuality}/{streamConnection}/{userId}/{advertiserId}`
                .replace(`{${"udid"}}`, encodeURIComponent(String(requestParams['udid'])))
                .replace(`{${"applicationCode"}}`, encodeURIComponent(String(requestParams['applicationCode'])))
                .replace(`{${"platform"}}`, encodeURIComponent(String(requestParams['platform'])))
                .replace(`{${"stationCode"}}`, encodeURIComponent(String(requestParams['stationCode'])))
                .replace(`{${"streamQuality"}}`, encodeURIComponent(String(requestParams['streamQuality'])))
                .replace(`{${"streamConnection"}}`, encodeURIComponent(String(requestParams['streamConnection'])))
                .replace(`{${"userId"}}`, encodeURIComponent(String(requestParams['userId'])))
                .replace(`{${"advertiserId"}}`, encodeURIComponent(String(requestParams['advertiserId'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get list of all stations
         * @param {requestParameters} [GetMinifiedStationsRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMinifiedStations(requestParams: GetMinifiedStationsRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'regionCode' is not null or undefined
            if (requestParams['regionCode'] === null || requestParams['regionCode'] === undefined) {
                throw new RequiredError('regionCode','Required parameter regionCode was null or undefined when calling getMinifiedStations.');
            }
            const localVarPath = `/stations/{regionCode}`
                .replace(`{${"regionCode"}}`, encodeURIComponent(String(requestParams['regionCode'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (requestParams['AppCode']) {
                localVarQueryParameter['AppCode[]'] = requestParams['AppCode'];
            }
            if (requestParams['StationId']) {
                localVarQueryParameter['StationId[]'] = requestParams['StationId'];
            }
            if (requestParams['StationCode']) {
                localVarQueryParameter['StationCode[]'] = requestParams['StationCode'];
            }
            if (requestParams['StationDADIId']) {
                localVarQueryParameter['StationDADIId[]'] = requestParams['StationDADIId'];
            }
            if (requestParams['StationType']) {
                localVarQueryParameter['StationType[]'] = requestParams['StationType'];
            }
            if (requestParams['include']) {
                localVarQueryParameter['include'] = requestParams['include'].join(COLLECTION_FORMATS["csv"]);
            }
            if (requestParams['test'] !== undefined) {
                localVarQueryParameter['test'] = requestParams['test'];
            }
            if (requestParams['_p'] !== undefined) {
                localVarQueryParameter['_p'] = requestParams['_p'];
            }
            if (requestParams['_pp'] !== undefined) {
                localVarQueryParameter['_pp'] = requestParams['_pp'];
            }
            if (requestParams['_o'] !== undefined) {
                localVarQueryParameter['_o'] = requestParams['_o'];
            }
            if (requestParams['_filter']) {
                localVarQueryParameter['_filter[]'] = requestParams['_filter'];
            }
            if (requestParams['premium'] !== undefined) {
                localVarQueryParameter['premium'] = requestParams['premium'];
            }
            if (requestParams['X_Forwarded_For'] !== undefined && requestParams['X_Forwarded_For'] !== null) {
                localVarHeaderParameter['X-Forwarded-For'] = String(requestParams['X_Forwarded_For']);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns nearby stations split based on user location
         * @summary Get nearby split stations
         * @param {requestParameters} [GetNearbyStationsRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNearbyStations(requestParams: GetNearbyStationsRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'stationCode' is not null or undefined
            if (requestParams['stationCode'] === null || requestParams['stationCode'] === undefined) {
                throw new RequiredError('stationCode','Required parameter stationCode was null or undefined when calling getNearbyStations.');
            }
            const localVarPath = `/nearbyStations/{stationCode}`
                .replace(`{${"stationCode"}}`, encodeURIComponent(String(requestParams['stationCode'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (requestParams['userIp'] !== undefined) {
                localVarQueryParameter['userIp'] = requestParams['userIp'];
            }
            if (requestParams['coordinates_lat'] !== undefined) {
                localVarQueryParameter['coordinates[lat]'] = requestParams['coordinates_lat'];
            }
            if (requestParams['coordinates_lon'] !== undefined) {
                localVarQueryParameter['coordinates[lon]'] = requestParams['coordinates_lon'];
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return the station networks
         * @summary Get station networks
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNetworks(options: any = {}): AxiosArgs {
            const localVarPath = `/networks`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get nowplaying / station on air data of stations
         * @param {requestParameters} [GetNowPlayingAndOnAirForStationsRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNowPlayingAndOnAirForStations(requestParams: GetNowPlayingAndOnAirForStationsRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'regionCode' is not null or undefined
            if (requestParams['regionCode'] === null || requestParams['regionCode'] === undefined) {
                throw new RequiredError('regionCode','Required parameter regionCode was null or undefined when calling getNowPlayingAndOnAirForStations.');
            }
            const localVarPath = `/stations_nowplaying/{regionCode}`
                .replace(`{${"regionCode"}}`, encodeURIComponent(String(requestParams['regionCode'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (requestParams['StationCode']) {
                localVarQueryParameter['StationCode[]'] = requestParams['StationCode'];
            }
            if (requestParams['StationDADIId']) {
                localVarQueryParameter['StationDADIId[]'] = requestParams['StationDADIId'];
            }
            if (requestParams['StationType']) {
                localVarQueryParameter['StationType[]'] = requestParams['StationType'];
            }
            if (requestParams['test'] !== undefined) {
                localVarQueryParameter['test'] = requestParams['test'];
            }
            if (requestParams['premium'] !== undefined) {
                localVarQueryParameter['premium'] = requestParams['premium'];
            }
            if (requestParams['_p'] !== undefined) {
                localVarQueryParameter['_p'] = requestParams['_p'];
            }
            if (requestParams['_pp'] !== undefined) {
                localVarQueryParameter['_pp'] = requestParams['_pp'];
            }
            if (requestParams['_o'] !== undefined) {
                localVarQueryParameter['_o'] = requestParams['_o'];
            }
            if (requestParams['_filter']) {
                localVarQueryParameter['_filter[]'] = requestParams['_filter'];
            }
            if (requestParams['X_Forwarded_For'] !== undefined && requestParams['X_Forwarded_For'] !== null) {
                localVarHeaderParameter['X-Forwarded-For'] = String(requestParams['X_Forwarded_For']);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return what is currently playing on {station}
         * @summary Get nowplaying object
         * @param {requestParameters} [GetNowPlayingByStationCodeRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNowPlayingByStationCode(requestParams: GetNowPlayingByStationCodeRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'stationCode' is not null or undefined
            if (requestParams['stationCode'] === null || requestParams['stationCode'] === undefined) {
                throw new RequiredError('stationCode','Required parameter stationCode was null or undefined when calling getNowPlayingByStationCode.');
            }
            // verify required parameter 'responseType' is not null or undefined
            if (requestParams['responseType'] === null || requestParams['responseType'] === undefined) {
                throw new RequiredError('responseType','Required parameter responseType was null or undefined when calling getNowPlayingByStationCode.');
            }
            const localVarPath = `/nowplaying/{stationCode}/{responseType}`
                .replace(`{${"stationCode"}}`, encodeURIComponent(String(requestParams['stationCode'])))
                .replace(`{${"responseType"}}`, encodeURIComponent(String(requestParams['responseType'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return what is currently playing on {station}
         * @summary Get nowplaying object with small time adjustment so it aligns to realtime
         * @param {requestParameters} [GetOaNowPlayingByStationCodeRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOaNowPlayingByStationCode(requestParams: GetOaNowPlayingByStationCodeRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'stationCode' is not null or undefined
            if (requestParams['stationCode'] === null || requestParams['stationCode'] === undefined) {
                throw new RequiredError('stationCode','Required parameter stationCode was null or undefined when calling getOaNowPlayingByStationCode.');
            }
            // verify required parameter 'responseType' is not null or undefined
            if (requestParams['responseType'] === null || requestParams['responseType'] === undefined) {
                throw new RequiredError('responseType','Required parameter responseType was null or undefined when calling getOaNowPlayingByStationCode.');
            }
            const localVarPath = `/oanowplaying/{stationCode}/{responseType}`
                .replace(`{${"stationCode"}}`, encodeURIComponent(String(requestParams['stationCode'])))
                .replace(`{${"responseType"}}`, encodeURIComponent(String(requestParams['responseType'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get playlist information for a show or station. Must provide one of ScheduleId, StationCode
         * @param {requestParameters} [GetPlaylistRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylist(requestParams: GetPlaylistRequest, options: any = {}): AxiosArgs {
            const localVarPath = `/playlist`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (requestParams['ScheduleId'] !== undefined) {
                localVarQueryParameter['ScheduleId'] = requestParams['ScheduleId'];
            }
            if (requestParams['StationCode'] !== undefined) {
                localVarQueryParameter['StationCode'] = requestParams['StationCode'];
            }
            if (requestParams['from'] !== undefined) {
                localVarQueryParameter['from'] = requestParams['from'];
            }
            if (requestParams['until'] !== undefined) {
                localVarQueryParameter['until'] = requestParams['until'];
            }
            if (requestParams['_p'] !== undefined) {
                localVarQueryParameter['_p'] = requestParams['_p'];
            }
            if (requestParams['_pp'] !== undefined) {
                localVarQueryParameter['_pp'] = requestParams['_pp'];
            }
            if (requestParams['_o'] !== undefined) {
                localVarQueryParameter['_o'] = requestParams['_o'];
            }
            if (requestParams['_filter']) {
                localVarQueryParameter['_filter[]'] = requestParams['_filter'];
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Same as `/podcastsbyid/{podcastId}` except the Podcast Channel is merged with Podcast
         * @summary Get podcast object
         * @param {requestParameters} [GetPodcastByIdRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPodcastById(requestParams: GetPodcastByIdRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'podcastId' is not null or undefined
            if (requestParams['podcastId'] === null || requestParams['podcastId'] === undefined) {
                throw new RequiredError('podcastId','Required parameter podcastId was null or undefined when calling getPodcastById.');
            }
            const localVarPath = `/podcast/{podcastId}`
                .replace(`{${"podcastId"}}`, encodeURIComponent(String(requestParams['podcastId'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Return Podcast Channels that belong to {station}
         * @param {requestParameters} [GetPodcastChannelsDadiByDadiIdRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPodcastChannelsDadiByDadiId(requestParams: GetPodcastChannelsDadiByDadiIdRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'dadiCode' is not null or undefined
            if (requestParams['dadiCode'] === null || requestParams['dadiCode'] === undefined) {
                throw new RequiredError('dadiCode','Required parameter dadiCode was null or undefined when calling getPodcastChannelsDadiByDadiId.');
            }
            const localVarPath = `/podcastchannelsdadi/{dadiCode}`
                .replace(`{${"dadiCode"}}`, encodeURIComponent(String(requestParams['dadiCode'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (requestParams['_p'] !== undefined) {
                localVarQueryParameter['_p'] = requestParams['_p'];
            }
            if (requestParams['_pp'] !== undefined) {
                localVarQueryParameter['_pp'] = requestParams['_pp'];
            }
            if (requestParams['_o'] !== undefined) {
                localVarQueryParameter['_o'] = requestParams['_o'];
            }
            if (requestParams['_filter']) {
                localVarQueryParameter['_filter[]'] = requestParams['_filter'];
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Find all podcasts by channel
         * @param {requestParameters} [GetPodcastsByChannelRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPodcastsByChannel(requestParams: GetPodcastsByChannelRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'podcastchannel' is not null or undefined
            if (requestParams['podcastchannel'] === null || requestParams['podcastchannel'] === undefined) {
                throw new RequiredError('podcastchannel','Required parameter podcastchannel was null or undefined when calling getPodcastsByChannel.');
            }
            const localVarPath = `/podcastsbychannel/{podcastchannel}`
                .replace(`{${"podcastchannel"}}`, encodeURIComponent(String(requestParams['podcastchannel'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (requestParams['PodcastSeasonNumber']) {
                localVarQueryParameter['PodcastSeasonNumber[]'] = requestParams['PodcastSeasonNumber'];
            }
            if (requestParams['PodcastRadioplayId']) {
                localVarQueryParameter['PodcastRadioplayId[]'] = requestParams['PodcastRadioplayId'];
            }
            if (requestParams['_p'] !== undefined) {
                localVarQueryParameter['_p'] = requestParams['_p'];
            }
            if (requestParams['_pp'] !== undefined) {
                localVarQueryParameter['_pp'] = requestParams['_pp'];
            }
            if (requestParams['_o'] !== undefined) {
                localVarQueryParameter['_o'] = requestParams['_o'];
            }
            if (requestParams['_filter']) {
                localVarQueryParameter['_filter[]'] = requestParams['_filter'];
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return Podcasts that belong to {podcastChannel}
         * @summary Get podcasts object
         * @param {requestParameters} [GetPodcastsByChannelIdRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPodcastsByChannelId(requestParams: GetPodcastsByChannelIdRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'podcastChannelId' is not null or undefined
            if (requestParams['podcastChannelId'] === null || requestParams['podcastChannelId'] === undefined) {
                throw new RequiredError('podcastChannelId','Required parameter podcastChannelId was null or undefined when calling getPodcastsByChannelId.');
            }
            const localVarPath = `/podcastsbyid/{podcastChannelId}`
                .replace(`{${"podcastChannelId"}}`, encodeURIComponent(String(requestParams['podcastChannelId'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (requestParams['_p'] !== undefined) {
                localVarQueryParameter['_p'] = requestParams['_p'];
            }
            if (requestParams['_pp'] !== undefined) {
                localVarQueryParameter['_pp'] = requestParams['_pp'];
            }
            if (requestParams['_o'] !== undefined) {
                localVarQueryParameter['_o'] = requestParams['_o'];
            }
            if (requestParams['_filter']) {
                localVarQueryParameter['_filter[]'] = requestParams['_filter'];
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get podcasts by station code
         * @param {requestParameters} [GetPodcastsChannelsByStationCodeRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPodcastsChannelsByStationCode(requestParams: GetPodcastsChannelsByStationCodeRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'stationCode' is not null or undefined
            if (requestParams['stationCode'] === null || requestParams['stationCode'] === undefined) {
                throw new RequiredError('stationCode','Required parameter stationCode was null or undefined when calling getPodcastsChannelsByStationCode.');
            }
            const localVarPath = `/podcastsbystation/{stationCode}`
                .replace(`{${"stationCode"}}`, encodeURIComponent(String(requestParams['stationCode'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get Premium Incentive information for an App
         * @param {requestParameters} [GetPremiumForAppRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPremiumForApp(requestParams: GetPremiumForAppRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'appCode' is not null or undefined
            if (requestParams['appCode'] === null || requestParams['appCode'] === undefined) {
                throw new RequiredError('appCode','Required parameter appCode was null or undefined when calling getPremiumForApp.');
            }
            const localVarPath = `/premium/app/{appCode}`
                .replace(`{${"appCode"}}`, encodeURIComponent(String(requestParams['appCode'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get Premium Incentive information for a Region
         * @param {requestParameters} [GetPremiumForRegionRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPremiumForRegion(requestParams: GetPremiumForRegionRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'regionCode' is not null or undefined
            if (requestParams['regionCode'] === null || requestParams['regionCode'] === undefined) {
                throw new RequiredError('regionCode','Required parameter regionCode was null or undefined when calling getPremiumForRegion.');
            }
            const localVarPath = `/premium/region/{regionCode}`
                .replace(`{${"regionCode"}}`, encodeURIComponent(String(requestParams['regionCode'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get Premium Incentive information for a Station
         * @param {requestParameters} [GetPremiumForStationRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPremiumForStation(requestParams: GetPremiumForStationRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'stationCode' is not null or undefined
            if (requestParams['stationCode'] === null || requestParams['stationCode'] === undefined) {
                throw new RequiredError('stationCode','Required parameter stationCode was null or undefined when calling getPremiumForStation.');
            }
            const localVarPath = `/premium/station/{stationCode}`
                .replace(`{${"stationCode"}}`, encodeURIComponent(String(requestParams['stationCode'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get presenter by ID
         * @param {requestParameters} [GetPresenterByIdRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPresenterById(requestParams: GetPresenterByIdRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'presenterId' is not null or undefined
            if (requestParams['presenterId'] === null || requestParams['presenterId'] === undefined) {
                throw new RequiredError('presenterId','Required parameter presenterId was null or undefined when calling getPresenterById.');
            }
            const localVarPath = `/presenters/{presenterId}`
                .replace(`{${"presenterId"}}`, encodeURIComponent(String(requestParams['presenterId'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get presenters. (stationId OR stationCode OR brandCode parameter is required)
         * @param {requestParameters} [GetPresentersRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPresenters(requestParams: GetPresentersRequest, options: any = {}): AxiosArgs {
            const localVarPath = `/presenters`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (requestParams['stationId'] !== undefined) {
                localVarQueryParameter['stationId'] = requestParams['stationId'];
            }
            if (requestParams['stationCode'] !== undefined) {
                localVarQueryParameter['stationCode'] = requestParams['stationCode'];
            }
            if (requestParams['brandCode'] !== undefined) {
                localVarQueryParameter['brandCode'] = requestParams['brandCode'];
            }
            if (requestParams['regionCode'] !== undefined) {
                localVarQueryParameter['regionCode'] = requestParams['regionCode'];
            }
            if (requestParams['since'] !== undefined) {
                localVarQueryParameter['since'] = requestParams['since'];
            }
            if (requestParams['presenterId']) {
                localVarQueryParameter['presenterId[]'] = requestParams['presenterId'];
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * VideoList of regions
         * @summary Get regions object
         * @param {requestParameters} [GetRegionsRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegions(requestParams: GetRegionsRequest, options: any = {}): AxiosArgs {
            const localVarPath = `/regions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (requestParams['_p'] !== undefined) {
                localVarQueryParameter['_p'] = requestParams['_p'];
            }
            if (requestParams['_pp'] !== undefined) {
                localVarQueryParameter['_pp'] = requestParams['_pp'];
            }
            if (requestParams['_o'] !== undefined) {
                localVarQueryParameter['_o'] = requestParams['_o'];
            }
            if (requestParams['_filter']) {
                localVarQueryParameter['_filter[]'] = requestParams['_filter'];
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Specific endpoint to Google Home Feed
         * @param {requestParameters} [GetRegionsMetaByRegionCodeRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegionsMetaByRegionCode(requestParams: GetRegionsMetaByRegionCodeRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'regionCode' is not null or undefined
            if (requestParams['regionCode'] === null || requestParams['regionCode'] === undefined) {
                throw new RequiredError('regionCode','Required parameter regionCode was null or undefined when calling getRegionsMetaByRegionCode.');
            }
            const localVarPath = `/regionsmeta/{regionCode}`
                .replace(`{${"regionCode"}}`, encodeURIComponent(String(requestParams['regionCode'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get schedule object
         * @param {requestParameters} [GetScheduleByStationCodeRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScheduleByStationCode(requestParams: GetScheduleByStationCodeRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'stationCode' is not null or undefined
            if (requestParams['stationCode'] === null || requestParams['stationCode'] === undefined) {
                throw new RequiredError('stationCode','Required parameter stationCode was null or undefined when calling getScheduleByStationCode.');
            }
            // verify required parameter 'timestart' is not null or undefined
            if (requestParams['timestart'] === null || requestParams['timestart'] === undefined) {
                throw new RequiredError('timestart','Required parameter timestart was null or undefined when calling getScheduleByStationCode.');
            }
            // verify required parameter 'timeend' is not null or undefined
            if (requestParams['timeend'] === null || requestParams['timeend'] === undefined) {
                throw new RequiredError('timeend','Required parameter timeend was null or undefined when calling getScheduleByStationCode.');
            }
            const localVarPath = `/schedule/{stationCode}/{time:start}/{time:end}`
                .replace(`{${"stationCode"}}`, encodeURIComponent(String(requestParams['stationCode'])))
                .replace(`{${"time:start"}}`, encodeURIComponent(String(requestParams['timestart'])))
                .replace(`{${"time:end"}}`, encodeURIComponent(String(requestParams['timeend'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * \"schedule\" but with Dadi ID
         * @summary \"schedule\" but with Dadi ID
         * @param {requestParameters} [GetScheduleDadiByDadiIdRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScheduleDadiByDadiId(requestParams: GetScheduleDadiByDadiIdRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'dadiCode' is not null or undefined
            if (requestParams['dadiCode'] === null || requestParams['dadiCode'] === undefined) {
                throw new RequiredError('dadiCode','Required parameter dadiCode was null or undefined when calling getScheduleDadiByDadiId.');
            }
            // verify required parameter 'timestart' is not null or undefined
            if (requestParams['timestart'] === null || requestParams['timestart'] === undefined) {
                throw new RequiredError('timestart','Required parameter timestart was null or undefined when calling getScheduleDadiByDadiId.');
            }
            // verify required parameter 'timeend' is not null or undefined
            if (requestParams['timeend'] === null || requestParams['timeend'] === undefined) {
                throw new RequiredError('timeend','Required parameter timeend was null or undefined when calling getScheduleDadiByDadiId.');
            }
            const localVarPath = `/scheduledadi/{dadiCode}/{time:start}/{time:end}`
                .replace(`{${"dadiCode"}}`, encodeURIComponent(String(requestParams['dadiCode'])))
                .replace(`{${"time:start"}}`, encodeURIComponent(String(requestParams['timestart'])))
                .replace(`{${"time:end"}}`, encodeURIComponent(String(requestParams['timeend'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get shows object. (AppCode OR BrandCode OR StationCode OR RegionCode parameter is required)
         * @param {requestParameters} [GetShowsRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShows(requestParams: GetShowsRequest, options: any = {}): AxiosArgs {
            const localVarPath = `/shows`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (requestParams['AppCode'] !== undefined) {
                localVarQueryParameter['AppCode'] = requestParams['AppCode'];
            }
            if (requestParams['RegionCode'] !== undefined) {
                localVarQueryParameter['RegionCode'] = requestParams['RegionCode'];
            }
            if (requestParams['ForceAppCode'] !== undefined) {
                localVarQueryParameter['ForceAppCode'] = requestParams['ForceAppCode'];
            }
            if (requestParams['BrandCode'] !== undefined) {
                localVarQueryParameter['BrandCode'] = requestParams['BrandCode'];
            }
            if (requestParams['StationCode'] !== undefined) {
                localVarQueryParameter['StationCode'] = requestParams['StationCode'];
            }
            if (requestParams['type']) {
                localVarQueryParameter['type[]'] = requestParams['type'];
            }
            if (requestParams['latest'] !== undefined) {
                localVarQueryParameter['latest'] = requestParams['latest'] ? '1' : '0';
            }
            if (requestParams['is_active'] !== undefined) {
                localVarQueryParameter['is_active'] = requestParams['is_active'] ? '1' : '0';
            }
            if (requestParams['onlyWithAudibles'] !== undefined) {
                localVarQueryParameter['onlyWithAudibles'] = requestParams['onlyWithAudibles'] ? '1' : '0';
            }
            if (requestParams['_filter_is_featured'] !== undefined) {
                localVarQueryParameter['_filter[is_featured]'] = requestParams['_filter_is_featured'];
            }
            if (requestParams['_filter_is_premium_only'] !== undefined) {
                localVarQueryParameter['_filter[is_premium_only]'] = requestParams['_filter_is_premium_only'];
            }
            if (requestParams['_p'] !== undefined) {
                localVarQueryParameter['_p'] = requestParams['_p'];
            }
            if (requestParams['_pp'] !== undefined) {
                localVarQueryParameter['_pp'] = requestParams['_pp'];
            }
            if (requestParams['_o'] !== undefined) {
                localVarQueryParameter['_o'] = requestParams['_o'];
            }
            if (requestParams['_filter']) {
                localVarQueryParameter['_filter[]'] = requestParams['_filter'];
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get a Shuttle Link object
         * @param {requestParameters} [GetShuttlelinksByIdRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShuttlelinksById(requestParams: GetShuttlelinksByIdRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'shuttleLinkId' is not null or undefined
            if (requestParams['shuttleLinkId'] === null || requestParams['shuttleLinkId'] === undefined) {
                throw new RequiredError('shuttleLinkId','Required parameter shuttleLinkId was null or undefined when calling getShuttlelinksById.');
            }
            const localVarPath = `/shuttlelinks/{shuttleLinkId}`
                .replace(`{${"shuttleLinkId"}}`, encodeURIComponent(String(requestParams['shuttleLinkId'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get SignIn Incentive information for an App
         * @param {requestParameters} [GetSignInIncentivesForAppRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSignInIncentivesForApp(requestParams: GetSignInIncentivesForAppRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'appCode' is not null or undefined
            if (requestParams['appCode'] === null || requestParams['appCode'] === undefined) {
                throw new RequiredError('appCode','Required parameter appCode was null or undefined when calling getSignInIncentivesForApp.');
            }
            const localVarPath = `/signIn/app/{appCode}`
                .replace(`{${"appCode"}}`, encodeURIComponent(String(requestParams['appCode'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get SignIn Incentive information for a Station
         * @param {requestParameters} [GetSignInIncentivesForStationRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSignInIncentivesForStation(requestParams: GetSignInIncentivesForStationRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'stationCode' is not null or undefined
            if (requestParams['stationCode'] === null || requestParams['stationCode'] === undefined) {
                throw new RequiredError('stationCode','Required parameter stationCode was null or undefined when calling getSignInIncentivesForStation.');
            }
            const localVarPath = `/signIn/station/{stationCode}`
                .replace(`{${"stationCode"}}`, encodeURIComponent(String(requestParams['stationCode'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get list of all stations
         * @param {requestParameters} [GetStationsRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStations(requestParams: GetStationsRequest, options: any = {}): AxiosArgs {
            const localVarPath = `/stationlist`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (requestParams['AppCode']) {
                localVarQueryParameter['AppCode[]'] = requestParams['AppCode'];
            }
            if (requestParams['StationId']) {
                localVarQueryParameter['StationId[]'] = requestParams['StationId'];
            }
            if (requestParams['StationCode']) {
                localVarQueryParameter['StationCode[]'] = requestParams['StationCode'];
            }
            if (requestParams['StationDADIId']) {
                localVarQueryParameter['StationDADIId[]'] = requestParams['StationDADIId'];
            }
            if (requestParams['StationType']) {
                localVarQueryParameter['StationType[]'] = requestParams['StationType'];
            }
            if (requestParams['include']) {
                localVarQueryParameter['include'] = requestParams['include'].join(COLLECTION_FORMATS["csv"]);
            }
            if (requestParams['test'] !== undefined) {
                localVarQueryParameter['test'] = requestParams['test'];
            }
            if (requestParams['premium'] !== undefined) {
                localVarQueryParameter['premium'] = requestParams['premium'];
            }
            if (requestParams['_p'] !== undefined) {
                localVarQueryParameter['_p'] = requestParams['_p'];
            }
            if (requestParams['_pp'] !== undefined) {
                localVarQueryParameter['_pp'] = requestParams['_pp'];
            }
            if (requestParams['_o'] !== undefined) {
                localVarQueryParameter['_o'] = requestParams['_o'];
            }
            if (requestParams['_filter']) {
                localVarQueryParameter['_filter[]'] = requestParams['_filter'];
            }
            if (requestParams['X_Forwarded_For'] !== undefined && requestParams['X_Forwarded_For'] !== null) {
                localVarHeaderParameter['X-Forwarded-For'] = String(requestParams['X_Forwarded_For']);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get station code grouped by port
         * @summary Get station code grouped by port
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStationsByPort(options: any = {}): AxiosArgs {
            const localVarPath = `/stationsbyport`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get list of all stations
         * @param {requestParameters} [GetStationsByRegionCodeRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStationsByRegionCode(requestParams: GetStationsByRegionCodeRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'regionCode' is not null or undefined
            if (requestParams['regionCode'] === null || requestParams['regionCode'] === undefined) {
                throw new RequiredError('regionCode','Required parameter regionCode was null or undefined when calling getStationsByRegionCode.');
            }
            const localVarPath = `/stationlist/{regionCode}`
                .replace(`{${"regionCode"}}`, encodeURIComponent(String(requestParams['regionCode'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (requestParams['AppCode']) {
                localVarQueryParameter['AppCode[]'] = requestParams['AppCode'];
            }
            if (requestParams['StationId']) {
                localVarQueryParameter['StationId[]'] = requestParams['StationId'];
            }
            if (requestParams['StationCode']) {
                localVarQueryParameter['StationCode[]'] = requestParams['StationCode'];
            }
            if (requestParams['StationDADIId']) {
                localVarQueryParameter['StationDADIId[]'] = requestParams['StationDADIId'];
            }
            if (requestParams['StationType']) {
                localVarQueryParameter['StationType[]'] = requestParams['StationType'];
            }
            if (requestParams['include']) {
                localVarQueryParameter['include'] = requestParams['include'].join(COLLECTION_FORMATS["csv"]);
            }
            if (requestParams['test'] !== undefined) {
                localVarQueryParameter['test'] = requestParams['test'];
            }
            if (requestParams['_p'] !== undefined) {
                localVarQueryParameter['_p'] = requestParams['_p'];
            }
            if (requestParams['_pp'] !== undefined) {
                localVarQueryParameter['_pp'] = requestParams['_pp'];
            }
            if (requestParams['_o'] !== undefined) {
                localVarQueryParameter['_o'] = requestParams['_o'];
            }
            if (requestParams['_filter']) {
                localVarQueryParameter['_filter[]'] = requestParams['_filter'];
            }
            if (requestParams['premium'] !== undefined) {
                localVarQueryParameter['premium'] = requestParams['premium'];
            }
            if (requestParams['X_Forwarded_For'] !== undefined && requestParams['X_Forwarded_For'] !== null) {
                localVarHeaderParameter['X-Forwarded-For'] = String(requestParams['X_Forwarded_For']);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves tickets available (via. <a href=\"http://aloud.com/\">Aloud.com)</a>
         * @summary Get tickets object
         * @param {requestParameters} [GetTicketsByStationCodeRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketsByStationCode(requestParams: GetTicketsByStationCodeRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'stationCode' is not null or undefined
            if (requestParams['stationCode'] === null || requestParams['stationCode'] === undefined) {
                throw new RequiredError('stationCode','Required parameter stationCode was null or undefined when calling getTicketsByStationCode.');
            }
            const localVarPath = `/tickets/{stationCode}`
                .replace(`{${"stationCode"}}`, encodeURIComponent(String(requestParams['stationCode'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves \"tickets\" but with Dadi ID
         * @summary Get tickets with Dadi ID
         * @param {requestParameters} [GetTicketsDadiByDadiIdRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketsDadiByDadiId(requestParams: GetTicketsDadiByDadiIdRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'dadiCode' is not null or undefined
            if (requestParams['dadiCode'] === null || requestParams['dadiCode'] === undefined) {
                throw new RequiredError('dadiCode','Required parameter dadiCode was null or undefined when calling getTicketsDadiByDadiId.');
            }
            const localVarPath = `/ticketsdadi/{dadiCode}`
                .replace(`{${"dadiCode"}}`, encodeURIComponent(String(requestParams['dadiCode'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * \"travel\" but with a Dadi ID
         * @summary Get travel with Dadi ID
         * @param {requestParameters} [GetTrafficWarningsByDadiIdRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrafficWarningsByDadiId(requestParams: GetTrafficWarningsByDadiIdRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'dadiCode' is not null or undefined
            if (requestParams['dadiCode'] === null || requestParams['dadiCode'] === undefined) {
                throw new RequiredError('dadiCode','Required parameter dadiCode was null or undefined when calling getTrafficWarningsByDadiId.');
            }
            const localVarPath = `/traveldadi/{dadiCode}`
                .replace(`{${"dadiCode"}}`, encodeURIComponent(String(requestParams['dadiCode'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns traffic warnings nearby to {station}
         * @summary Get traffic warning information
         * @param {requestParameters} [GetTrafficWarningsByStationCodeRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrafficWarningsByStationCode(requestParams: GetTrafficWarningsByStationCodeRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'stationCode' is not null or undefined
            if (requestParams['stationCode'] === null || requestParams['stationCode'] === undefined) {
                throw new RequiredError('stationCode','Required parameter stationCode was null or undefined when calling getTrafficWarningsByStationCode.');
            }
            const localVarPath = `/travel/{stationCode}`
                .replace(`{${"stationCode"}}`, encodeURIComponent(String(requestParams['stationCode'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves or (or saves if {saveMode} is \"save\") user's settings
         * @summary Retrieves/Saves user's settings
         * @param {requestParameters} [GetUserSettingsRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserSettings(requestParams: GetUserSettingsRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'saveMode' is not null or undefined
            if (requestParams['saveMode'] === null || requestParams['saveMode'] === undefined) {
                throw new RequiredError('saveMode','Required parameter saveMode was null or undefined when calling getUserSettings.');
            }
            // verify required parameter 'udid' is not null or undefined
            if (requestParams['udid'] === null || requestParams['udid'] === undefined) {
                throw new RequiredError('udid','Required parameter udid was null or undefined when calling getUserSettings.');
            }
            // verify required parameter 'applicationCode' is not null or undefined
            if (requestParams['applicationCode'] === null || requestParams['applicationCode'] === undefined) {
                throw new RequiredError('applicationCode','Required parameter applicationCode was null or undefined when calling getUserSettings.');
            }
            // verify required parameter 'platform' is not null or undefined
            if (requestParams['platform'] === null || requestParams['platform'] === undefined) {
                throw new RequiredError('platform','Required parameter platform was null or undefined when calling getUserSettings.');
            }
            // verify required parameter 'userId' is not null or undefined
            if (requestParams['userId'] === null || requestParams['userId'] === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling getUserSettings.');
            }
            const localVarPath = `/settings/{saveMode}/{udid}/{applicationCode}_{platform}/{userId}`
                .replace(`{${"saveMode"}}`, encodeURIComponent(String(requestParams['saveMode'])))
                .replace(`{${"udid"}}`, encodeURIComponent(String(requestParams['udid'])))
                .replace(`{${"applicationCode"}}`, encodeURIComponent(String(requestParams['applicationCode'])))
                .replace(`{${"platform"}}`, encodeURIComponent(String(requestParams['platform'])))
                .replace(`{${"userId"}}`, encodeURIComponent(String(requestParams['userId'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve {station} configuration (Phones)
         * @summary Retrieve {station} configuration (Phones)
         * @param {requestParameters} [InitAppRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initApp(requestParams: InitAppRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'udid' is not null or undefined
            if (requestParams['udid'] === null || requestParams['udid'] === undefined) {
                throw new RequiredError('udid','Required parameter udid was null or undefined when calling initApp.');
            }
            // verify required parameter 'applicationCode' is not null or undefined
            if (requestParams['applicationCode'] === null || requestParams['applicationCode'] === undefined) {
                throw new RequiredError('applicationCode','Required parameter applicationCode was null or undefined when calling initApp.');
            }
            // verify required parameter 'platform' is not null or undefined
            if (requestParams['platform'] === null || requestParams['platform'] === undefined) {
                throw new RequiredError('platform','Required parameter platform was null or undefined when calling initApp.');
            }
            // verify required parameter 'location' is not null or undefined
            if (requestParams['location'] === null || requestParams['location'] === undefined) {
                throw new RequiredError('location','Required parameter location was null or undefined when calling initApp.');
            }
            // verify required parameter 'userId' is not null or undefined
            if (requestParams['userId'] === null || requestParams['userId'] === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling initApp.');
            }
            // verify required parameter 'advertiserId' is not null or undefined
            if (requestParams['advertiserId'] === null || requestParams['advertiserId'] === undefined) {
                throw new RequiredError('advertiserId','Required parameter advertiserId was null or undefined when calling initApp.');
            }
            // verify required parameter 'deeplink' is not null or undefined
            if (requestParams['deeplink'] === null || requestParams['deeplink'] === undefined) {
                throw new RequiredError('deeplink','Required parameter deeplink was null or undefined when calling initApp.');
            }
            const localVarPath = `/init/{udid}/{applicationCode}_{platform}/{location}/{userId}/{advertiserId}/{deeplink}`
                .replace(`{${"udid"}}`, encodeURIComponent(String(requestParams['udid'])))
                .replace(`{${"applicationCode"}}`, encodeURIComponent(String(requestParams['applicationCode'])))
                .replace(`{${"platform"}}`, encodeURIComponent(String(requestParams['platform'])))
                .replace(`{${"location"}}`, encodeURIComponent(String(requestParams['location'])))
                .replace(`{${"userId"}}`, encodeURIComponent(String(requestParams['userId'])))
                .replace(`{${"advertiserId"}}`, encodeURIComponent(String(requestParams['advertiserId'])))
                .replace(`{${"deeplink"}}`, encodeURIComponent(String(requestParams['deeplink'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (requestParams['test'] !== undefined) {
                localVarQueryParameter['test'] = requestParams['test'];
            }
            if (requestParams['X_Forwarded_For'] !== undefined && requestParams['X_Forwarded_For'] !== null) {
                localVarHeaderParameter['X-Forwarded-For'] = String(requestParams['X_Forwarded_For']);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * \"init\" but with Dadi ID
         * @summary \"init\" but with Dadi ID
         * @param {requestParameters} [InitWithDadiIdRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initWithDadiId(requestParams: InitWithDadiIdRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'dadiCode' is not null or undefined
            if (requestParams['dadiCode'] === null || requestParams['dadiCode'] === undefined) {
                throw new RequiredError('dadiCode','Required parameter dadiCode was null or undefined when calling initWithDadiId.');
            }
            const localVarPath = `/initdadi/{dadiCode}`
                .replace(`{${"dadiCode"}}`, encodeURIComponent(String(requestParams['dadiCode'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (requestParams['stationBrandRelated_StationType']) {
                localVarQueryParameter['stationBrandRelated.StationType[]'] = requestParams['stationBrandRelated_StationType'];
            }
            if (requestParams['stationRelated_StationType']) {
                localVarQueryParameter['stationRelated.StationType[]'] = requestParams['stationRelated_StationType'];
            }
            if (requestParams['test'] !== undefined) {
                localVarQueryParameter['test'] = requestParams['test'];
            }
            if (requestParams['X_Forwarded_For'] !== undefined && requestParams['X_Forwarded_For'] !== null) {
                localVarHeaderParameter['X-Forwarded-For'] = String(requestParams['X_Forwarded_For']);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a station's (potentially random) HTML Panel
         * @summary Retrieve a station's (potentially random) HTML Panel
         * @param {requestParameters} [InitWithStationCodeRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initWithStationCode(requestParams: InitWithStationCodeRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'stationCode' is not null or undefined
            if (requestParams['stationCode'] === null || requestParams['stationCode'] === undefined) {
                throw new RequiredError('stationCode','Required parameter stationCode was null or undefined when calling initWithStationCode.');
            }
            const localVarPath = `/initweb/{stationCode}`
                .replace(`{${"stationCode"}}`, encodeURIComponent(String(requestParams['stationCode'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (requestParams['stationBrandRelated_StationType']) {
                localVarQueryParameter['stationBrandRelated.StationType[]'] = requestParams['stationBrandRelated_StationType'];
            }
            if (requestParams['stationRelated_StationType']) {
                localVarQueryParameter['stationRelated.StationType[]'] = requestParams['stationRelated_StationType'];
            }
            if (requestParams['test'] !== undefined) {
                localVarQueryParameter['test'] = requestParams['test'];
            }
            if (requestParams['X_Forwarded_For'] !== undefined && requestParams['X_Forwarded_For'] !== null) {
                localVarHeaderParameter['X-Forwarded-For'] = String(requestParams['X_Forwarded_For']);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve \"events\" but with a Dadi ID
         * @summary Get the latest 250 events for a Station
         * @param {requestParameters} [LatestEventsRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latestEvents(requestParams: LatestEventsRequest, options: any = {}): AxiosArgs {
            const localVarPath = `/latestEvents`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (requestParams['DadiCode'] !== undefined) {
                localVarQueryParameter['DadiCode'] = requestParams['DadiCode'];
            }
            if (requestParams['StationCode'] !== undefined) {
                localVarQueryParameter['StationCode'] = requestParams['StationCode'];
            }
            if (requestParams['StationId'] !== undefined) {
                localVarQueryParameter['StationId'] = requestParams['StationId'];
            }
            if (requestParams['from'] !== undefined) {
                localVarQueryParameter['from'] = requestParams['from'];
            }
            if (requestParams['_o'] !== undefined) {
                localVarQueryParameter['_o'] = requestParams['_o'];
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Generic incoming logger so external applications can send any log they like
         * @summary Register any log
         * @param {requestParameters} [LogRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        log(requestParams: LogRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'type' is not null or undefined
            if (requestParams['type'] === null || requestParams['type'] === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling log.');
            }
            // verify required parameter 'entity' is not null or undefined
            if (requestParams['entity'] === null || requestParams['entity'] === undefined) {
                throw new RequiredError('entity','Required parameter entity was null or undefined when calling log.');
            }
            // verify required parameter 'message' is not null or undefined
            if (requestParams['message'] === null || requestParams['message'] === undefined) {
                throw new RequiredError('message','Required parameter message was null or undefined when calling log.');
            }
            const localVarPath = `/log`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (requestParams['type'] !== undefined) {
                localVarQueryParameter['type'] = requestParams['type'];
            }
            if (requestParams['entity'] !== undefined) {
                localVarQueryParameter['entity'] = requestParams['entity'];
            }
            if (requestParams['message'] !== undefined) {
                localVarQueryParameter['message'] = requestParams['message'];
            }
            if (requestParams['also'] !== undefined) {
                localVarQueryParameter['also'] = requestParams['also'];
            }
            if (requestParams['USER'] !== undefined && requestParams['USER'] !== null) {
                localVarHeaderParameter['USER'] = String(requestParams['USER']);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Given valid credentials, will provide user information and hint at how long their cookie should last (not authentication!)
         * @summary Get user information
         * @param {requestParameters} [LoginRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(requestParams: LoginRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'udid' is not null or undefined
            if (requestParams['udid'] === null || requestParams['udid'] === undefined) {
                throw new RequiredError('udid','Required parameter udid was null or undefined when calling login.');
            }
            // verify required parameter 'applicationCode' is not null or undefined
            if (requestParams['applicationCode'] === null || requestParams['applicationCode'] === undefined) {
                throw new RequiredError('applicationCode','Required parameter applicationCode was null or undefined when calling login.');
            }
            // verify required parameter 'platform' is not null or undefined
            if (requestParams['platform'] === null || requestParams['platform'] === undefined) {
                throw new RequiredError('platform','Required parameter platform was null or undefined when calling login.');
            }
            // verify required parameter 'location' is not null or undefined
            if (requestParams['location'] === null || requestParams['location'] === undefined) {
                throw new RequiredError('location','Required parameter location was null or undefined when calling login.');
            }
            // verify required parameter 'email' is not null or undefined
            if (requestParams['email'] === null || requestParams['email'] === undefined) {
                throw new RequiredError('email','Required parameter email was null or undefined when calling login.');
            }
            // verify required parameter 'password' is not null or undefined
            if (requestParams['password'] === null || requestParams['password'] === undefined) {
                throw new RequiredError('password','Required parameter password was null or undefined when calling login.');
            }
            // verify required parameter 'device' is not null or undefined
            if (requestParams['device'] === null || requestParams['device'] === undefined) {
                throw new RequiredError('device','Required parameter device was null or undefined when calling login.');
            }
            const localVarPath = `/login/{udid}/{applicationCode}_{platform}/{location}/{email}/{password}/{device}`
                .replace(`{${"udid"}}`, encodeURIComponent(String(requestParams['udid'])))
                .replace(`{${"applicationCode"}}`, encodeURIComponent(String(requestParams['applicationCode'])))
                .replace(`{${"platform"}}`, encodeURIComponent(String(requestParams['platform'])))
                .replace(`{${"location"}}`, encodeURIComponent(String(requestParams['location'])))
                .replace(`{${"email"}}`, encodeURIComponent(String(requestParams['email'])))
                .replace(`{${"password"}}`, encodeURIComponent(String(requestParams['password'])))
                .replace(`{${"device"}}`, encodeURIComponent(String(requestParams['device'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Logs logging user out (does not actually log user out, pretty much just logs it)
         * @summary Logs logging user out
         * @param {requestParameters} [LogoutRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(requestParams: LogoutRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'udid' is not null or undefined
            if (requestParams['udid'] === null || requestParams['udid'] === undefined) {
                throw new RequiredError('udid','Required parameter udid was null or undefined when calling logout.');
            }
            // verify required parameter 'applicationCode' is not null or undefined
            if (requestParams['applicationCode'] === null || requestParams['applicationCode'] === undefined) {
                throw new RequiredError('applicationCode','Required parameter applicationCode was null or undefined when calling logout.');
            }
            // verify required parameter 'platform' is not null or undefined
            if (requestParams['platform'] === null || requestParams['platform'] === undefined) {
                throw new RequiredError('platform','Required parameter platform was null or undefined when calling logout.');
            }
            // verify required parameter 'userId' is not null or undefined
            if (requestParams['userId'] === null || requestParams['userId'] === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling logout.');
            }
            const localVarPath = `/logout/{udid}/{applicationCode}_{platform}/{userId}`
                .replace(`{${"udid"}}`, encodeURIComponent(String(requestParams['udid'])))
                .replace(`{${"applicationCode"}}`, encodeURIComponent(String(requestParams['applicationCode'])))
                .replace(`{${"platform"}}`, encodeURIComponent(String(requestParams['platform'])))
                .replace(`{${"userId"}}`, encodeURIComponent(String(requestParams['userId'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Returns 4 randomly ordered local stations
         * @param {requestParameters} [LuckydipRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        luckydip(requestParams: LuckydipRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'region' is not null or undefined
            if (requestParams['region'] === null || requestParams['region'] === undefined) {
                throw new RequiredError('region','Required parameter region was null or undefined when calling luckydip.');
            }
            const localVarPath = `/luckydip/{region}`
                .replace(`{${"region"}}`, encodeURIComponent(String(requestParams['region'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * One-time request to obtain JWT token without user credentials (<a href=\"https://bauer-specialist.atlassian.net/wiki/spaces/AUD/pages/773947418\">readme</a>)
         * @summary Get JWT token
         * @param {requestParameters} [MakeTokenRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeToken(requestParams: MakeTokenRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'userId' is not null or undefined
            if (requestParams['userId'] === null || requestParams['userId'] === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling makeToken.');
            }
            // verify required parameter 'deviceId' is not null or undefined
            if (requestParams['deviceId'] === null || requestParams['deviceId'] === undefined) {
                throw new RequiredError('deviceId','Required parameter deviceId was null or undefined when calling makeToken.');
            }
            const localVarPath = `/makeToken/{userId}/{deviceId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(requestParams['userId'])))
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(requestParams['deviceId'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return Podcast Channels that belong to {station}
         * @summary Get podcast channels station object
         * @param {requestParameters} [PodcastchannelsstationRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        podcastchannelsstation(requestParams: PodcastchannelsstationRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'stationCode' is not null or undefined
            if (requestParams['stationCode'] === null || requestParams['stationCode'] === undefined) {
                throw new RequiredError('stationCode','Required parameter stationCode was null or undefined when calling podcastchannelsstation.');
            }
            const localVarPath = `/podcastchannelsstation/{stationCode}`
                .replace(`{${"stationCode"}}`, encodeURIComponent(String(requestParams['stationCode'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get list of all podcasts by region.
         * @param {requestParameters} [PodcastsByRegionCodeRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        podcastsByRegionCode(requestParams: PodcastsByRegionCodeRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'region' is not null or undefined
            if (requestParams['region'] === null || requestParams['region'] === undefined) {
                throw new RequiredError('region','Required parameter region was null or undefined when calling podcastsByRegionCode.');
            }
            const localVarPath = `/podcastchannelsregion/{region}`
                .replace(`{${"region"}}`, encodeURIComponent(String(requestParams['region'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (requestParams['StationId']) {
                localVarQueryParameter['StationId[]'] = requestParams['StationId'];
            }
            if (requestParams['AppId']) {
                localVarQueryParameter['AppId[]'] = requestParams['AppId'];
            }
            if (requestParams['_p'] !== undefined) {
                localVarQueryParameter['_p'] = requestParams['_p'];
            }
            if (requestParams['_pp'] !== undefined) {
                localVarQueryParameter['_pp'] = requestParams['_pp'];
            }
            if (requestParams['_o'] !== undefined) {
                localVarQueryParameter['_o'] = requestParams['_o'];
            }
            if (requestParams['_filter']) {
                localVarQueryParameter['_filter[]'] = requestParams['_filter'];
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Create a Shuttle Link object
         * @param {requestParameters} [PostShuttlelinksRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postShuttlelinks(requestParams: PostShuttlelinksRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'title' is not null or undefined
            if (requestParams['title'] === null || requestParams['title'] === undefined) {
                throw new RequiredError('title','Required parameter title was null or undefined when calling postShuttlelinks.');
            }
            // verify required parameter 'application_id' is not null or undefined
            if (requestParams['application_id'] === null || requestParams['application_id'] === undefined) {
                throw new RequiredError('application_id','Required parameter application_id was null or undefined when calling postShuttlelinks.');
            }
            // verify required parameter 'description' is not null or undefined
            if (requestParams['description'] === null || requestParams['description'] === undefined) {
                throw new RequiredError('description','Required parameter description was null or undefined when calling postShuttlelinks.');
            }
            // verify required parameter 'image_url' is not null or undefined
            if (requestParams['image_url'] === null || requestParams['image_url'] === undefined) {
                throw new RequiredError('image_url','Required parameter image_url was null or undefined when calling postShuttlelinks.');
            }
            // verify required parameter 'ios_url' is not null or undefined
            if (requestParams['ios_url'] === null || requestParams['ios_url'] === undefined) {
                throw new RequiredError('ios_url','Required parameter ios_url was null or undefined when calling postShuttlelinks.');
            }
            // verify required parameter 'android_url' is not null or undefined
            if (requestParams['android_url'] === null || requestParams['android_url'] === undefined) {
                throw new RequiredError('android_url','Required parameter android_url was null or undefined when calling postShuttlelinks.');
            }
            // verify required parameter 'desktop_url' is not null or undefined
            if (requestParams['desktop_url'] === null || requestParams['desktop_url'] === undefined) {
                throw new RequiredError('desktop_url','Required parameter desktop_url was null or undefined when calling postShuttlelinks.');
            }
            const localVarPath = `/shuttlelinks`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (requestParams['id'] !== undefined) {
                localVarQueryParameter['id'] = requestParams['id'];
            }
            if (requestParams['title'] !== undefined) {
                localVarQueryParameter['title'] = requestParams['title'];
            }
            if (requestParams['application_id'] !== undefined) {
                localVarQueryParameter['application_id'] = requestParams['application_id'];
            }
            if (requestParams['description'] !== undefined) {
                localVarQueryParameter['description'] = requestParams['description'];
            }
            if (requestParams['image_url'] !== undefined) {
                localVarQueryParameter['image_url'] = requestParams['image_url'];
            }
            if (requestParams['ios_url'] !== undefined) {
                localVarQueryParameter['ios_url'] = requestParams['ios_url'];
            }
            if (requestParams['android_url'] !== undefined) {
                localVarQueryParameter['android_url'] = requestParams['android_url'];
            }
            if (requestParams['desktop_url'] !== undefined) {
                localVarQueryParameter['desktop_url'] = requestParams['desktop_url'];
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns premium subcription status for a user, via the Bearer auth header
         * @summary Return subcription status for a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        premiumStatusForUser(options: any = {}): AxiosArgs {
            const localVarPath = `/premiumStatusForUser`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a user account
         * @summary Creates a user account
         * @param {requestParameters} [RegisterUserRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerUser(requestParams: RegisterUserRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'udid' is not null or undefined
            if (requestParams['udid'] === null || requestParams['udid'] === undefined) {
                throw new RequiredError('udid','Required parameter udid was null or undefined when calling registerUser.');
            }
            // verify required parameter 'applicationCode' is not null or undefined
            if (requestParams['applicationCode'] === null || requestParams['applicationCode'] === undefined) {
                throw new RequiredError('applicationCode','Required parameter applicationCode was null or undefined when calling registerUser.');
            }
            // verify required parameter 'platform' is not null or undefined
            if (requestParams['platform'] === null || requestParams['platform'] === undefined) {
                throw new RequiredError('platform','Required parameter platform was null or undefined when calling registerUser.');
            }
            // verify required parameter 'fullName' is not null or undefined
            if (requestParams['fullName'] === null || requestParams['fullName'] === undefined) {
                throw new RequiredError('fullName','Required parameter fullName was null or undefined when calling registerUser.');
            }
            // verify required parameter 'email' is not null or undefined
            if (requestParams['email'] === null || requestParams['email'] === undefined) {
                throw new RequiredError('email','Required parameter email was null or undefined when calling registerUser.');
            }
            // verify required parameter 'gender' is not null or undefined
            if (requestParams['gender'] === null || requestParams['gender'] === undefined) {
                throw new RequiredError('gender','Required parameter gender was null or undefined when calling registerUser.');
            }
            // verify required parameter 'location' is not null or undefined
            if (requestParams['location'] === null || requestParams['location'] === undefined) {
                throw new RequiredError('location','Required parameter location was null or undefined when calling registerUser.');
            }
            // verify required parameter 'age' is not null or undefined
            if (requestParams['age'] === null || requestParams['age'] === undefined) {
                throw new RequiredError('age','Required parameter age was null or undefined when calling registerUser.');
            }
            // verify required parameter 'password' is not null or undefined
            if (requestParams['password'] === null || requestParams['password'] === undefined) {
                throw new RequiredError('password','Required parameter password was null or undefined when calling registerUser.');
            }
            const localVarPath = `/register/{udid}/{applicationCode}_{platform}/{fullName}/{email}/{gender}/{location}/{age}/{password}`
                .replace(`{${"udid"}}`, encodeURIComponent(String(requestParams['udid'])))
                .replace(`{${"applicationCode"}}`, encodeURIComponent(String(requestParams['applicationCode'])))
                .replace(`{${"platform"}}`, encodeURIComponent(String(requestParams['platform'])))
                .replace(`{${"fullName"}}`, encodeURIComponent(String(requestParams['fullName'])))
                .replace(`{${"email"}}`, encodeURIComponent(String(requestParams['email'])))
                .replace(`{${"gender"}}`, encodeURIComponent(String(requestParams['gender'])))
                .replace(`{${"location"}}`, encodeURIComponent(String(requestParams['location'])))
                .replace(`{${"age"}}`, encodeURIComponent(String(requestParams['age'])))
                .replace(`{${"password"}}`, encodeURIComponent(String(requestParams['password'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Reset {user.email}'s password and send a reset email
         * @summary Reset user's password
         * @param {requestParameters} [ResetUserPasswordRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetUserPassword(requestParams: ResetUserPasswordRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'udid' is not null or undefined
            if (requestParams['udid'] === null || requestParams['udid'] === undefined) {
                throw new RequiredError('udid','Required parameter udid was null or undefined when calling resetUserPassword.');
            }
            // verify required parameter 'applicationCode' is not null or undefined
            if (requestParams['applicationCode'] === null || requestParams['applicationCode'] === undefined) {
                throw new RequiredError('applicationCode','Required parameter applicationCode was null or undefined when calling resetUserPassword.');
            }
            // verify required parameter 'platform' is not null or undefined
            if (requestParams['platform'] === null || requestParams['platform'] === undefined) {
                throw new RequiredError('platform','Required parameter platform was null or undefined when calling resetUserPassword.');
            }
            // verify required parameter 'emaill' is not null or undefined
            if (requestParams['emaill'] === null || requestParams['emaill'] === undefined) {
                throw new RequiredError('emaill','Required parameter emaill was null or undefined when calling resetUserPassword.');
            }
            const localVarPath = `/reset/{udid}/{applicationCode}_{platform}/{emaill}`
                .replace(`{${"udid"}}`, encodeURIComponent(String(requestParams['udid'])))
                .replace(`{${"applicationCode"}}`, encodeURIComponent(String(requestParams['applicationCode'])))
                .replace(`{${"platform"}}`, encodeURIComponent(String(requestParams['platform'])))
                .replace(`{${"emaill"}}`, encodeURIComponent(String(requestParams['emaill'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get list of all boxset episode that have been updated since provided date, by region.
         * @summary Search boxset episode updates by region
         * @param {requestParameters} [SearchBoxSetEpisodeUpdatesRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchBoxSetEpisodeUpdates(requestParams: SearchBoxSetEpisodeUpdatesRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'region' is not null or undefined
            if (requestParams['region'] === null || requestParams['region'] === undefined) {
                throw new RequiredError('region','Required parameter region was null or undefined when calling searchBoxSetEpisodeUpdates.');
            }
            const localVarPath = `/searchBoxSetEpisodeUpdates/{region}`
                .replace(`{${"region"}}`, encodeURIComponent(String(requestParams['region'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (requestParams['since'] !== undefined) {
                localVarQueryParameter['since'] = requestParams['since'];
            }
            if (requestParams['_pp'] !== undefined) {
                localVarQueryParameter['_pp'] = requestParams['_pp'];
            }
            if (requestParams['include'] !== undefined) {
                localVarQueryParameter['include'] = requestParams['include'];
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get list of all boxsets that have been updated since provided date, by region.
         * @summary Search boxset updates by region
         * @param {requestParameters} [SearchBoxSetUpdatesRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchBoxSetUpdates(requestParams: SearchBoxSetUpdatesRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'region' is not null or undefined
            if (requestParams['region'] === null || requestParams['region'] === undefined) {
                throw new RequiredError('region','Required parameter region was null or undefined when calling searchBoxSetUpdates.');
            }
            const localVarPath = `/searchBoxSetUpdates/{region}`
                .replace(`{${"region"}}`, encodeURIComponent(String(requestParams['region'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (requestParams['since'] !== undefined) {
                localVarQueryParameter['since'] = requestParams['since'];
            }
            if (requestParams['_pp'] !== undefined) {
                localVarQueryParameter['_pp'] = requestParams['_pp'];
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get list of all listen again episodes that have been updated since provided date, by region.
         * @param {requestParameters} [SearchListenAgainEpisodeUpdatesRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchListenAgainEpisodeUpdates(requestParams: SearchListenAgainEpisodeUpdatesRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'region' is not null or undefined
            if (requestParams['region'] === null || requestParams['region'] === undefined) {
                throw new RequiredError('region','Required parameter region was null or undefined when calling searchListenAgainEpisodeUpdates.');
            }
            const localVarPath = `/searchListenAgainEpisodeUpdates/{region}`
                .replace(`{${"region"}}`, encodeURIComponent(String(requestParams['region'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (requestParams['since'] !== undefined) {
                localVarQueryParameter['since'] = requestParams['since'];
            }
            if (requestParams['_pp'] !== undefined) {
                localVarQueryParameter['_pp'] = requestParams['_pp'];
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get list of all listen again that have been updated since provided date, by region.
         * @summary Search listen again show updates by region
         * @param {requestParameters} [SearchListenAgainShowUpdatesRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchListenAgainShowUpdates(requestParams: SearchListenAgainShowUpdatesRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'region' is not null or undefined
            if (requestParams['region'] === null || requestParams['region'] === undefined) {
                throw new RequiredError('region','Required parameter region was null or undefined when calling searchListenAgainShowUpdates.');
            }
            const localVarPath = `/searchListenAgainShowUpdates/{region}`
                .replace(`{${"region"}}`, encodeURIComponent(String(requestParams['region'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (requestParams['since'] !== undefined) {
                localVarQueryParameter['since'] = requestParams['since'];
            }
            if (requestParams['_pp'] !== undefined) {
                localVarQueryParameter['_pp'] = requestParams['_pp'];
            }
            if (requestParams['include']) {
                localVarQueryParameter['include'] = requestParams['include'].join(COLLECTION_FORMATS["csv"]);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get list of all podcast episodes that have been updated since provided date, by region.
         * @summary Search podcast episode updates by region
         * @param {requestParameters} [SearchPodcastEpisodeUpdatesRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPodcastEpisodeUpdates(requestParams: SearchPodcastEpisodeUpdatesRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'region' is not null or undefined
            if (requestParams['region'] === null || requestParams['region'] === undefined) {
                throw new RequiredError('region','Required parameter region was null or undefined when calling searchPodcastEpisodeUpdates.');
            }
            const localVarPath = `/searchPodcastEpisodeUpdates/{region}`
                .replace(`{${"region"}}`, encodeURIComponent(String(requestParams['region'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (requestParams['since'] !== undefined) {
                localVarQueryParameter['since'] = requestParams['since'];
            }
            if (requestParams['_pp'] !== undefined) {
                localVarQueryParameter['_pp'] = requestParams['_pp'];
            }
            if (requestParams['include'] !== undefined) {
                localVarQueryParameter['include'] = requestParams['include'];
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get list of all podcast show/channels that have been updated since provided date, by region.
         * @param {requestParameters} [SearchPodcastShowUpdatesRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPodcastShowUpdates(requestParams: SearchPodcastShowUpdatesRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'region' is not null or undefined
            if (requestParams['region'] === null || requestParams['region'] === undefined) {
                throw new RequiredError('region','Required parameter region was null or undefined when calling searchPodcastShowUpdates.');
            }
            const localVarPath = `/searchPodcastShowUpdates/{region}`
                .replace(`{${"region"}}`, encodeURIComponent(String(requestParams['region'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (requestParams['since'] !== undefined) {
                localVarQueryParameter['since'] = requestParams['since'];
            }
            if (requestParams['_pp'] !== undefined) {
                localVarQueryParameter['_pp'] = requestParams['_pp'];
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get presenters, built specifically for Search ingestion
         * @param {requestParameters} [SearchPresentersUpdatesRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPresentersUpdates(requestParams: SearchPresentersUpdatesRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'region' is not null or undefined
            if (requestParams['region'] === null || requestParams['region'] === undefined) {
                throw new RequiredError('region','Required parameter region was null or undefined when calling searchPresentersUpdates.');
            }
            const localVarPath = `/searchPresentersUpdates/{region}`
                .replace(`{${"region"}}`, encodeURIComponent(String(requestParams['region'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (requestParams['since'] !== undefined) {
                localVarQueryParameter['since'] = requestParams['since'];
            }
            if (requestParams['_pp'] !== undefined) {
                localVarQueryParameter['_pp'] = requestParams['_pp'];
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Rebuild {streamUrl} and redirect to it
         * @summary Rebuild {streamUrl} and redirect to it
         * @param {requestParameters} [SetSharpstreamRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setSharpstream(requestParams: SetSharpstreamRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'i' is not null or undefined
            if (requestParams['i'] === null || requestParams['i'] === undefined) {
                throw new RequiredError('i','Required parameter i was null or undefined when calling setSharpstream.');
            }
            const localVarPath = `/sharpstream`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (requestParams['i'] !== undefined) {
                localVarQueryParameter['i'] = requestParams['i'];
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Generates and sends email to {user} reminding them of {station}'s current show
         * @param {requestParameters} [SetUserReminderRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUserReminder(requestParams: SetUserReminderRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'udid' is not null or undefined
            if (requestParams['udid'] === null || requestParams['udid'] === undefined) {
                throw new RequiredError('udid','Required parameter udid was null or undefined when calling setUserReminder.');
            }
            // verify required parameter 'applicationCode' is not null or undefined
            if (requestParams['applicationCode'] === null || requestParams['applicationCode'] === undefined) {
                throw new RequiredError('applicationCode','Required parameter applicationCode was null or undefined when calling setUserReminder.');
            }
            // verify required parameter 'platform' is not null or undefined
            if (requestParams['platform'] === null || requestParams['platform'] === undefined) {
                throw new RequiredError('platform','Required parameter platform was null or undefined when calling setUserReminder.');
            }
            // verify required parameter 'stationCode' is not null or undefined
            if (requestParams['stationCode'] === null || requestParams['stationCode'] === undefined) {
                throw new RequiredError('stationCode','Required parameter stationCode was null or undefined when calling setUserReminder.');
            }
            // verify required parameter 'timeCode' is not null or undefined
            if (requestParams['timeCode'] === null || requestParams['timeCode'] === undefined) {
                throw new RequiredError('timeCode','Required parameter timeCode was null or undefined when calling setUserReminder.');
            }
            // verify required parameter 'userId' is not null or undefined
            if (requestParams['userId'] === null || requestParams['userId'] === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling setUserReminder.');
            }
            const localVarPath = `/reminder/{udid}/{applicationCode}_{platform}/{stationCode}/{timeCode}/{userId}`
                .replace(`{${"udid"}}`, encodeURIComponent(String(requestParams['udid'])))
                .replace(`{${"applicationCode"}}`, encodeURIComponent(String(requestParams['applicationCode'])))
                .replace(`{${"platform"}}`, encodeURIComponent(String(requestParams['platform'])))
                .replace(`{${"stationCode"}}`, encodeURIComponent(String(requestParams['stationCode'])))
                .replace(`{${"timeCode"}}`, encodeURIComponent(String(requestParams['timeCode'])))
                .replace(`{${"userId"}}`, encodeURIComponent(String(requestParams['userId'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns stream auth level based on a respective stream url
         * @summary Authenticated streams lookup endpoint
         * @param {requestParameters} [StreamAuthLevelRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        streamAuthLevel(requestParams: StreamAuthLevelRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'StreamUrl' is not null or undefined
            if (requestParams['StreamUrl'] === null || requestParams['StreamUrl'] === undefined) {
                throw new RequiredError('StreamUrl','Required parameter StreamUrl was null or undefined when calling streamAuthLevel.');
            }
            const localVarPath = `/streamAuthLevel`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (requestParams['StreamUrl'] !== undefined) {
                localVarQueryParameter['StreamUrl'] = requestParams['StreamUrl'];
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * DEPRECATED. Update a registration token.
         * @summary DEPRECATED. Update a registration token.
         * @param {requestParameters} [UpdateTokenRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateToken(requestParams: UpdateTokenRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'registrationToken' is not null or undefined
            if (requestParams['registrationToken'] === null || requestParams['registrationToken'] === undefined) {
                throw new RequiredError('registrationToken','Required parameter registrationToken was null or undefined when calling updateToken.');
            }
            const localVarPath = `/tokens/{registrationToken}`
                .replace(`{${"registrationToken"}}`, encodeURIComponent(String(requestParams['registrationToken'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Verifies a user's email post-registration
         * @summary Verifies user email
         * @param {requestParameters} [VerifyUserEmailRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyUserEmail(requestParams: VerifyUserEmailRequest, options: any = {}): AxiosArgs {
            // verify required parameter 'udid' is not null or undefined
            if (requestParams['udid'] === null || requestParams['udid'] === undefined) {
                throw new RequiredError('udid','Required parameter udid was null or undefined when calling verifyUserEmail.');
            }
            // verify required parameter 'applicationCode' is not null or undefined
            if (requestParams['applicationCode'] === null || requestParams['applicationCode'] === undefined) {
                throw new RequiredError('applicationCode','Required parameter applicationCode was null or undefined when calling verifyUserEmail.');
            }
            // verify required parameter 'platform' is not null or undefined
            if (requestParams['platform'] === null || requestParams['platform'] === undefined) {
                throw new RequiredError('platform','Required parameter platform was null or undefined when calling verifyUserEmail.');
            }
            // verify required parameter 'email' is not null or undefined
            if (requestParams['email'] === null || requestParams['email'] === undefined) {
                throw new RequiredError('email','Required parameter email was null or undefined when calling verifyUserEmail.');
            }
            const localVarPath = `/verify/{udid}/{applicationCode}_{platform}/{email}`
                .replace(`{${"udid"}}`, encodeURIComponent(String(requestParams['udid'])))
                .replace(`{${"applicationCode"}}`, encodeURIComponent(String(requestParams['applicationCode'])))
                .replace(`{${"platform"}}`, encodeURIComponent(String(requestParams['platform'])))
                .replace(`{${"email"}}`, encodeURIComponent(String(requestParams['email'])));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};
/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Remove a registration token.
         * @summary Remove a registration token.
         * @param {requestParameters} [DeleteTokenRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteToken(requestParams: DeleteTokenRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deleteToken(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary DEPRECATED. Delete multiple tokens
         * @param {requestParameters} [ForgetTokensRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgetTokens(requestParams: ForgetTokensRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).forgetTokens(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Retrieve Artist Record
         * @summary Get artist object
         * @param {requestParameters} [GetAbsoluteArtistByIdRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAbsoluteArtistById(requestParams: GetAbsoluteArtistByIdRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Artist> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getAbsoluteArtistById(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Retrieve list of aggregator brands
         * @summary Get a list of all active aggregator brands
         * @param {requestParameters} [GetAggregatorBrandsRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAggregatorBrands(requestParams: GetAggregatorBrandsRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AggregatorBrand>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getAggregatorBrands(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Return a list of all Applications
         * @summary Get applications object
         * @param {requestParameters} [GetAppsRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApps(requestParams: GetAppsRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<App>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getApps(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get station details for Dadi ID
         * @param {requestParameters} [GetArticleByDadiIdRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArticleByDadiId(requestParams: GetArticleByDadiIdRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentArticle> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getArticleByDadiId(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get station details for Dadi ID
         * @param {requestParameters} [GetArticleByDadiIdAndFilterRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArticleByDadiIdAndFilter(requestParams: GetArticleByDadiIdAndFilterRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentArticle> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getArticleByDadiIdAndFilter(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get station details
         * @param {requestParameters} [GetArticleByStationCodeRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArticleByStationCode(requestParams: GetArticleByStationCodeRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentArticle> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getArticleByStationCode(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get station details
         * @param {requestParameters} [GetArticleByStationCodeAndFilterRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArticleByStationCodeAndFilter(requestParams: GetArticleByStationCodeAndFilterRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContentArticle>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getArticleByStationCodeAndFilter(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Retrieve list of audible content (podcasts, box set episodes, listen again episodes)<hr><strong style=\"color:red\">*One of these parameters is required: `AppCode`, `RegionCode`, `StationCode`, `RegionCode`</strong>
         * @summary Get audibles object. (AppCode OR RegionCode OR StationCode OR RegionCode parameter is required)
         * @param {requestParameters} [GetAudiblesRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAudibles(requestParams: GetAudiblesRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Audible>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getAudibles(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Deprecated? Prefer getShows
         * @summary Get BoxSet and its episodes
         * @param {requestParameters} [GetBoxsetRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBoxset(requestParams: GetBoxsetRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BoxSet> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getBoxset(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Retrieve set episodes (JSON) -- Not on phones
         * @summary Get set episodes object
         * @param {requestParameters} [GetBoxsetEpisodesByStationCodeRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBoxsetEpisodesByStationCode(requestParams: GetBoxsetEpisodesByStationCodeRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BoxSetEpisode>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getBoxsetEpisodesByStationCode(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get brand object
         * @param {requestParameters} [GetBrandByBrandCodeRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrandByBrandCode(requestParams: GetBrandByBrandCodeRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Brand> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getBrandByBrandCode(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Index of brand entities
         * @param {requestParameters} [GetBrandsRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrands(requestParams: GetBrandsRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Brand>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getBrands(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * DEPRECATED. Stores information about pushable device - {token} is unique identifier
         * @summary DEPRECATED. Get device token object
         * @param {requestParameters} [GetDeviceTokenRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceToken(requestParams: GetDeviceTokenRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceToken> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getDeviceToken(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * DEPRECATED. Index of device tokens
         * @summary DEPRECATED. Get device tokens object
         * @param {requestParameters} [GetDevicesTokensRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDevicesTokens(requestParams: GetDevicesTokensRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Device>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getDevicesTokens(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Retrieve parsed event record (Generally more useful than eventdata)
         * @summary Get event object
         * @param {requestParameters} [GetEventRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvent(requestParams: GetEventRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventsXml> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getEvent(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Retrieve raw-ish event record
         * @summary Retrieve raw-ish event record
         * @param {requestParameters} [GetEventByIdRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventById(requestParams: GetEventByIdRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Event> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getEventById(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Retrieve \"events\" but with a Dadi ID
         * @summary Get playlist with a Dadi ID
         * @param {requestParameters} [GetEventsByDadiIdRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventsByDadiId(requestParams: GetEventsByDadiIdRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NowPlayingSimple>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getEventsByDadiId(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Retrieve up to {limit} events up until {time}
         * @summary Get playlist
         * @param {requestParameters} [GetEventsByStationCodeRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventsByStationCode(requestParams: GetEventsByStationCodeRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NowPlayingSimple>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getEventsByStationCode(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get list of all featured podcasts
         * @param {requestParameters} [GetFeaturedPodcastsByRegionRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturedPodcastsByRegion(requestParams: GetFeaturedPodcastsByRegionRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PodcastChannelRegion>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getFeaturedPodcastsByRegion(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Returns xml document describing a full screen streaming ad
         * @summary Get full screen streaming ad object
         * @param {requestParameters} [GetInstreamRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstream(requestParams: GetInstreamRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Instream> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getInstream(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get listen again episodes
         * @param {requestParameters} [GetListenAgainByDadiIdRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListenAgainByDadiId(requestParams: GetListenAgainByDadiIdRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: Array<ListenAgainEpisode>; }> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getListenAgainByDadiId(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Retrieve historical play information for {station}
         * @summary Get listenagain object
         * @param {requestParameters} [GetListenAgainByStationCodeRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListenAgainByStationCode(requestParams: GetListenAgainByStationCodeRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: Array<ListenAgainEpisode>; }> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getListenAgainByStationCode(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Logs a \"listen\"
         * @param {requestParameters} [GetListenLogsRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListenLogs(requestParams: GetListenLogsRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListenXml> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getListenLogs(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get list of all stations
         * @param {requestParameters} [GetMinifiedStationsRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMinifiedStations(requestParams: GetMinifiedStationsRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Station>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getMinifiedStations(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Returns nearby stations split based on user location
         * @summary Get nearby split stations
         * @param {requestParameters} [GetNearbyStationsRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNearbyStations(requestParams: GetNearbyStationsRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getNearbyStations(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Return the station networks
         * @summary Get station networks
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNetworks(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Network>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getNetworks(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get nowplaying / station on air data of stations
         * @param {requestParameters} [GetNowPlayingAndOnAirForStationsRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNowPlayingAndOnAirForStations(requestParams: GetNowPlayingAndOnAirForStationsRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InlineResponse200>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getNowPlayingAndOnAirForStations(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Return what is currently playing on {station}
         * @summary Get nowplaying object
         * @param {requestParameters} [GetNowPlayingByStationCodeRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNowPlayingByStationCode(requestParams: GetNowPlayingByStationCodeRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getNowPlayingByStationCode(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Return what is currently playing on {station}
         * @summary Get nowplaying object with small time adjustment so it aligns to realtime
         * @param {requestParameters} [GetOaNowPlayingByStationCodeRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOaNowPlayingByStationCode(requestParams: GetOaNowPlayingByStationCodeRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NowPlaying> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getOaNowPlayingByStationCode(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get playlist information for a show or station. Must provide one of ScheduleId, StationCode
         * @param {requestParameters} [GetPlaylistRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylist(requestParams: GetPlaylistRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NowPlayingSimple>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getPlaylist(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Same as `/podcastsbyid/{podcastId}` except the Podcast Channel is merged with Podcast
         * @summary Get podcast object
         * @param {requestParameters} [GetPodcastByIdRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPodcastById(requestParams: GetPodcastByIdRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PodcastEpisode> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getPodcastById(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Return Podcast Channels that belong to {station}
         * @param {requestParameters} [GetPodcastChannelsDadiByDadiIdRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPodcastChannelsDadiByDadiId(requestParams: GetPodcastChannelsDadiByDadiIdRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PodcastChannelRegion>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getPodcastChannelsDadiByDadiId(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Find all podcasts by channel
         * @param {requestParameters} [GetPodcastsByChannelRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPodcastsByChannel(requestParams: GetPodcastsByChannelRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: any; }> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getPodcastsByChannel(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Return Podcasts that belong to {podcastChannel}
         * @summary Get podcasts object
         * @param {requestParameters} [GetPodcastsByChannelIdRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPodcastsByChannelId(requestParams: GetPodcastsByChannelIdRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PodcastEpisode>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getPodcastsByChannelId(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get podcasts by station code
         * @param {requestParameters} [GetPodcastsChannelsByStationCodeRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPodcastsChannelsByStationCode(requestParams: GetPodcastsChannelsByStationCodeRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PodcastChannelRegion>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getPodcastsChannelsByStationCode(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get Premium Incentive information for an App
         * @param {requestParameters} [GetPremiumForAppRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPremiumForApp(requestParams: GetPremiumForAppRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Premium>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getPremiumForApp(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get Premium Incentive information for a Region
         * @param {requestParameters} [GetPremiumForRegionRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPremiumForRegion(requestParams: GetPremiumForRegionRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Premium>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getPremiumForRegion(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get Premium Incentive information for a Station
         * @param {requestParameters} [GetPremiumForStationRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPremiumForStation(requestParams: GetPremiumForStationRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Premium>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getPremiumForStation(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get presenter by ID
         * @param {requestParameters} [GetPresenterByIdRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPresenterById(requestParams: GetPresenterByIdRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Presenter> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getPresenterById(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get presenters. (stationId OR stationCode OR brandCode parameter is required)
         * @param {requestParameters} [GetPresentersRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPresenters(requestParams: GetPresentersRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Presenter>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getPresenters(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * VideoList of regions
         * @summary Get regions object
         * @param {requestParameters} [GetRegionsRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegions(requestParams: GetRegionsRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Region>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getRegions(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Specific endpoint to Google Home Feed
         * @param {requestParameters} [GetRegionsMetaByRegionCodeRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegionsMetaByRegionCode(requestParams: GetRegionsMetaByRegionCodeRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegionsMeta> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getRegionsMetaByRegionCode(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get schedule object
         * @param {requestParameters} [GetScheduleByStationCodeRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScheduleByStationCode(requestParams: GetScheduleByStationCodeRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: Array<Schedule>; }> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getScheduleByStationCode(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * \"schedule\" but with Dadi ID
         * @summary \"schedule\" but with Dadi ID
         * @param {requestParameters} [GetScheduleDadiByDadiIdRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScheduleDadiByDadiId(requestParams: GetScheduleDadiByDadiIdRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: any; }> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getScheduleDadiByDadiId(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get shows object. (AppCode OR BrandCode OR StationCode OR RegionCode parameter is required)
         * @param {requestParameters} [GetShowsRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShows(requestParams: GetShowsRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Show>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getShows(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get a Shuttle Link object
         * @param {requestParameters} [GetShuttlelinksByIdRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShuttlelinksById(requestParams: GetShuttlelinksByIdRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShuttleLink> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getShuttlelinksById(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get SignIn Incentive information for an App
         * @param {requestParameters} [GetSignInIncentivesForAppRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSignInIncentivesForApp(requestParams: GetSignInIncentivesForAppRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SignInIncentive>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getSignInIncentivesForApp(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get SignIn Incentive information for a Station
         * @param {requestParameters} [GetSignInIncentivesForStationRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSignInIncentivesForStation(requestParams: GetSignInIncentivesForStationRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SignInIncentive>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getSignInIncentivesForStation(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get list of all stations
         * @param {requestParameters} [GetStationsRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStations(requestParams: GetStationsRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Station>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getStations(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get station code grouped by port
         * @summary Get station code grouped by port
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStationsByPort(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: any; }> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getStationsByPort(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get list of all stations
         * @param {requestParameters} [GetStationsByRegionCodeRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStationsByRegionCode(requestParams: GetStationsByRegionCodeRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Station>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getStationsByRegionCode(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Retrieves tickets available (via. <a href=\"http://aloud.com/\">Aloud.com)</a>
         * @summary Get tickets object
         * @param {requestParameters} [GetTicketsByStationCodeRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketsByStationCode(requestParams: GetTicketsByStationCodeRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Ticket>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getTicketsByStationCode(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Retrieves \"tickets\" but with Dadi ID
         * @summary Get tickets with Dadi ID
         * @param {requestParameters} [GetTicketsDadiByDadiIdRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketsDadiByDadiId(requestParams: GetTicketsDadiByDadiIdRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Ticket>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getTicketsDadiByDadiId(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * \"travel\" but with a Dadi ID
         * @summary Get travel with Dadi ID
         * @param {requestParameters} [GetTrafficWarningsByDadiIdRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrafficWarningsByDadiId(requestParams: GetTrafficWarningsByDadiIdRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Travel>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getTrafficWarningsByDadiId(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Returns traffic warnings nearby to {station}
         * @summary Get traffic warning information
         * @param {requestParameters} [GetTrafficWarningsByStationCodeRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrafficWarningsByStationCode(requestParams: GetTrafficWarningsByStationCodeRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Travel>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getTrafficWarningsByStationCode(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Retrieves or (or saves if {saveMode} is \"save\") user's settings
         * @summary Retrieves/Saves user's settings
         * @param {requestParameters} [GetUserSettingsRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserSettings(requestParams: GetUserSettingsRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getUserSettings(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Retrieve {station} configuration (Phones)
         * @summary Retrieve {station} configuration (Phones)
         * @param {requestParameters} [InitAppRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initApp(requestParams: InitAppRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Init> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).initApp(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * \"init\" but with Dadi ID
         * @summary \"init\" but with Dadi ID
         * @param {requestParameters} [InitWithDadiIdRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initWithDadiId(requestParams: InitWithDadiIdRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Station> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).initWithDadiId(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Retrieve a station's (potentially random) HTML Panel
         * @summary Retrieve a station's (potentially random) HTML Panel
         * @param {requestParameters} [InitWithStationCodeRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initWithStationCode(requestParams: InitWithStationCodeRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Station> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).initWithStationCode(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Retrieve \"events\" but with a Dadi ID
         * @summary Get the latest 250 events for a Station
         * @param {requestParameters} [LatestEventsRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latestEvents(requestParams: LatestEventsRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NowPlayingReduced>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).latestEvents(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Generic incoming logger so external applications can send any log they like
         * @summary Register any log
         * @param {requestParameters} [LogRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        log(requestParams: LogRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).log(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Given valid credentials, will provide user information and hint at how long their cookie should last (not authentication!)
         * @summary Get user information
         * @param {requestParameters} [LoginRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(requestParams: LoginRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).login(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Logs logging user out (does not actually log user out, pretty much just logs it)
         * @summary Logs logging user out
         * @param {requestParameters} [LogoutRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(requestParams: LogoutRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).logout(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Returns 4 randomly ordered local stations
         * @param {requestParameters} [LuckydipRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        luckydip(requestParams: LuckydipRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).luckydip(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * One-time request to obtain JWT token without user credentials (<a href=\"https://bauer-specialist.atlassian.net/wiki/spaces/AUD/pages/773947418\">readme</a>)
         * @summary Get JWT token
         * @param {requestParameters} [MakeTokenRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeToken(requestParams: MakeTokenRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenStatus> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).makeToken(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Return Podcast Channels that belong to {station}
         * @summary Get podcast channels station object
         * @param {requestParameters} [PodcastchannelsstationRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        podcastchannelsstation(requestParams: PodcastchannelsstationRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PodcastChannelRegion>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).podcastchannelsstation(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get list of all podcasts by region.
         * @param {requestParameters} [PodcastsByRegionCodeRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        podcastsByRegionCode(requestParams: PodcastsByRegionCodeRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PodcastChannelRegion>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).podcastsByRegionCode(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Create a Shuttle Link object
         * @param {requestParameters} [PostShuttlelinksRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postShuttlelinks(requestParams: PostShuttlelinksRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShuttleLink> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).postShuttlelinks(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Returns premium subcription status for a user, via the Bearer auth header
         * @summary Return subcription status for a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        premiumStatusForUser(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserPremiumStatus> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).premiumStatusForUser(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Creates a user account
         * @summary Creates a user account
         * @param {requestParameters} [RegisterUserRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerUser(requestParams: RegisterUserRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).registerUser(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Reset {user.email}'s password and send a reset email
         * @summary Reset user's password
         * @param {requestParameters} [ResetUserPasswordRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetUserPassword(requestParams: ResetUserPasswordRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).resetUserPassword(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get list of all boxset episode that have been updated since provided date, by region.
         * @summary Search boxset episode updates by region
         * @param {requestParameters} [SearchBoxSetEpisodeUpdatesRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchBoxSetEpisodeUpdates(requestParams: SearchBoxSetEpisodeUpdatesRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SearchBoxSetEpisode>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).searchBoxSetEpisodeUpdates(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get list of all boxsets that have been updated since provided date, by region.
         * @summary Search boxset updates by region
         * @param {requestParameters} [SearchBoxSetUpdatesRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchBoxSetUpdates(requestParams: SearchBoxSetUpdatesRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SearchBoxSet>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).searchBoxSetUpdates(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get list of all listen again episodes that have been updated since provided date, by region.
         * @param {requestParameters} [SearchListenAgainEpisodeUpdatesRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchListenAgainEpisodeUpdates(requestParams: SearchListenAgainEpisodeUpdatesRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SearchListenAgainEpisode>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).searchListenAgainEpisodeUpdates(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get list of all listen again that have been updated since provided date, by region.
         * @summary Search listen again show updates by region
         * @param {requestParameters} [SearchListenAgainShowUpdatesRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchListenAgainShowUpdates(requestParams: SearchListenAgainShowUpdatesRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SearchListenAgain>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).searchListenAgainShowUpdates(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get list of all podcast episodes that have been updated since provided date, by region.
         * @summary Search podcast episode updates by region
         * @param {requestParameters} [SearchPodcastEpisodeUpdatesRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPodcastEpisodeUpdates(requestParams: SearchPodcastEpisodeUpdatesRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SearchPodcastEpisode>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).searchPodcastEpisodeUpdates(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get list of all podcast show/channels that have been updated since provided date, by region.
         * @param {requestParameters} [SearchPodcastShowUpdatesRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPodcastShowUpdates(requestParams: SearchPodcastShowUpdatesRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SearchPodcastShow>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).searchPodcastShowUpdates(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get presenters, built specifically for Search ingestion
         * @param {requestParameters} [SearchPresentersUpdatesRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPresentersUpdates(requestParams: SearchPresentersUpdatesRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Presenter>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).searchPresentersUpdates(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Rebuild {streamUrl} and redirect to it
         * @summary Rebuild {streamUrl} and redirect to it
         * @param {requestParameters} [SetSharpstreamRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setSharpstream(requestParams: SetSharpstreamRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).setSharpstream(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Generates and sends email to {user} reminding them of {station}'s current show
         * @param {requestParameters} [SetUserReminderRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUserReminder(requestParams: SetUserReminderRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).setUserReminder(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Returns stream auth level based on a respective stream url
         * @summary Authenticated streams lookup endpoint
         * @param {requestParameters} [StreamAuthLevelRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        streamAuthLevel(requestParams: StreamAuthLevelRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).streamAuthLevel(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * DEPRECATED. Update a registration token.
         * @summary DEPRECATED. Update a registration token.
         * @param {requestParameters} [UpdateTokenRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateToken(requestParams: UpdateTokenRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).updateToken(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Verifies a user's email post-registration
         * @summary Verifies user email
         * @param {requestParameters} [VerifyUserEmailRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyUserEmail(requestParams: VerifyUserEmailRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).verifyUserEmail(requestParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, axios?: AxiosInstance, basePath?: string) {
    return {
        /**
         * Remove a registration token.
         * @summary Remove a registration token.
         * @param {requestParameters} [DeleteTokenRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteToken(requestParams: DeleteTokenRequest, options?: any) {
            return DefaultApiFp(configuration).deleteToken(requestParams, options)(axios, basePath);
        },
        /**
         *
         * @summary DEPRECATED. Delete multiple tokens
         * @param {requestParameters} [ForgetTokensRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgetTokens(requestParams: ForgetTokensRequest, options?: any) {
            return DefaultApiFp(configuration).forgetTokens(requestParams, options)(axios, basePath);
        },
        /**
         * Retrieve Artist Record
         * @summary Get artist object
         * @param {requestParameters} [GetAbsoluteArtistByIdRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAbsoluteArtistById(requestParams: GetAbsoluteArtistByIdRequest, options?: any) {
            return DefaultApiFp(configuration).getAbsoluteArtistById(requestParams, options)(axios, basePath);
        },
        /**
         * Retrieve list of aggregator brands
         * @summary Get a list of all active aggregator brands
         * @param {requestParameters} [GetAggregatorBrandsRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAggregatorBrands(requestParams: GetAggregatorBrandsRequest, options?: any) {
            return DefaultApiFp(configuration).getAggregatorBrands(requestParams, options)(axios, basePath);
        },
        /**
         * Return a list of all Applications
         * @summary Get applications object
         * @param {requestParameters} [GetAppsRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApps(requestParams: GetAppsRequest, options?: any) {
            return DefaultApiFp(configuration).getApps(requestParams, options)(axios, basePath);
        },
        /**
         *
         * @summary Get station details for Dadi ID
         * @param {requestParameters} [GetArticleByDadiIdRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArticleByDadiId(requestParams: GetArticleByDadiIdRequest, options?: any) {
            return DefaultApiFp(configuration).getArticleByDadiId(requestParams, options)(axios, basePath);
        },
        /**
         *
         * @summary Get station details for Dadi ID
         * @param {requestParameters} [GetArticleByDadiIdAndFilterRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArticleByDadiIdAndFilter(requestParams: GetArticleByDadiIdAndFilterRequest, options?: any) {
            return DefaultApiFp(configuration).getArticleByDadiIdAndFilter(requestParams, options)(axios, basePath);
        },
        /**
         *
         * @summary Get station details
         * @param {requestParameters} [GetArticleByStationCodeRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArticleByStationCode(requestParams: GetArticleByStationCodeRequest, options?: any) {
            return DefaultApiFp(configuration).getArticleByStationCode(requestParams, options)(axios, basePath);
        },
        /**
         *
         * @summary Get station details
         * @param {requestParameters} [GetArticleByStationCodeAndFilterRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArticleByStationCodeAndFilter(requestParams: GetArticleByStationCodeAndFilterRequest, options?: any) {
            return DefaultApiFp(configuration).getArticleByStationCodeAndFilter(requestParams, options)(axios, basePath);
        },
        /**
         * Retrieve list of audible content (podcasts, box set episodes, listen again episodes)<hr><strong style=\"color:red\">*One of these parameters is required: `AppCode`, `RegionCode`, `StationCode`, `RegionCode`</strong>
         * @summary Get audibles object. (AppCode OR RegionCode OR StationCode OR RegionCode parameter is required)
         * @param {requestParameters} [GetAudiblesRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAudibles(requestParams: GetAudiblesRequest, options?: any) {
            return DefaultApiFp(configuration).getAudibles(requestParams, options)(axios, basePath);
        },
        /**
         * Deprecated? Prefer getShows
         * @summary Get BoxSet and its episodes
         * @param {requestParameters} [GetBoxsetRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBoxset(requestParams: GetBoxsetRequest, options?: any) {
            return DefaultApiFp(configuration).getBoxset(requestParams, options)(axios, basePath);
        },
        /**
         * Retrieve set episodes (JSON) -- Not on phones
         * @summary Get set episodes object
         * @param {requestParameters} [GetBoxsetEpisodesByStationCodeRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBoxsetEpisodesByStationCode(requestParams: GetBoxsetEpisodesByStationCodeRequest, options?: any) {
            return DefaultApiFp(configuration).getBoxsetEpisodesByStationCode(requestParams, options)(axios, basePath);
        },
        /**
         *
         * @summary Get brand object
         * @param {requestParameters} [GetBrandByBrandCodeRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrandByBrandCode(requestParams: GetBrandByBrandCodeRequest, options?: any) {
            return DefaultApiFp(configuration).getBrandByBrandCode(requestParams, options)(axios, basePath);
        },
        /**
         *
         * @summary Index of brand entities
         * @param {requestParameters} [GetBrandsRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrands(requestParams: GetBrandsRequest, options?: any) {
            return DefaultApiFp(configuration).getBrands(requestParams, options)(axios, basePath);
        },
        /**
         * DEPRECATED. Stores information about pushable device - {token} is unique identifier
         * @summary DEPRECATED. Get device token object
         * @param {requestParameters} [GetDeviceTokenRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceToken(requestParams: GetDeviceTokenRequest, options?: any) {
            return DefaultApiFp(configuration).getDeviceToken(requestParams, options)(axios, basePath);
        },
        /**
         * DEPRECATED. Index of device tokens
         * @summary DEPRECATED. Get device tokens object
         * @param {requestParameters} [GetDevicesTokensRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDevicesTokens(requestParams: GetDevicesTokensRequest, options?: any) {
            return DefaultApiFp(configuration).getDevicesTokens(requestParams, options)(axios, basePath);
        },
        /**
         * Retrieve parsed event record (Generally more useful than eventdata)
         * @summary Get event object
         * @param {requestParameters} [GetEventRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvent(requestParams: GetEventRequest, options?: any) {
            return DefaultApiFp(configuration).getEvent(requestParams, options)(axios, basePath);
        },
        /**
         * Retrieve raw-ish event record
         * @summary Retrieve raw-ish event record
         * @param {requestParameters} [GetEventByIdRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventById(requestParams: GetEventByIdRequest, options?: any) {
            return DefaultApiFp(configuration).getEventById(requestParams, options)(axios, basePath);
        },
        /**
         * Retrieve \"events\" but with a Dadi ID
         * @summary Get playlist with a Dadi ID
         * @param {requestParameters} [GetEventsByDadiIdRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventsByDadiId(requestParams: GetEventsByDadiIdRequest, options?: any) {
            return DefaultApiFp(configuration).getEventsByDadiId(requestParams, options)(axios, basePath);
        },
        /**
         * Retrieve up to {limit} events up until {time}
         * @summary Get playlist
         * @param {requestParameters} [GetEventsByStationCodeRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventsByStationCode(requestParams: GetEventsByStationCodeRequest, options?: any) {
            return DefaultApiFp(configuration).getEventsByStationCode(requestParams, options)(axios, basePath);
        },
        /**
         *
         * @summary Get list of all featured podcasts
         * @param {requestParameters} [GetFeaturedPodcastsByRegionRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturedPodcastsByRegion(requestParams: GetFeaturedPodcastsByRegionRequest, options?: any) {
            return DefaultApiFp(configuration).getFeaturedPodcastsByRegion(requestParams, options)(axios, basePath);
        },
        /**
         * Returns xml document describing a full screen streaming ad
         * @summary Get full screen streaming ad object
         * @param {requestParameters} [GetInstreamRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstream(requestParams: GetInstreamRequest, options?: any) {
            return DefaultApiFp(configuration).getInstream(requestParams, options)(axios, basePath);
        },
        /**
         *
         * @summary Get listen again episodes
         * @param {requestParameters} [GetListenAgainByDadiIdRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListenAgainByDadiId(requestParams: GetListenAgainByDadiIdRequest, options?: any) {
            return DefaultApiFp(configuration).getListenAgainByDadiId(requestParams, options)(axios, basePath);
        },
        /**
         * Retrieve historical play information for {station}
         * @summary Get listenagain object
         * @param {requestParameters} [GetListenAgainByStationCodeRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListenAgainByStationCode(requestParams: GetListenAgainByStationCodeRequest, options?: any) {
            return DefaultApiFp(configuration).getListenAgainByStationCode(requestParams, options)(axios, basePath);
        },
        /**
         *
         * @summary Logs a \"listen\"
         * @param {requestParameters} [GetListenLogsRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListenLogs(requestParams: GetListenLogsRequest, options?: any) {
            return DefaultApiFp(configuration).getListenLogs(requestParams, options)(axios, basePath);
        },
        /**
         *
         * @summary Get list of all stations
         * @param {requestParameters} [GetMinifiedStationsRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMinifiedStations(requestParams: GetMinifiedStationsRequest, options?: any) {
            return DefaultApiFp(configuration).getMinifiedStations(requestParams, options)(axios, basePath);
        },
        /**
         * Returns nearby stations split based on user location
         * @summary Get nearby split stations
         * @param {requestParameters} [GetNearbyStationsRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNearbyStations(requestParams: GetNearbyStationsRequest, options?: any) {
            return DefaultApiFp(configuration).getNearbyStations(requestParams, options)(axios, basePath);
        },
        /**
         * Return the station networks
         * @summary Get station networks
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNetworks(options?: any) {
            return DefaultApiFp(configuration).getNetworks(options)(axios, basePath);
        },
        /**
         *
         * @summary Get nowplaying / station on air data of stations
         * @param {requestParameters} [GetNowPlayingAndOnAirForStationsRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNowPlayingAndOnAirForStations(requestParams: GetNowPlayingAndOnAirForStationsRequest, options?: any) {
            return DefaultApiFp(configuration).getNowPlayingAndOnAirForStations(requestParams, options)(axios, basePath);
        },
        /**
         * Return what is currently playing on {station}
         * @summary Get nowplaying object
         * @param {requestParameters} [GetNowPlayingByStationCodeRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNowPlayingByStationCode(requestParams: GetNowPlayingByStationCodeRequest, options?: any) {
            return DefaultApiFp(configuration).getNowPlayingByStationCode(requestParams, options)(axios, basePath);
        },
        /**
         * Return what is currently playing on {station}
         * @summary Get nowplaying object with small time adjustment so it aligns to realtime
         * @param {requestParameters} [GetOaNowPlayingByStationCodeRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOaNowPlayingByStationCode(requestParams: GetOaNowPlayingByStationCodeRequest, options?: any) {
            return DefaultApiFp(configuration).getOaNowPlayingByStationCode(requestParams, options)(axios, basePath);
        },
        /**
         *
         * @summary Get playlist information for a show or station. Must provide one of ScheduleId, StationCode
         * @param {requestParameters} [GetPlaylistRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylist(requestParams: GetPlaylistRequest, options?: any) {
            return DefaultApiFp(configuration).getPlaylist(requestParams, options)(axios, basePath);
        },
        /**
         * Same as `/podcastsbyid/{podcastId}` except the Podcast Channel is merged with Podcast
         * @summary Get podcast object
         * @param {requestParameters} [GetPodcastByIdRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPodcastById(requestParams: GetPodcastByIdRequest, options?: any) {
            return DefaultApiFp(configuration).getPodcastById(requestParams, options)(axios, basePath);
        },
        /**
         *
         * @summary Return Podcast Channels that belong to {station}
         * @param {requestParameters} [GetPodcastChannelsDadiByDadiIdRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPodcastChannelsDadiByDadiId(requestParams: GetPodcastChannelsDadiByDadiIdRequest, options?: any) {
            return DefaultApiFp(configuration).getPodcastChannelsDadiByDadiId(requestParams, options)(axios, basePath);
        },
        /**
         *
         * @summary Find all podcasts by channel
         * @param {requestParameters} [GetPodcastsByChannelRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPodcastsByChannel(requestParams: GetPodcastsByChannelRequest, options?: any) {
            return DefaultApiFp(configuration).getPodcastsByChannel(requestParams, options)(axios, basePath);
        },
        /**
         * Return Podcasts that belong to {podcastChannel}
         * @summary Get podcasts object
         * @param {requestParameters} [GetPodcastsByChannelIdRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPodcastsByChannelId(requestParams: GetPodcastsByChannelIdRequest, options?: any) {
            return DefaultApiFp(configuration).getPodcastsByChannelId(requestParams, options)(axios, basePath);
        },
        /**
         *
         * @summary Get podcasts by station code
         * @param {requestParameters} [GetPodcastsChannelsByStationCodeRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPodcastsChannelsByStationCode(requestParams: GetPodcastsChannelsByStationCodeRequest, options?: any) {
            return DefaultApiFp(configuration).getPodcastsChannelsByStationCode(requestParams, options)(axios, basePath);
        },
        /**
         *
         * @summary Get Premium Incentive information for an App
         * @param {requestParameters} [GetPremiumForAppRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPremiumForApp(requestParams: GetPremiumForAppRequest, options?: any) {
            return DefaultApiFp(configuration).getPremiumForApp(requestParams, options)(axios, basePath);
        },
        /**
         *
         * @summary Get Premium Incentive information for a Region
         * @param {requestParameters} [GetPremiumForRegionRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPremiumForRegion(requestParams: GetPremiumForRegionRequest, options?: any) {
            return DefaultApiFp(configuration).getPremiumForRegion(requestParams, options)(axios, basePath);
        },
        /**
         *
         * @summary Get Premium Incentive information for a Station
         * @param {requestParameters} [GetPremiumForStationRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPremiumForStation(requestParams: GetPremiumForStationRequest, options?: any) {
            return DefaultApiFp(configuration).getPremiumForStation(requestParams, options)(axios, basePath);
        },
        /**
         *
         * @summary Get presenter by ID
         * @param {requestParameters} [GetPresenterByIdRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPresenterById(requestParams: GetPresenterByIdRequest, options?: any) {
            return DefaultApiFp(configuration).getPresenterById(requestParams, options)(axios, basePath);
        },
        /**
         *
         * @summary Get presenters. (stationId OR stationCode OR brandCode parameter is required)
         * @param {requestParameters} [GetPresentersRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPresenters(requestParams: GetPresentersRequest, options?: any) {
            return DefaultApiFp(configuration).getPresenters(requestParams, options)(axios, basePath);
        },
        /**
         * VideoList of regions
         * @summary Get regions object
         * @param {requestParameters} [GetRegionsRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegions(requestParams: GetRegionsRequest, options?: any) {
            return DefaultApiFp(configuration).getRegions(requestParams, options)(axios, basePath);
        },
        /**
         *
         * @summary Specific endpoint to Google Home Feed
         * @param {requestParameters} [GetRegionsMetaByRegionCodeRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegionsMetaByRegionCode(requestParams: GetRegionsMetaByRegionCodeRequest, options?: any) {
            return DefaultApiFp(configuration).getRegionsMetaByRegionCode(requestParams, options)(axios, basePath);
        },
        /**
         *
         * @summary Get schedule object
         * @param {requestParameters} [GetScheduleByStationCodeRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScheduleByStationCode(requestParams: GetScheduleByStationCodeRequest, options?: any) {
            return DefaultApiFp(configuration).getScheduleByStationCode(requestParams, options)(axios, basePath);
        },
        /**
         * \"schedule\" but with Dadi ID
         * @summary \"schedule\" but with Dadi ID
         * @param {requestParameters} [GetScheduleDadiByDadiIdRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScheduleDadiByDadiId(requestParams: GetScheduleDadiByDadiIdRequest, options?: any) {
            return DefaultApiFp(configuration).getScheduleDadiByDadiId(requestParams, options)(axios, basePath);
        },
        /**
         *
         * @summary Get shows object. (AppCode OR BrandCode OR StationCode OR RegionCode parameter is required)
         * @param {requestParameters} [GetShowsRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShows(requestParams: GetShowsRequest, options?: any) {
            return DefaultApiFp(configuration).getShows(requestParams, options)(axios, basePath);
        },
        /**
         *
         * @summary Get a Shuttle Link object
         * @param {requestParameters} [GetShuttlelinksByIdRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShuttlelinksById(requestParams: GetShuttlelinksByIdRequest, options?: any) {
            return DefaultApiFp(configuration).getShuttlelinksById(requestParams, options)(axios, basePath);
        },
        /**
         *
         * @summary Get SignIn Incentive information for an App
         * @param {requestParameters} [GetSignInIncentivesForAppRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSignInIncentivesForApp(requestParams: GetSignInIncentivesForAppRequest, options?: any) {
            return DefaultApiFp(configuration).getSignInIncentivesForApp(requestParams, options)(axios, basePath);
        },
        /**
         *
         * @summary Get SignIn Incentive information for a Station
         * @param {requestParameters} [GetSignInIncentivesForStationRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSignInIncentivesForStation(requestParams: GetSignInIncentivesForStationRequest, options?: any) {
            return DefaultApiFp(configuration).getSignInIncentivesForStation(requestParams, options)(axios, basePath);
        },
        /**
         *
         * @summary Get list of all stations
         * @param {requestParameters} [GetStationsRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStations(requestParams: GetStationsRequest, options?: any) {
            return DefaultApiFp(configuration).getStations(requestParams, options)(axios, basePath);
        },
        /**
         * Get station code grouped by port
         * @summary Get station code grouped by port
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStationsByPort(options?: any) {
            return DefaultApiFp(configuration).getStationsByPort(options)(axios, basePath);
        },
        /**
         *
         * @summary Get list of all stations
         * @param {requestParameters} [GetStationsByRegionCodeRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStationsByRegionCode(requestParams: GetStationsByRegionCodeRequest, options?: any) {
            return DefaultApiFp(configuration).getStationsByRegionCode(requestParams, options)(axios, basePath);
        },
        /**
         * Retrieves tickets available (via. <a href=\"http://aloud.com/\">Aloud.com)</a>
         * @summary Get tickets object
         * @param {requestParameters} [GetTicketsByStationCodeRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketsByStationCode(requestParams: GetTicketsByStationCodeRequest, options?: any) {
            return DefaultApiFp(configuration).getTicketsByStationCode(requestParams, options)(axios, basePath);
        },
        /**
         * Retrieves \"tickets\" but with Dadi ID
         * @summary Get tickets with Dadi ID
         * @param {requestParameters} [GetTicketsDadiByDadiIdRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketsDadiByDadiId(requestParams: GetTicketsDadiByDadiIdRequest, options?: any) {
            return DefaultApiFp(configuration).getTicketsDadiByDadiId(requestParams, options)(axios, basePath);
        },
        /**
         * \"travel\" but with a Dadi ID
         * @summary Get travel with Dadi ID
         * @param {requestParameters} [GetTrafficWarningsByDadiIdRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrafficWarningsByDadiId(requestParams: GetTrafficWarningsByDadiIdRequest, options?: any) {
            return DefaultApiFp(configuration).getTrafficWarningsByDadiId(requestParams, options)(axios, basePath);
        },
        /**
         * Returns traffic warnings nearby to {station}
         * @summary Get traffic warning information
         * @param {requestParameters} [GetTrafficWarningsByStationCodeRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrafficWarningsByStationCode(requestParams: GetTrafficWarningsByStationCodeRequest, options?: any) {
            return DefaultApiFp(configuration).getTrafficWarningsByStationCode(requestParams, options)(axios, basePath);
        },
        /**
         * Retrieves or (or saves if {saveMode} is \"save\") user's settings
         * @summary Retrieves/Saves user's settings
         * @param {requestParameters} [GetUserSettingsRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserSettings(requestParams: GetUserSettingsRequest, options?: any) {
            return DefaultApiFp(configuration).getUserSettings(requestParams, options)(axios, basePath);
        },
        /**
         * Retrieve {station} configuration (Phones)
         * @summary Retrieve {station} configuration (Phones)
         * @param {requestParameters} [InitAppRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initApp(requestParams: InitAppRequest, options?: any) {
            return DefaultApiFp(configuration).initApp(requestParams, options)(axios, basePath);
        },
        /**
         * \"init\" but with Dadi ID
         * @summary \"init\" but with Dadi ID
         * @param {requestParameters} [InitWithDadiIdRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initWithDadiId(requestParams: InitWithDadiIdRequest, options?: any) {
            return DefaultApiFp(configuration).initWithDadiId(requestParams, options)(axios, basePath);
        },
        /**
         * Retrieve a station's (potentially random) HTML Panel
         * @summary Retrieve a station's (potentially random) HTML Panel
         * @param {requestParameters} [InitWithStationCodeRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initWithStationCode(requestParams: InitWithStationCodeRequest, options?: any) {
            return DefaultApiFp(configuration).initWithStationCode(requestParams, options)(axios, basePath);
        },
        /**
         * Retrieve \"events\" but with a Dadi ID
         * @summary Get the latest 250 events for a Station
         * @param {requestParameters} [LatestEventsRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latestEvents(requestParams: LatestEventsRequest, options?: any) {
            return DefaultApiFp(configuration).latestEvents(requestParams, options)(axios, basePath);
        },
        /**
         * Generic incoming logger so external applications can send any log they like
         * @summary Register any log
         * @param {requestParameters} [LogRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        log(requestParams: LogRequest, options?: any) {
            return DefaultApiFp(configuration).log(requestParams, options)(axios, basePath);
        },
        /**
         * Given valid credentials, will provide user information and hint at how long their cookie should last (not authentication!)
         * @summary Get user information
         * @param {requestParameters} [LoginRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(requestParams: LoginRequest, options?: any) {
            return DefaultApiFp(configuration).login(requestParams, options)(axios, basePath);
        },
        /**
         * Logs logging user out (does not actually log user out, pretty much just logs it)
         * @summary Logs logging user out
         * @param {requestParameters} [LogoutRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(requestParams: LogoutRequest, options?: any) {
            return DefaultApiFp(configuration).logout(requestParams, options)(axios, basePath);
        },
        /**
         *
         * @summary Returns 4 randomly ordered local stations
         * @param {requestParameters} [LuckydipRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        luckydip(requestParams: LuckydipRequest, options?: any) {
            return DefaultApiFp(configuration).luckydip(requestParams, options)(axios, basePath);
        },
        /**
         * One-time request to obtain JWT token without user credentials (<a href=\"https://bauer-specialist.atlassian.net/wiki/spaces/AUD/pages/773947418\">readme</a>)
         * @summary Get JWT token
         * @param {requestParameters} [MakeTokenRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeToken(requestParams: MakeTokenRequest, options?: any) {
            return DefaultApiFp(configuration).makeToken(requestParams, options)(axios, basePath);
        },
        /**
         * Return Podcast Channels that belong to {station}
         * @summary Get podcast channels station object
         * @param {requestParameters} [PodcastchannelsstationRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        podcastchannelsstation(requestParams: PodcastchannelsstationRequest, options?: any) {
            return DefaultApiFp(configuration).podcastchannelsstation(requestParams, options)(axios, basePath);
        },
        /**
         *
         * @summary Get list of all podcasts by region.
         * @param {requestParameters} [PodcastsByRegionCodeRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        podcastsByRegionCode(requestParams: PodcastsByRegionCodeRequest, options?: any) {
            return DefaultApiFp(configuration).podcastsByRegionCode(requestParams, options)(axios, basePath);
        },
        /**
         *
         * @summary Create a Shuttle Link object
         * @param {requestParameters} [PostShuttlelinksRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postShuttlelinks(requestParams: PostShuttlelinksRequest, options?: any) {
            return DefaultApiFp(configuration).postShuttlelinks(requestParams, options)(axios, basePath);
        },
        /**
         * Returns premium subcription status for a user, via the Bearer auth header
         * @summary Return subcription status for a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        premiumStatusForUser(options?: any) {
            return DefaultApiFp(configuration).premiumStatusForUser(options)(axios, basePath);
        },
        /**
         * Creates a user account
         * @summary Creates a user account
         * @param {requestParameters} [RegisterUserRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerUser(requestParams: RegisterUserRequest, options?: any) {
            return DefaultApiFp(configuration).registerUser(requestParams, options)(axios, basePath);
        },
        /**
         * Reset {user.email}'s password and send a reset email
         * @summary Reset user's password
         * @param {requestParameters} [ResetUserPasswordRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetUserPassword(requestParams: ResetUserPasswordRequest, options?: any) {
            return DefaultApiFp(configuration).resetUserPassword(requestParams, options)(axios, basePath);
        },
        /**
         * Get list of all boxset episode that have been updated since provided date, by region.
         * @summary Search boxset episode updates by region
         * @param {requestParameters} [SearchBoxSetEpisodeUpdatesRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchBoxSetEpisodeUpdates(requestParams: SearchBoxSetEpisodeUpdatesRequest, options?: any) {
            return DefaultApiFp(configuration).searchBoxSetEpisodeUpdates(requestParams, options)(axios, basePath);
        },
        /**
         * Get list of all boxsets that have been updated since provided date, by region.
         * @summary Search boxset updates by region
         * @param {requestParameters} [SearchBoxSetUpdatesRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchBoxSetUpdates(requestParams: SearchBoxSetUpdatesRequest, options?: any) {
            return DefaultApiFp(configuration).searchBoxSetUpdates(requestParams, options)(axios, basePath);
        },
        /**
         *
         * @summary Get list of all listen again episodes that have been updated since provided date, by region.
         * @param {requestParameters} [SearchListenAgainEpisodeUpdatesRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchListenAgainEpisodeUpdates(requestParams: SearchListenAgainEpisodeUpdatesRequest, options?: any) {
            return DefaultApiFp(configuration).searchListenAgainEpisodeUpdates(requestParams, options)(axios, basePath);
        },
        /**
         * Get list of all listen again that have been updated since provided date, by region.
         * @summary Search listen again show updates by region
         * @param {requestParameters} [SearchListenAgainShowUpdatesRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchListenAgainShowUpdates(requestParams: SearchListenAgainShowUpdatesRequest, options?: any) {
            return DefaultApiFp(configuration).searchListenAgainShowUpdates(requestParams, options)(axios, basePath);
        },
        /**
         * Get list of all podcast episodes that have been updated since provided date, by region.
         * @summary Search podcast episode updates by region
         * @param {requestParameters} [SearchPodcastEpisodeUpdatesRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPodcastEpisodeUpdates(requestParams: SearchPodcastEpisodeUpdatesRequest, options?: any) {
            return DefaultApiFp(configuration).searchPodcastEpisodeUpdates(requestParams, options)(axios, basePath);
        },
        /**
         *
         * @summary Get list of all podcast show/channels that have been updated since provided date, by region.
         * @param {requestParameters} [SearchPodcastShowUpdatesRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPodcastShowUpdates(requestParams: SearchPodcastShowUpdatesRequest, options?: any) {
            return DefaultApiFp(configuration).searchPodcastShowUpdates(requestParams, options)(axios, basePath);
        },
        /**
         *
         * @summary Get presenters, built specifically for Search ingestion
         * @param {requestParameters} [SearchPresentersUpdatesRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPresentersUpdates(requestParams: SearchPresentersUpdatesRequest, options?: any) {
            return DefaultApiFp(configuration).searchPresentersUpdates(requestParams, options)(axios, basePath);
        },
        /**
         * Rebuild {streamUrl} and redirect to it
         * @summary Rebuild {streamUrl} and redirect to it
         * @param {requestParameters} [SetSharpstreamRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setSharpstream(requestParams: SetSharpstreamRequest, options?: any) {
            return DefaultApiFp(configuration).setSharpstream(requestParams, options)(axios, basePath);
        },
        /**
         *
         * @summary Generates and sends email to {user} reminding them of {station}'s current show
         * @param {requestParameters} [SetUserReminderRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUserReminder(requestParams: SetUserReminderRequest, options?: any) {
            return DefaultApiFp(configuration).setUserReminder(requestParams, options)(axios, basePath);
        },
        /**
         * Returns stream auth level based on a respective stream url
         * @summary Authenticated streams lookup endpoint
         * @param {requestParameters} [StreamAuthLevelRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        streamAuthLevel(requestParams: StreamAuthLevelRequest, options?: any) {
            return DefaultApiFp(configuration).streamAuthLevel(requestParams, options)(axios, basePath);
        },
        /**
         * DEPRECATED. Update a registration token.
         * @summary DEPRECATED. Update a registration token.
         * @param {requestParameters} [UpdateTokenRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateToken(requestParams: UpdateTokenRequest, options?: any) {
            return DefaultApiFp(configuration).updateToken(requestParams, options)(axios, basePath);
        },
        /**
         * Verifies a user's email post-registration
         * @summary Verifies user email
         * @param {requestParameters} [VerifyUserEmailRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyUserEmail(requestParams: VerifyUserEmailRequest, options?: any) {
            return DefaultApiFp(configuration).verifyUserEmail(requestParams, options)(axios, basePath);
        },
    };
};
/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * Remove a registration token.
     * @summary Remove a registration token.
     * @param {requestParameters} [DeleteTokenRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteToken(requestParams: DeleteTokenRequest, options?: any) {
        return DefaultApiFp(this.configuration).deleteToken(requestParams, options)(this.axios, this.basePath);
    }

    /**
     *
     * @summary DEPRECATED. Delete multiple tokens
     * @param {requestParameters} [ForgetTokensRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public forgetTokens(requestParams: ForgetTokensRequest, options?: any) {
        return DefaultApiFp(this.configuration).forgetTokens(requestParams, options)(this.axios, this.basePath);
    }

    /**
     * Retrieve Artist Record
     * @summary Get artist object
     * @param {requestParameters} [GetAbsoluteArtistByIdRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAbsoluteArtistById(requestParams: GetAbsoluteArtistByIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getAbsoluteArtistById(requestParams, options)(this.axios, this.basePath);
    }

    /**
     * Retrieve list of aggregator brands
     * @summary Get a list of all active aggregator brands
     * @param {requestParameters} [GetAggregatorBrandsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAggregatorBrands(requestParams: GetAggregatorBrandsRequest, options?: any) {
        return DefaultApiFp(this.configuration).getAggregatorBrands(requestParams, options)(this.axios, this.basePath);
    }

    /**
     * Return a list of all Applications
     * @summary Get applications object
     * @param {requestParameters} [GetAppsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getApps(requestParams: GetAppsRequest, options?: any) {
        return DefaultApiFp(this.configuration).getApps(requestParams, options)(this.axios, this.basePath);
    }

    /**
     *
     * @summary Get station details for Dadi ID
     * @param {requestParameters} [GetArticleByDadiIdRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getArticleByDadiId(requestParams: GetArticleByDadiIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getArticleByDadiId(requestParams, options)(this.axios, this.basePath);
    }

    /**
     *
     * @summary Get station details for Dadi ID
     * @param {requestParameters} [GetArticleByDadiIdAndFilterRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getArticleByDadiIdAndFilter(requestParams: GetArticleByDadiIdAndFilterRequest, options?: any) {
        return DefaultApiFp(this.configuration).getArticleByDadiIdAndFilter(requestParams, options)(this.axios, this.basePath);
    }

    /**
     *
     * @summary Get station details
     * @param {requestParameters} [GetArticleByStationCodeRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getArticleByStationCode(requestParams: GetArticleByStationCodeRequest, options?: any) {
        return DefaultApiFp(this.configuration).getArticleByStationCode(requestParams, options)(this.axios, this.basePath);
    }

    /**
     *
     * @summary Get station details
     * @param {requestParameters} [GetArticleByStationCodeAndFilterRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getArticleByStationCodeAndFilter(requestParams: GetArticleByStationCodeAndFilterRequest, options?: any) {
        return DefaultApiFp(this.configuration).getArticleByStationCodeAndFilter(requestParams, options)(this.axios, this.basePath);
    }

    /**
     * Retrieve list of audible content (podcasts, box set episodes, listen again episodes)<hr><strong style=\"color:red\">*One of these parameters is required: `AppCode`, `RegionCode`, `StationCode`, `RegionCode`</strong>
     * @summary Get audibles object. (AppCode OR RegionCode OR StationCode OR RegionCode parameter is required)
     * @param {requestParameters} [GetAudiblesRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAudibles(requestParams: GetAudiblesRequest, options?: any) {
        return DefaultApiFp(this.configuration).getAudibles(requestParams, options)(this.axios, this.basePath);
    }

    /**
     * Deprecated? Prefer getShows
     * @summary Get BoxSet and its episodes
     * @param {requestParameters} [GetBoxsetRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getBoxset(requestParams: GetBoxsetRequest, options?: any) {
        return DefaultApiFp(this.configuration).getBoxset(requestParams, options)(this.axios, this.basePath);
    }

    /**
     * Retrieve set episodes (JSON) -- Not on phones
     * @summary Get set episodes object
     * @param {requestParameters} [GetBoxsetEpisodesByStationCodeRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getBoxsetEpisodesByStationCode(requestParams: GetBoxsetEpisodesByStationCodeRequest, options?: any) {
        return DefaultApiFp(this.configuration).getBoxsetEpisodesByStationCode(requestParams, options)(this.axios, this.basePath);
    }

    /**
     *
     * @summary Get brand object
     * @param {requestParameters} [GetBrandByBrandCodeRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getBrandByBrandCode(requestParams: GetBrandByBrandCodeRequest, options?: any) {
        return DefaultApiFp(this.configuration).getBrandByBrandCode(requestParams, options)(this.axios, this.basePath);
    }

    /**
     *
     * @summary Index of brand entities
     * @param {requestParameters} [GetBrandsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getBrands(requestParams: GetBrandsRequest, options?: any) {
        return DefaultApiFp(this.configuration).getBrands(requestParams, options)(this.axios, this.basePath);
    }

    /**
     * DEPRECATED. Stores information about pushable device - {token} is unique identifier
     * @summary DEPRECATED. Get device token object
     * @param {requestParameters} [GetDeviceTokenRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getDeviceToken(requestParams: GetDeviceTokenRequest, options?: any) {
        return DefaultApiFp(this.configuration).getDeviceToken(requestParams, options)(this.axios, this.basePath);
    }

    /**
     * DEPRECATED. Index of device tokens
     * @summary DEPRECATED. Get device tokens object
     * @param {requestParameters} [GetDevicesTokensRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getDevicesTokens(requestParams: GetDevicesTokensRequest, options?: any) {
        return DefaultApiFp(this.configuration).getDevicesTokens(requestParams, options)(this.axios, this.basePath);
    }

    /**
     * Retrieve parsed event record (Generally more useful than eventdata)
     * @summary Get event object
     * @param {requestParameters} [GetEventRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getEvent(requestParams: GetEventRequest, options?: any) {
        return DefaultApiFp(this.configuration).getEvent(requestParams, options)(this.axios, this.basePath);
    }

    /**
     * Retrieve raw-ish event record
     * @summary Retrieve raw-ish event record
     * @param {requestParameters} [GetEventByIdRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getEventById(requestParams: GetEventByIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getEventById(requestParams, options)(this.axios, this.basePath);
    }

    /**
     * Retrieve \"events\" but with a Dadi ID
     * @summary Get playlist with a Dadi ID
     * @param {requestParameters} [GetEventsByDadiIdRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getEventsByDadiId(requestParams: GetEventsByDadiIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getEventsByDadiId(requestParams, options)(this.axios, this.basePath);
    }

    /**
     * Retrieve up to {limit} events up until {time}
     * @summary Get playlist
     * @param {requestParameters} [GetEventsByStationCodeRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getEventsByStationCode(requestParams: GetEventsByStationCodeRequest, options?: any) {
        return DefaultApiFp(this.configuration).getEventsByStationCode(requestParams, options)(this.axios, this.basePath);
    }

    /**
     *
     * @summary Get list of all featured podcasts
     * @param {requestParameters} [GetFeaturedPodcastsByRegionRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getFeaturedPodcastsByRegion(requestParams: GetFeaturedPodcastsByRegionRequest, options?: any) {
        return DefaultApiFp(this.configuration).getFeaturedPodcastsByRegion(requestParams, options)(this.axios, this.basePath);
    }

    /**
     * Returns xml document describing a full screen streaming ad
     * @summary Get full screen streaming ad object
     * @param {requestParameters} [GetInstreamRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getInstream(requestParams: GetInstreamRequest, options?: any) {
        return DefaultApiFp(this.configuration).getInstream(requestParams, options)(this.axios, this.basePath);
    }

    /**
     *
     * @summary Get listen again episodes
     * @param {requestParameters} [GetListenAgainByDadiIdRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getListenAgainByDadiId(requestParams: GetListenAgainByDadiIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getListenAgainByDadiId(requestParams, options)(this.axios, this.basePath);
    }

    /**
     * Retrieve historical play information for {station}
     * @summary Get listenagain object
     * @param {requestParameters} [GetListenAgainByStationCodeRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getListenAgainByStationCode(requestParams: GetListenAgainByStationCodeRequest, options?: any) {
        return DefaultApiFp(this.configuration).getListenAgainByStationCode(requestParams, options)(this.axios, this.basePath);
    }

    /**
     *
     * @summary Logs a \"listen\"
     * @param {requestParameters} [GetListenLogsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getListenLogs(requestParams: GetListenLogsRequest, options?: any) {
        return DefaultApiFp(this.configuration).getListenLogs(requestParams, options)(this.axios, this.basePath);
    }

    /**
     *
     * @summary Get list of all stations
     * @param {requestParameters} [GetMinifiedStationsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getMinifiedStations(requestParams: GetMinifiedStationsRequest, options?: any) {
        return DefaultApiFp(this.configuration).getMinifiedStations(requestParams, options)(this.axios, this.basePath);
    }

    /**
     * Returns nearby stations split based on user location
     * @summary Get nearby split stations
     * @param {requestParameters} [GetNearbyStationsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getNearbyStations(requestParams: GetNearbyStationsRequest, options?: any) {
        return DefaultApiFp(this.configuration).getNearbyStations(requestParams, options)(this.axios, this.basePath);
    }

    /**
     * Return the station networks
     * @summary Get station networks
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getNetworks(options?: any) {
        return DefaultApiFp(this.configuration).getNetworks(options)(this.axios, this.basePath);
    }

    /**
     *
     * @summary Get nowplaying / station on air data of stations
     * @param {requestParameters} [GetNowPlayingAndOnAirForStationsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getNowPlayingAndOnAirForStations(requestParams: GetNowPlayingAndOnAirForStationsRequest, options?: any) {
        return DefaultApiFp(this.configuration).getNowPlayingAndOnAirForStations(requestParams, options)(this.axios, this.basePath);
    }

    /**
     * Return what is currently playing on {station}
     * @summary Get nowplaying object
     * @param {requestParameters} [GetNowPlayingByStationCodeRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getNowPlayingByStationCode(requestParams: GetNowPlayingByStationCodeRequest, options?: any) {
        return DefaultApiFp(this.configuration).getNowPlayingByStationCode(requestParams, options)(this.axios, this.basePath);
    }

    /**
     * Return what is currently playing on {station}
     * @summary Get nowplaying object with small time adjustment so it aligns to realtime
     * @param {requestParameters} [GetOaNowPlayingByStationCodeRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getOaNowPlayingByStationCode(requestParams: GetOaNowPlayingByStationCodeRequest, options?: any) {
        return DefaultApiFp(this.configuration).getOaNowPlayingByStationCode(requestParams, options)(this.axios, this.basePath);
    }

    /**
     *
     * @summary Get playlist information for a show or station. Must provide one of ScheduleId, StationCode
     * @param {requestParameters} [GetPlaylistRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getPlaylist(requestParams: GetPlaylistRequest, options?: any) {
        return DefaultApiFp(this.configuration).getPlaylist(requestParams, options)(this.axios, this.basePath);
    }

    /**
     * Same as `/podcastsbyid/{podcastId}` except the Podcast Channel is merged with Podcast
     * @summary Get podcast object
     * @param {requestParameters} [GetPodcastByIdRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getPodcastById(requestParams: GetPodcastByIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getPodcastById(requestParams, options)(this.axios, this.basePath);
    }

    /**
     *
     * @summary Return Podcast Channels that belong to {station}
     * @param {requestParameters} [GetPodcastChannelsDadiByDadiIdRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getPodcastChannelsDadiByDadiId(requestParams: GetPodcastChannelsDadiByDadiIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getPodcastChannelsDadiByDadiId(requestParams, options)(this.axios, this.basePath);
    }

    /**
     *
     * @summary Find all podcasts by channel
     * @param {requestParameters} [GetPodcastsByChannelRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getPodcastsByChannel(requestParams: GetPodcastsByChannelRequest, options?: any) {
        return DefaultApiFp(this.configuration).getPodcastsByChannel(requestParams, options)(this.axios, this.basePath);
    }

    /**
     * Return Podcasts that belong to {podcastChannel}
     * @summary Get podcasts object
     * @param {requestParameters} [GetPodcastsByChannelIdRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getPodcastsByChannelId(requestParams: GetPodcastsByChannelIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getPodcastsByChannelId(requestParams, options)(this.axios, this.basePath);
    }

    /**
     *
     * @summary Get podcasts by station code
     * @param {requestParameters} [GetPodcastsChannelsByStationCodeRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getPodcastsChannelsByStationCode(requestParams: GetPodcastsChannelsByStationCodeRequest, options?: any) {
        return DefaultApiFp(this.configuration).getPodcastsChannelsByStationCode(requestParams, options)(this.axios, this.basePath);
    }

    /**
     *
     * @summary Get Premium Incentive information for an App
     * @param {requestParameters} [GetPremiumForAppRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getPremiumForApp(requestParams: GetPremiumForAppRequest, options?: any) {
        return DefaultApiFp(this.configuration).getPremiumForApp(requestParams, options)(this.axios, this.basePath);
    }

    /**
     *
     * @summary Get Premium Incentive information for a Region
     * @param {requestParameters} [GetPremiumForRegionRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getPremiumForRegion(requestParams: GetPremiumForRegionRequest, options?: any) {
        return DefaultApiFp(this.configuration).getPremiumForRegion(requestParams, options)(this.axios, this.basePath);
    }

    /**
     *
     * @summary Get Premium Incentive information for a Station
     * @param {requestParameters} [GetPremiumForStationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getPremiumForStation(requestParams: GetPremiumForStationRequest, options?: any) {
        return DefaultApiFp(this.configuration).getPremiumForStation(requestParams, options)(this.axios, this.basePath);
    }

    /**
     *
     * @summary Get presenter by ID
     * @param {requestParameters} [GetPresenterByIdRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getPresenterById(requestParams: GetPresenterByIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getPresenterById(requestParams, options)(this.axios, this.basePath);
    }

    /**
     *
     * @summary Get presenters. (stationId OR stationCode OR brandCode parameter is required)
     * @param {requestParameters} [GetPresentersRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getPresenters(requestParams: GetPresentersRequest, options?: any) {
        return DefaultApiFp(this.configuration).getPresenters(requestParams, options)(this.axios, this.basePath);
    }

    /**
     * VideoList of regions
     * @summary Get regions object
     * @param {requestParameters} [GetRegionsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getRegions(requestParams: GetRegionsRequest, options?: any) {
        return DefaultApiFp(this.configuration).getRegions(requestParams, options)(this.axios, this.basePath);
    }

    /**
     *
     * @summary Specific endpoint to Google Home Feed
     * @param {requestParameters} [GetRegionsMetaByRegionCodeRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getRegionsMetaByRegionCode(requestParams: GetRegionsMetaByRegionCodeRequest, options?: any) {
        return DefaultApiFp(this.configuration).getRegionsMetaByRegionCode(requestParams, options)(this.axios, this.basePath);
    }

    /**
     *
     * @summary Get schedule object
     * @param {requestParameters} [GetScheduleByStationCodeRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getScheduleByStationCode(requestParams: GetScheduleByStationCodeRequest, options?: any) {
        return DefaultApiFp(this.configuration).getScheduleByStationCode(requestParams, options)(this.axios, this.basePath);
    }

    /**
     * \"schedule\" but with Dadi ID
     * @summary \"schedule\" but with Dadi ID
     * @param {requestParameters} [GetScheduleDadiByDadiIdRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getScheduleDadiByDadiId(requestParams: GetScheduleDadiByDadiIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getScheduleDadiByDadiId(requestParams, options)(this.axios, this.basePath);
    }

    /**
     *
     * @summary Get shows object. (AppCode OR BrandCode OR StationCode OR RegionCode parameter is required)
     * @param {requestParameters} [GetShowsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getShows(requestParams: GetShowsRequest, options?: any) {
        return DefaultApiFp(this.configuration).getShows(requestParams, options)(this.axios, this.basePath);
    }

    /**
     *
     * @summary Get a Shuttle Link object
     * @param {requestParameters} [GetShuttlelinksByIdRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getShuttlelinksById(requestParams: GetShuttlelinksByIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getShuttlelinksById(requestParams, options)(this.axios, this.basePath);
    }

    /**
     *
     * @summary Get SignIn Incentive information for an App
     * @param {requestParameters} [GetSignInIncentivesForAppRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getSignInIncentivesForApp(requestParams: GetSignInIncentivesForAppRequest, options?: any) {
        return DefaultApiFp(this.configuration).getSignInIncentivesForApp(requestParams, options)(this.axios, this.basePath);
    }

    /**
     *
     * @summary Get SignIn Incentive information for a Station
     * @param {requestParameters} [GetSignInIncentivesForStationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getSignInIncentivesForStation(requestParams: GetSignInIncentivesForStationRequest, options?: any) {
        return DefaultApiFp(this.configuration).getSignInIncentivesForStation(requestParams, options)(this.axios, this.basePath);
    }

    /**
     *
     * @summary Get list of all stations
     * @param {requestParameters} [GetStationsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getStations(requestParams: GetStationsRequest, options?: any) {
        return DefaultApiFp(this.configuration).getStations(requestParams, options)(this.axios, this.basePath);
    }

    /**
     * Get station code grouped by port
     * @summary Get station code grouped by port
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getStationsByPort(options?: any) {
        return DefaultApiFp(this.configuration).getStationsByPort(options)(this.axios, this.basePath);
    }

    /**
     *
     * @summary Get list of all stations
     * @param {requestParameters} [GetStationsByRegionCodeRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getStationsByRegionCode(requestParams: GetStationsByRegionCodeRequest, options?: any) {
        return DefaultApiFp(this.configuration).getStationsByRegionCode(requestParams, options)(this.axios, this.basePath);
    }

    /**
     * Retrieves tickets available (via. <a href=\"http://aloud.com/\">Aloud.com)</a>
     * @summary Get tickets object
     * @param {requestParameters} [GetTicketsByStationCodeRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getTicketsByStationCode(requestParams: GetTicketsByStationCodeRequest, options?: any) {
        return DefaultApiFp(this.configuration).getTicketsByStationCode(requestParams, options)(this.axios, this.basePath);
    }

    /**
     * Retrieves \"tickets\" but with Dadi ID
     * @summary Get tickets with Dadi ID
     * @param {requestParameters} [GetTicketsDadiByDadiIdRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getTicketsDadiByDadiId(requestParams: GetTicketsDadiByDadiIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getTicketsDadiByDadiId(requestParams, options)(this.axios, this.basePath);
    }

    /**
     * \"travel\" but with a Dadi ID
     * @summary Get travel with Dadi ID
     * @param {requestParameters} [GetTrafficWarningsByDadiIdRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getTrafficWarningsByDadiId(requestParams: GetTrafficWarningsByDadiIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).getTrafficWarningsByDadiId(requestParams, options)(this.axios, this.basePath);
    }

    /**
     * Returns traffic warnings nearby to {station}
     * @summary Get traffic warning information
     * @param {requestParameters} [GetTrafficWarningsByStationCodeRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getTrafficWarningsByStationCode(requestParams: GetTrafficWarningsByStationCodeRequest, options?: any) {
        return DefaultApiFp(this.configuration).getTrafficWarningsByStationCode(requestParams, options)(this.axios, this.basePath);
    }

    /**
     * Retrieves or (or saves if {saveMode} is \"save\") user's settings
     * @summary Retrieves/Saves user's settings
     * @param {requestParameters} [GetUserSettingsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getUserSettings(requestParams: GetUserSettingsRequest, options?: any) {
        return DefaultApiFp(this.configuration).getUserSettings(requestParams, options)(this.axios, this.basePath);
    }

    /**
     * Retrieve {station} configuration (Phones)
     * @summary Retrieve {station} configuration (Phones)
     * @param {requestParameters} [InitAppRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public initApp(requestParams: InitAppRequest, options?: any) {
        return DefaultApiFp(this.configuration).initApp(requestParams, options)(this.axios, this.basePath);
    }

    /**
     * \"init\" but with Dadi ID
     * @summary \"init\" but with Dadi ID
     * @param {requestParameters} [InitWithDadiIdRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public initWithDadiId(requestParams: InitWithDadiIdRequest, options?: any) {
        return DefaultApiFp(this.configuration).initWithDadiId(requestParams, options)(this.axios, this.basePath);
    }

    /**
     * Retrieve a station's (potentially random) HTML Panel
     * @summary Retrieve a station's (potentially random) HTML Panel
     * @param {requestParameters} [InitWithStationCodeRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public initWithStationCode(requestParams: InitWithStationCodeRequest, options?: any) {
        return DefaultApiFp(this.configuration).initWithStationCode(requestParams, options)(this.axios, this.basePath);
    }

    /**
     * Retrieve \"events\" but with a Dadi ID
     * @summary Get the latest 250 events for a Station
     * @param {requestParameters} [LatestEventsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public latestEvents(requestParams: LatestEventsRequest, options?: any) {
        return DefaultApiFp(this.configuration).latestEvents(requestParams, options)(this.axios, this.basePath);
    }

    /**
     * Generic incoming logger so external applications can send any log they like
     * @summary Register any log
     * @param {requestParameters} [LogRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public log(requestParams: LogRequest, options?: any) {
        return DefaultApiFp(this.configuration).log(requestParams, options)(this.axios, this.basePath);
    }

    /**
     * Given valid credentials, will provide user information and hint at how long their cookie should last (not authentication!)
     * @summary Get user information
     * @param {requestParameters} [LoginRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public login(requestParams: LoginRequest, options?: any) {
        return DefaultApiFp(this.configuration).login(requestParams, options)(this.axios, this.basePath);
    }

    /**
     * Logs logging user out (does not actually log user out, pretty much just logs it)
     * @summary Logs logging user out
     * @param {requestParameters} [LogoutRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public logout(requestParams: LogoutRequest, options?: any) {
        return DefaultApiFp(this.configuration).logout(requestParams, options)(this.axios, this.basePath);
    }

    /**
     *
     * @summary Returns 4 randomly ordered local stations
     * @param {requestParameters} [LuckydipRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public luckydip(requestParams: LuckydipRequest, options?: any) {
        return DefaultApiFp(this.configuration).luckydip(requestParams, options)(this.axios, this.basePath);
    }

    /**
     * One-time request to obtain JWT token without user credentials (<a href=\"https://bauer-specialist.atlassian.net/wiki/spaces/AUD/pages/773947418\">readme</a>)
     * @summary Get JWT token
     * @param {requestParameters} [MakeTokenRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public makeToken(requestParams: MakeTokenRequest, options?: any) {
        return DefaultApiFp(this.configuration).makeToken(requestParams, options)(this.axios, this.basePath);
    }

    /**
     * Return Podcast Channels that belong to {station}
     * @summary Get podcast channels station object
     * @param {requestParameters} [PodcastchannelsstationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public podcastchannelsstation(requestParams: PodcastchannelsstationRequest, options?: any) {
        return DefaultApiFp(this.configuration).podcastchannelsstation(requestParams, options)(this.axios, this.basePath);
    }

    /**
     *
     * @summary Get list of all podcasts by region.
     * @param {requestParameters} [PodcastsByRegionCodeRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public podcastsByRegionCode(requestParams: PodcastsByRegionCodeRequest, options?: any) {
        return DefaultApiFp(this.configuration).podcastsByRegionCode(requestParams, options)(this.axios, this.basePath);
    }

    /**
     *
     * @summary Create a Shuttle Link object
     * @param {requestParameters} [PostShuttlelinksRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postShuttlelinks(requestParams: PostShuttlelinksRequest, options?: any) {
        return DefaultApiFp(this.configuration).postShuttlelinks(requestParams, options)(this.axios, this.basePath);
    }

    /**
     * Returns premium subcription status for a user, via the Bearer auth header
     * @summary Return subcription status for a user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public premiumStatusForUser(options?: any) {
        return DefaultApiFp(this.configuration).premiumStatusForUser(options)(this.axios, this.basePath);
    }

    /**
     * Creates a user account
     * @summary Creates a user account
     * @param {requestParameters} [RegisterUserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public registerUser(requestParams: RegisterUserRequest, options?: any) {
        return DefaultApiFp(this.configuration).registerUser(requestParams, options)(this.axios, this.basePath);
    }

    /**
     * Reset {user.email}'s password and send a reset email
     * @summary Reset user's password
     * @param {requestParameters} [ResetUserPasswordRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public resetUserPassword(requestParams: ResetUserPasswordRequest, options?: any) {
        return DefaultApiFp(this.configuration).resetUserPassword(requestParams, options)(this.axios, this.basePath);
    }

    /**
     * Get list of all boxset episode that have been updated since provided date, by region.
     * @summary Search boxset episode updates by region
     * @param {requestParameters} [SearchBoxSetEpisodeUpdatesRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public searchBoxSetEpisodeUpdates(requestParams: SearchBoxSetEpisodeUpdatesRequest, options?: any) {
        return DefaultApiFp(this.configuration).searchBoxSetEpisodeUpdates(requestParams, options)(this.axios, this.basePath);
    }

    /**
     * Get list of all boxsets that have been updated since provided date, by region.
     * @summary Search boxset updates by region
     * @param {requestParameters} [SearchBoxSetUpdatesRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public searchBoxSetUpdates(requestParams: SearchBoxSetUpdatesRequest, options?: any) {
        return DefaultApiFp(this.configuration).searchBoxSetUpdates(requestParams, options)(this.axios, this.basePath);
    }

    /**
     *
     * @summary Get list of all listen again episodes that have been updated since provided date, by region.
     * @param {requestParameters} [SearchListenAgainEpisodeUpdatesRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public searchListenAgainEpisodeUpdates(requestParams: SearchListenAgainEpisodeUpdatesRequest, options?: any) {
        return DefaultApiFp(this.configuration).searchListenAgainEpisodeUpdates(requestParams, options)(this.axios, this.basePath);
    }

    /**
     * Get list of all listen again that have been updated since provided date, by region.
     * @summary Search listen again show updates by region
     * @param {requestParameters} [SearchListenAgainShowUpdatesRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public searchListenAgainShowUpdates(requestParams: SearchListenAgainShowUpdatesRequest, options?: any) {
        return DefaultApiFp(this.configuration).searchListenAgainShowUpdates(requestParams, options)(this.axios, this.basePath);
    }

    /**
     * Get list of all podcast episodes that have been updated since provided date, by region.
     * @summary Search podcast episode updates by region
     * @param {requestParameters} [SearchPodcastEpisodeUpdatesRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public searchPodcastEpisodeUpdates(requestParams: SearchPodcastEpisodeUpdatesRequest, options?: any) {
        return DefaultApiFp(this.configuration).searchPodcastEpisodeUpdates(requestParams, options)(this.axios, this.basePath);
    }

    /**
     *
     * @summary Get list of all podcast show/channels that have been updated since provided date, by region.
     * @param {requestParameters} [SearchPodcastShowUpdatesRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public searchPodcastShowUpdates(requestParams: SearchPodcastShowUpdatesRequest, options?: any) {
        return DefaultApiFp(this.configuration).searchPodcastShowUpdates(requestParams, options)(this.axios, this.basePath);
    }

    /**
     *
     * @summary Get presenters, built specifically for Search ingestion
     * @param {requestParameters} [SearchPresentersUpdatesRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public searchPresentersUpdates(requestParams: SearchPresentersUpdatesRequest, options?: any) {
        return DefaultApiFp(this.configuration).searchPresentersUpdates(requestParams, options)(this.axios, this.basePath);
    }

    /**
     * Rebuild {streamUrl} and redirect to it
     * @summary Rebuild {streamUrl} and redirect to it
     * @param {requestParameters} [SetSharpstreamRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public setSharpstream(requestParams: SetSharpstreamRequest, options?: any) {
        return DefaultApiFp(this.configuration).setSharpstream(requestParams, options)(this.axios, this.basePath);
    }

    /**
     *
     * @summary Generates and sends email to {user} reminding them of {station}'s current show
     * @param {requestParameters} [SetUserReminderRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public setUserReminder(requestParams: SetUserReminderRequest, options?: any) {
        return DefaultApiFp(this.configuration).setUserReminder(requestParams, options)(this.axios, this.basePath);
    }

    /**
     * Returns stream auth level based on a respective stream url
     * @summary Authenticated streams lookup endpoint
     * @param {requestParameters} [StreamAuthLevelRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public streamAuthLevel(requestParams: StreamAuthLevelRequest, options?: any) {
        return DefaultApiFp(this.configuration).streamAuthLevel(requestParams, options)(this.axios, this.basePath);
    }

    /**
     * DEPRECATED. Update a registration token.
     * @summary DEPRECATED. Update a registration token.
     * @param {requestParameters} [UpdateTokenRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateToken(requestParams: UpdateTokenRequest, options?: any) {
        return DefaultApiFp(this.configuration).updateToken(requestParams, options)(this.axios, this.basePath);
    }

    /**
     * Verifies a user's email post-registration
     * @summary Verifies user email
     * @param {requestParameters} [VerifyUserEmailRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public verifyUserEmail(requestParams: VerifyUserEmailRequest, options?: any) {
        return DefaultApiFp(this.configuration).verifyUserEmail(requestParams, options)(this.axios, this.basePath);
    }

}

